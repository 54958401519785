import { Injectable } from '@angular/core';
import {
    StartDay, StartLesson,
    StartLockedCell, StartTeacherAbsent, StartTimeTable, StartTimeTableData,
} from '@common/entities';
import { IStartTimeTableResult, IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { COMMON_FIELDS } from '@common/constants';
import { FormForm } from '@common/entities/form/Form.entity';
import { FormOrg } from '@common/entities/form/FormOrg.entity';
import { FormResult } from '@common/entities/form/FormResult.entity';
import { FormCoupon } from '@common/entities/form/FormCoupon.entity';



// export interface IFormApiService { }

@Injectable({
    providedIn: 'root',
})
export class FormApiService extends CommonApiService implements  ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'form', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Forms Apis                                 */
    /* -------------------------------------------------------------------------- */

    async getFroms(body: any): Promise<FormForm[]> {
        return await this.extraApiService.post('/form/forms/by-filter', body);
    }

    async upsertForm(body: any): Promise<FormForm[]> {
        return await this.extraApiService.post('/form/forms/upsert', body);
    }

    async softDeleteForm(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/soft-delete', body);
    }

    async deleteForm(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                              Forms Orgs Apis                               */
    /* -------------------------------------------------------------------------- */

    async getFormByOrg(body: any): Promise<FormOrg[]> {
        return await this.extraApiService.request(FormOrg, 'post', '/form/forms/forms-orgs/by-filter', body);
    }

    async upsertFormByOrg(body: any): Promise<FormOrg[]> {
        return await this.extraApiService.post('/form/forms/forms-orgs/upsert', body);
    }

    async softDeleteFormByOrg(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/forms-orgs/soft-delete', body);
    }

    async deleteFormByOrg(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/forms-orgs/delete', body);
    }

    async formPayment(body: any): Promise<FormOrg[]> {
        return await this.extraApiService.post('/form/pay/form-payments/by-filter', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                              Forms Coupon Apis                             */
    /* -------------------------------------------------------------------------- */

    async getFormCoupon(body: any): Promise<FormCoupon[]> {
        return await this.extraApiService.request(FormCoupon, 'post', '/form/pay/form-coupons/by-filter', body);
    }

    async upsertFormCoupon(body: any): Promise<FormCoupon[]> {
        return await this.extraApiService.post('/form/pay/form-coupons/upsert', body);
    }

    async softDeleteFormCoupon(body: any): Promise<any> {
        return await this.extraApiService.post('/form/pay/form-coupons/soft-delete', body);
    }

    async deleteFormCoupon(body: any): Promise<any> {
        return await this.extraApiService.post('/form/pay/form-coupons/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                              Forms Result Apis                             */
    /* -------------------------------------------------------------------------- */

    async getFormResult(body: any): Promise<FormResult[]> {
        return await this.extraApiService.request(FormResult, 'post', '/form/forms/forms-results/by-filter', body);
    }

    async upsertFormResult(body: any): Promise<FormResult[]> {
        return await this.extraApiService.post('/form/forms/forms-results/upsert', body);
    }

    async softDeleteFormResult(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/forms-results/soft-delete', body);
    }

    async deleteFormResult(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/forms-results/delete', body);
    }

    async getOrganisation(body: any): Promise<any> {
        return await this.extraApiService.getAccountApi('/api/organisation', body);
    }

    async groupByAndCount(body: any): Promise<any> {
        return await this.extraApiService.post('/form/forms/forms-results/count', body);
    }

    async generateExcel(body: any): Promise<any> {
        return await this.extraApiService.postFile('/util/generator/export-data-as-xlsx', body);
    }

    async getSchema(body: string): Promise<any> {
        return await this.extraApiService.get('/core/util/table-info/' + body);
    }
}
