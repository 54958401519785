import { Injectable } from '@angular/core';
import { CloudApplication, CloudOrg } from '@common/entities';
import { HelperService } from '../helpers/helper.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { IFindPaginateOptions } from '@common/interfaces';

@Injectable({
    providedIn: 'root',
})
export class CloudApiService extends CommonApiService implements ICommonApiService {

    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'core', helperService);
    }


    public async getOrgs(): Promise<CloudOrg[]> {
        return this.extraApiService.request<CloudOrg[]>(CloudOrg, 'post', `/cloud/organizations/by-filter`).then(res => {

            return res;
        });
    }

    public async getApps(): Promise<CloudApplication[]> {
        return this.extraApiService.request<CloudApplication[]>(CloudApplication, 'post', `/cloud/apps/by-filter`).then(res => {

            return res;
        });
    }

    public async getOrgByAuth(): Promise<CloudOrg> {
        return this.extraApiService.request<CloudOrg>(CloudOrg, 'post', `/cloud/organizations/get-apps`).then(res => {

            return res;
        });
    }

    // Cloud Org
    public async getCloudOrgs(filter: IFindPaginateOptions): Promise<CloudOrg[]> {
        return this.extraApiService.request(CloudOrg, 'post', `/cloud/organizations/by-filter`, filter);
    }

    public async upsertCloudOrgs(body: CloudOrg[]): Promise<CloudOrg[]> {
        return this.extraApiService.request(CloudOrg, 'post', `/cloud/organizations/upsert`, body);
    }

    public async resetPasswordByAccountId(body: { accountId: number }): Promise<any> {
        return this.extraApiService.post(`/cloud/organizations/accounts/reset-password`, body);
    }
}
