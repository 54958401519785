import { Injectable } from '@angular/core';
import {
    StartDay, StartLesson,
    StartLockedCell, StartTeacherAbsent, StartTimeTable, StartTimeTableData,
} from '@common/entities';
import { IStartTimeTableResult, IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { COMMON_FIELDS } from '@common/constants';
import { QuestionsExam } from '@common/entities/exams/QuestionsExam.entity';
import { Exams } from '@common/entities/exams/Exams.entity';
import { ExamsQuestions } from '@common/entities/exams/ExamsQuestions.entity';
import { QuestionsAnswer } from '@common/entities/exams/QuestionsAnswer.entity';
import { GroupExams } from '@common/entities/exams/GroupExams.entity';

@Injectable({
    providedIn: 'root',
})
export class ExamsApiService extends CommonApiService implements  ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'form', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Exams Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getExams(body: any): Promise<Exams[]> {
        return await this.extraApiService.request(Exams, 'post', '/form/exams/by-filter', body);
    }

    async upsertExams(body: any): Promise<Exams[]> {
        return await this.extraApiService.post('/form/exams/upsert', body);
    }

    async softDeleteExams(body: any): Promise<Exams[]> {
        return await this.extraApiService.post('/form/exams/soft-delete', body);
    }

    async deleteExams(body: any): Promise<Exams[]> {
        return await this.extraApiService.post('/form/exams/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                               Questions Apis                               */
    /* -------------------------------------------------------------------------- */
    async getQuestions(body: any): Promise<QuestionsExam[]> {
        return await this.extraApiService.request(QuestionsExam, 'post', '/form/exams/questions/by-filter', body);
    }

    async upsertQuestions(body: any): Promise<QuestionsExam[]> {
        return await this.extraApiService.post('/form/exams/questions/upsert', body);
    }

    async softDeleteQuestions(body: any): Promise<QuestionsExam[]> {
        return await this.extraApiService.post('/form/exams/questions/soft-delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                               Exams Questions Apis                         */
    /* -------------------------------------------------------------------------- */
    async getExamsQuestions(body: any): Promise<ExamsQuestions[]> {
        return await this.extraApiService.post('/form/exams/exams-questions/by-filter', body);
    }

    async upsertExamsQuestions(body: any): Promise<ExamsQuestions[]> {
        return await this.extraApiService.post('/form/exams/exams-questions/upsert', body);
    }

    async softDeleteExamsQuestions(body: any): Promise<ExamsQuestions[]> {
        return await this.extraApiService.post('/form/exams/exams-questions/soft-delete', body);
    }

    async deleteExamsQuestions(body: any): Promise<ExamsQuestions[]> {
        return await this.extraApiService.post('/form/exams/exams-questions/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                               Questions Answer Apis                        */
    /* -------------------------------------------------------------------------- */
    async getQuestionsAnswer(body: any): Promise<QuestionsAnswer[]> {
        return await this.extraApiService.request(QuestionsAnswer, 'post', '/form/exams/questions-answers/by-filter', body);
    }

    async upsertQuestionsAnswer(body: any): Promise<QuestionsAnswer[]> {
        return await this.extraApiService.post('/form/exams/questions-answers/upsert', body);
    }

    async softDeleteQuestionsAnswer(body: any): Promise<QuestionsAnswer[]> {
        return await this.extraApiService.post('/form/exams/questions-answers/soft-delete', body);
    }

    async deleteQuestionsAnswer(body: any): Promise<QuestionsAnswer[]> {
        return await this.extraApiService.post('/form/exams/questions-answers/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Group Exams Apis                           */
    /* -------------------------------------------------------------------------- */
    async getGroupExams(body: any): Promise<GroupExams[]> {
        return await this.extraApiService.post('/form/exams/groups-exams/by-filter', body);
    }

    async upsertGroupExams(body: any): Promise<GroupExams[]> {
        return await this.extraApiService.post('/form/exams/groups-exams/upsert', body);
    }

    async softDeleteGroupExams(body: any): Promise<GroupExams[]> {
        return await this.extraApiService.post('/form/exams/groups-exams/soft-delete', body);
    }

    async deleteGroupExams(body: any): Promise<GroupExams[]> {
        return await this.extraApiService.post('/form/exams/groups-exams/delete', body);
    }
}
