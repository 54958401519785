import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export const HttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json?cb=' + new Date().getTime());
};

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [TranslateModule],
    declarations: [],
})
export class ProviderTranslateModule { }
