import { Component, isDevMode, OnInit } from '@angular/core';

@Component({
    selector: 'core-loading',
    templateUrl: './core-loading.component.html',
    styleUrls: ['./core-loading.component.scss'],
})
export class CoreLoadingComponent implements OnInit {

    constructor(
    ) { }

    async ngOnInit(): Promise<void> {
    }
}
