import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class StudentExamSchedule extends BaseEntityClass {
    @Expose()
    public groupId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    public studentId!: number;

    constructor(payload?: Partial<StudentExamSchedule>) {
        super();

        return plainToClass(StudentExamSchedule, payload, { excludeExtraneousValues: true });
    }
}
