import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'core-input',
    templateUrl: './core-input.component.html',
    styleUrls: ['./core-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoreInputComponent),
            multi: true,
        },
    ],
})
export class CoreInputComponent implements ControlValueAccessor {
    constructor() { }

    @Input()
    public id!: string;

    @Input()
    public name!: string;

    @Input()
    public control!: AbstractControl;

    @Input()
    public max!: number;

    @Input()
    public direction: 'rtl' | 'ltr' = 'rtl';

    @Input()
    public min!: number;

    @Input()
    public icon!: string;

    @Input()
    public loading: boolean = true;

    @Input()
    public step: string = '1';

    @Input()
    public bg: boolean = false;

    @Input()
    public multiple: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public title: string = '';

    @Input()
    public required = false;

    @Input()
    public horizontal: { label: number, input: number } = {
            label: 0,
            input: 0,
        };

    @Input()
    public label = false;

    @Input()
    public asArray = false;

    @Input()
    public autocomplete = 'off';

    @Input()
    public type: 'text' | 'date' | 'time' | 'number' | 'email' | 'password' | 'file' | 'select' = 'text';

    @Input()
    public validation?: { status?: boolean, messages?: string[] } | null;

    @Output()
    public onType: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onFiles: EventEmitter<FileList> = new EventEmitter<FileList>();

    public input = '';

    public inputValue = null;

    public onChange: any = (_: any) => { };
    public onTouch: any = () => { };

    set value(input: any) {
        if (input !== undefined && this.input !== input) {
            if (input instanceof Date) {
                this.input = moment(input).format('YYYY-MM-DD');
            } else {
                this.input = input;
            }

            this.onChange(!this.asArray ? input : [input]);
            this.onTouch(!this.asArray ? input : [input]);
        }
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    onInput(event: any): void {
        if (this.type === 'file') {
            this.onFiles.emit(event.target.files);

            this.onChange(this.inputValue);
            this.onTouch(this.inputValue);
        } else {
            let value = this.inputValue as any;

            if (this.type === 'number') {
                value = value === '' || value === null || value === undefined ? null : Number(value);
            }

            this.validation = null;

            this.value = value;

            this.onChange(!this.asArray ? value : [value]);
            this.onTouch(!this.asArray ? value : [value]);

            this.onType.emit(value);
        }

    }

    get isInvalid(): boolean {
        if (!this.validation) {
            return false;
        } else {
            return !this.validation.status;
        }
    }

    public get labelCol(): string {
        if (this.horizontal && this.horizontal.label) {
            return `col-${this.horizontal.label}`;
        }
        return '';
    }

    public get inputCol(): string {
        if (this.horizontal && this.horizontal.input) {
            return `col-${this.horizontal.input}`;
        }
        return '';
    }
}
