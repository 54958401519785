<ngx-loading-bar color="#fd7e14" height="3px" ref="router"></ngx-loading-bar>
<ngx-loading-bar color="#fd7e14" height="3px" ref="http"></ngx-loading-bar>

<app-header></app-header>

<main>
    <div class="container-fluid">
        <div style="margin-top: 100px;">

            <router-outlet></router-outlet>

        </div>
    </div>
</main>

