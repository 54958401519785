
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { PayPayments } from './PayPayments.entity';
import { Student } from '../Student.entity';
import * as moment from 'moment';
import { PayStudentPaymentsInvoices } from './PayStudentPaymentsInvoices.entity';
import { PayBank } from './PayBank.entity';

export class PayInvoices extends BaseEntityClass {

    @Expose()
    public relationshipId!: number;

    @Expose()
    public paymentMethodCode!: number;

    @Expose()
    @Type( () => Date)
    public checkDate!: string;

    @Expose()
    public note!: string;

    @Expose()
    public payment!: PayPayments;

    @Expose()
    public student!: Student;

    @Expose()
    public checkNumber?: number;

    @Expose()
    public bankNumber?: number;

    @Expose()
    public bankBranchNumber?: number;

    @Expose()
    public statusCheck?: number;

    @Expose()
    public bankId?: number;

    @Expose()
    public bank?: PayBank;

    @Expose()
    public typePaidId?: number;

    @Expose()
    public receiptNumber!: number;

    @Expose()
    public status!: number;

    @Expose()
    public reason!: string;

    @Expose()
    public studentPaymentsInvoices!: PayStudentPaymentsInvoices[];

    @Expose()
    public date!: string;

    @Expose()
    @Transform( ({ obj }) =>  moment(obj.created_at).format('YYYY-MM-DD'))
    public date_created!: string;

    constructor(payload?: Partial<PayInvoices>) {
        super();

        return plainToClass(PayInvoices, payload, { excludeExtraneousValues: true });
    }
}


