import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { Attendance } from './Attendance.entity';
import { Behavior } from './Behavior.entity';
import { EvalAttendance } from './EvalAttendance.entity';
import { Relationship } from './Relationship.entity';
import { StudentEvent } from './StudentEvent.entity';


export class Student {
    @Expose()
    public SUG_ZEHUT: any;

    @Expose()
    public idType!: number;

    @Expose()
    public id!: number;

    @Expose()
    public firstName1!: string;

    @Expose()
    public lastName1!: string;

    @Expose()
    public firstName2!: string;

    @Expose()
    public lastName2!: string;

    @Expose()
    public firstName3!: string;

    @Expose()
    public lastName3!: string;

    @Expose()
    @Transform( ({ obj }) => obj.firstName1 + ' ' + obj.lastName1)
    public fullName!: string;

    @Expose()
    public grade!: number;

    @Expose()
    public gradeSection!: number;

    @Expose()
    public CODE_MIN!: number;

    @Expose()
    public SHICHVA!: number;

    @Expose()
    public MAKBILA!: number;

    @Expose()
    public MISPAR_ZEHUT!: number;

    @Expose()
    public SHEM_PRATI!: string;

    @Expose()
    public SHEM_MISHPACHA!: string;

    @Expose()
    public SHEM_PRATI_KINUI!: string;

    @Expose()
    public NAME!: string;

    @Expose()
    public SHEM_MISHPACHA_KINUI!: string;

    @Expose()
    public SHEM_PRATI_KINUI_5!: string;

    @Expose()
    public TAARICH_LEDA!: string;

    @Expose()
    public SHEM_PRATI_KINUI_1: any;

    @Expose()
    public SHEM_MISHPACHA_KINUI_1: any;

    @Expose()
    public EMAIL: any;

    @Expose()
    public MISPAR_NAYAD: any;

    @Expose()
    public BUS_ID: any;

    @Expose()
    public roles!: any[];

    @Expose()
    public capabilities!: any[];

    @Expose()
    @Type(() => Relationship)
    public relationships!: Relationship[];

    @Expose()
    @Type(() => Attendance)
    public attendance!: Attendance;

    @Expose()
    @Type(() => Behavior)
    public behaviors: Behavior[] = [];

    @Expose()
    public myGroups!: number[];

    @Expose()
    public mySubjects!: number[];

    @Expose()
    @Type(() => StudentEvent)
    public studentEvent?: StudentEvent;

    @Expose()
    @Type(() => EvalAttendance)
    public evalAttendance?: EvalAttendance;

    public CLASS_ID!: string;

    public studentEvents?: StudentEvent[];

    public get classId(): string {
        if (this.SHICHVA && this.MAKBILA) {
            return `${this.SHICHVA}-${this.MAKBILA}`;
        }

        return '';
    }

    constructor(payload: Partial<Student>) {
        return plainToClass(Student, payload, { excludeExtraneousValues: true });
    }
}
