
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { UtilNotificationType } from './UtilNotificationType.entity';

export class UtilOrgNotificationSetting extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public notificationTypeId!: number;

    @Expose()
    @Type(() => UtilNotificationType)
    public notificationType!: UtilNotificationType;

    constructor(payload?: Partial<UtilOrgNotificationSetting>) {
        super();

        return plainToClass(UtilOrgNotificationSetting, payload, { excludeExtraneousValues: true });
    }
}
