
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { FormForm } from './Form.entity';
import * as moment from 'moment';

export class FormResult extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public formId!: number;

    @Expose()
    public result!: any;

    @Expose()
    public paidStatus!: number;

    @Expose()
    @Transform(({ obj }) => moment(obj.created_at).format('YYYY-MM-DD HH:mm'))
    public dateTime!: string;

    @Expose()
    public forms!: FormForm;

    constructor(payload?: Partial<FormResult>) {
        super();
        return plainToClass(FormResult, payload, { excludeExtraneousValues: true });
    }
}
