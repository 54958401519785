
import { Expose, Type, plainToClass } from 'class-transformer';
import { CoreGroup } from './CoreGroup.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreGroupLevel extends BaseEntityClass {
    @Expose()
    public desc!: string;

    @Expose()
    @Type(() => CoreGroup)
    public groups!: CoreGroup[];

    constructor(payload?: Partial<CoreGroupLevel>) {
        super();

        return plainToClass(CoreGroupLevel, payload, { excludeExtraneousValues: true });
    }
}
