import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { CoreTeacher } from './core/CoreTeacher.entity';
import { EcountStudentMessage } from './EcountStudentMessage.entity';
import { CoreStudent } from './core/CoreStudent.entity';


export class EcountMessage extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public date!: Date;

    @Expose()
    public content!: string;

    @Expose()
    public teacherId!: number;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    @Type(() => CoreStudent)
    public students!: CoreStudent[];

    @Expose()
    @Type(() => EcountStudentMessage)
    public studentMessages!: EcountStudentMessage[];

    constructor(payload?: Partial<EcountMessage>) {
        super();

        return plainToClass(EcountMessage, payload, { excludeExtraneousValues: true });
    }
}
