import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';


export class Sheelon extends BaseEntityClass {
    @Expose()
    public SEMEL_SHEELON!: number;

    @Expose()
    public CODE_PIRTEY_MOED!: number;

    @Expose()
    public SHEM_SHEELON!: string;

    @Expose()
    public SEMEL_MIKZOA!: number;

    @Expose()
    public SHEM_MIKZOA!: string;

    @Expose()
    public TAARICH_BCHINA!: string;

    @Expose()
    public SHAAT_HATCHALA!: number;

    @Expose()
    public SHAAT_SIYUM!: number;

    @Expose()
    public SUG_SHEELON!: number;

    @Expose()
    public HAIM_SHEELON_REFORMA!: number;

    @Expose()
    public moed_acharon_divuach_mekori!: string;

    @Expose()
    public haim_haaracha_halufit!: number;

    @Expose()
    public MISPAR_TALMIDIM!: number;

    @Expose()
    public MISPAR_KVOTSOT!: number;

    constructor(payload?: Partial<Sheelon>) {
        super();

        return plainToClass(Sheelon, payload, { excludeExtraneousValues: true });
    }
}
