import { NgModule } from '@angular/core';
import { ExtraFilterPipe } from './extra-filter.pipe';

@NgModule({
    declarations: [
        ExtraFilterPipe,
    ],
    exports: [
        ExtraFilterPipe,
    ],
    imports: [],
})
export class CommonPipesModule {
    public static forRoot(): { ngModule: typeof CommonPipesModule, providers: any[] } {
        return {
            ngModule: CommonPipesModule,
            providers: [],
        };
    }
}
