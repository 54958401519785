import { Injectable, isDevMode } from '@angular/core';
import { Group, KindixUser } from '@common/entities';
import { ILessonStoredData } from '@common/interfaces/lesson.interface';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root',
})
export class ExtraAuthService {
    private loginUrl: string = environment[`loginUrl`] || 'https://authedu.kindix.me';

    constructor(
        private cookieService: CookieService,
        private helperService: HelperService,
    ) { }

    getToken(): void {

    }

    public getKindixToken(): KindixUser {
        const res = this.cookieService.check('kindix-token') ? JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token'))) : null;

        return new KindixUser(res);
    }

    public login(app?: string): void {
        window.location.href = this.getLoginUrl(app as any);
    }

    public logout(app?: string): void {
        const user = this.getKindixToken();

        this.cookieService.deleteAll();

        localStorage.clear();

        for (const _app of this.helperService.apps) {
            this.cookieService.deleteAll(`/${_app}`);
        }

        window.location.href = this.getLoginUrl(user.logout_url, app);
    }

    private getLoginUrl(url?: string, app?: string): string {
        return `${url || this.loginUrl}/?appcode=${app}`;
    }
}
