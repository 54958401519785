import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { MappingQuestion } from './MappingQuestion.entity';

export class Question extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public programSectionId!: number;

    @Expose()
    public mappingQuestions!: MappingQuestion[];

    @Expose()
    public score!: number;

    @Expose()
    public order!: number;

    public mappingId!: number;

    constructor(payload?: Partial<Question>) {
        super();

        return plainToClass(Question, payload, { excludeExtraneousValues: true });
    }
}
