<div class="common-update-version">
	<br>
	<h5>التحديثات</h5>
	<div class="version">
		الاصدار الحالي:
		<span>(v{{ version.active_version }})</span>
	</div>
	<button *ngIf="!!version && !isUpdated" (click)="onUpdate()">
		<mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
		<span>
			التحديث الى الصدار
		</span>
		<span>
			(v{{version.formal_version}})
		</span>
	</button>
</div>