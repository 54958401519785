
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { UtilOrgNotificationSetting } from './UtilOrgNotificationSetting.entity';

export class UtilNotificationType extends BaseEntityClass {
    @Expose()
    public appName!: string;

    @Expose()
    public type!: string;

    @Expose()
    @Type(() => UtilOrgNotificationSetting)
    public orgNotificationSettings!: UtilOrgNotificationSetting[];

    constructor(payload?: Partial<UtilNotificationType>) {
        super();

        return plainToClass(UtilNotificationType, payload, { excludeExtraneousValues: true });
    }
}
