import { IProgram, ISubject } from '@common/interfaces';
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreClass } from '../core/CoreClass.entity';
import { CoreSchoolProgram } from '../core/CoreSchoolProgram.entity';

export class Mapping extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public status!: number;

    @Expose()
    public description!: string;

    @Expose()
    public gradeId!: number;

    @Expose()
    public gardeSectionId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public subject!: ISubject;

    @Expose()
    public class!: CoreClass;

    @Expose()
    @Type(() => CoreSchoolProgram)
    public program?: CoreSchoolProgram;

    @Expose()
    @Type(() => CoreSchoolProgram)
    public remainingSections?: CoreSchoolProgram[];

    constructor(payload?: Partial<Mapping>) {
        super();

        return plainToClass(Mapping, payload, { excludeExtraneousValues: true });
    }
}
