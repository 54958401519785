import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Teacher } from './Teacher.entity';
import { CoreTeacher } from './core/CoreTeacher.entity';
import * as moment from 'moment';
import { EcountStatement } from './EcountStatement.entity';

export class EcountHappened extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    public content!: string;

    @Expose()
    @Type(() => Date)
    public happenedDate!: Date;

    @Expose()
    public teacherId!: number;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    public studentsIds!: number[];

    @Expose()
    @Transform(({ obj }) => moment(obj.date).format('YYYY-MM-DD'))
    public dateHappened!: string;

    @Expose()
    public statementId!: number;

    @Expose()
    @Type(() => EcountStatement)
    public statement!: EcountStatement;

    constructor(payload?: Partial<EcountHappened>) {
        super();

        return plainToClass(EcountHappened, payload, { excludeExtraneousValues: true });
    }
}
