/* eslint-disable max-classes-per-file */
import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Group } from './Group.entity';
import { EcountStatement } from './EcountStatement.entity';
import { Teacher } from './Teacher.entity';

export class GroupJSON {
    @Expose()
    @Type(() => Group)
    public json!: Group;

    @Expose()
    public id!: number;

    @Expose()
    public name!: string;

    @Expose()
    public isUsedAllMisparShaot!: boolean;

    @Expose()
    public timeTable!: any[];

    @Expose()
    public teachers!: any[];

    @Expose()
    public TEUR_SUG_KVUTSA!: string;

    @Expose()
    public CODE_SUG_KVUTSA!: number;

    @Expose()
    public SHEM_KVUTSA!: string;

    @Expose()
    public classes!: any[];

    constructor(payload?: Partial<GroupJSON>) {
        return plainToClass(GroupJSON, payload, { excludeExtraneousValues: true });
    }
}


export class TeacherAbsent {
    @Expose()
    public teacherId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    public date!: string;

    @Expose()
    public dayId!: number;

    @Expose()
    public resone: any;

    @Expose()
    public note: any;

    @Expose()
    public imgBase64: any;

    @Expose()
    public startTime!: string;

    @Expose()
    public endTime!: string;

    @Expose()
    public status!: number;

    @Expose()
    public approve!: boolean;

    @Expose()
    public teacherReplacId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    @Type(() => GroupJSON)
    public group!: GroupJSON;

    @Expose()
    public teacherAbsent!: Teacher;

    @Expose()
    public teacher!: Teacher;

    constructor(payload?: Partial<TeacherAbsent>) {
        return plainToClass(TeacherAbsent, payload, { excludeExtraneousValues: true });
    }
}
