import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { ServeMusicToolReservation } from './ServeMusicToolReservation';
import { ServeMusicToolStatuses } from './ServeMusicToolStatuses';
import { ServeMusicToolCategories } from './ServeMusicToolCategories';

export class ServeMusicTool extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public type!: string;

    @Expose()
    public code!: string;

    @Expose()
    public manager!: string;

    @Expose()
    public storagePlace!: string;

    @Expose()
    public statusId?: number = 1;

    @Expose()
    public status?: ServeMusicToolStatuses;

    @Expose()
    public categoryId?: number = 1;

    @Expose()
    public category?: ServeMusicToolCategories;

    @Expose()
    public musicToolReservations?: ServeMusicToolReservation[];

    constructor(payload?: Partial<ServeMusicTool>) {
        super();

        return plainToClass(ServeMusicTool, payload, { excludeExtraneousValues: true });
    }
}
