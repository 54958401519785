import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { SMS } from './SMS.entity';


export class SMSPhoneStatus extends BaseEntityClass {
    @Expose()
    public smsId!: number;

    @Expose()
    public phone!: string;

    @Expose()
    public status!: number;

    @Expose()
    @Type(() => SMS)
    public sms!: SMS;

    constructor(payload?: Partial<SMSPhoneStatus>) {
        super();

        return plainToClass(SMSPhoneStatus, payload, { excludeExtraneousValues: true });
    }
}
