<div
  class="progress"
  role="progressbar"
  [attr.aria-valuemin]="valuemin"
  [attr.aria-valuemax]="valuemax"
  [attr.aria-valuenow]="_value"
>
  <div class="progress-bar bg-{{ color }}" [style.width.%]="_value">
    {{ _value }}%
  </div>
</div>
