<div class="custom-input" [dir]="direction" [class.input-rtl]="direction">
  <div
    [class.row]="horizontal.label && horizontal.input"
    [class.form-group]="label && !horizontal.label && !horizontal.input"
  >
    <label
      [for]="id"
      *ngIf="label"
      class="form-label {{ labelCol }}"
      [class.mt-2]="horizontal.label && horizontal.input"
    >
      <div>
        <span style="display: inline-block">
          {{ title }}
        </span>
        <span style="display: inline-block" *ngIf="required" style="color: red">
          *
        </span>
      </div>
    </label>
    <div class="input-with-icon {{ inputCol }}">
      <i *ngIf="!!icon" class="i-icon" [class]="icon"></i>
      <ng-select
        [disabled]="disabled"
        class="core-select"
        [class.is-invalid]="isInvalid"
        [id]="id"
        [loading]="loading"
        [items]="_dropdownList"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [multiple]="multiple"
        [closeOnSelect]="true"
        [searchable]="searchable"
        [placeholder]="title"
        [class.input-bg]="bg"
        [dir]="direction"
        [(ngModel)]="inputValue"
        (change)="onChangeItemsSelect($event)"
        [appendTo]="appendTo"
        (clear)="reset()"
      >
        <ng-container *ngIf="multiple">
          <ng-template ng-header-tmp>
            <div class="custom-form-check">
              <input
                (change)="onChangeItemsSelectAll($event)"
                class="custom-form-check-input"
                type="checkbox"
                [(ngModel)]="isSelectedAll"
                id="flexCheckDefault"
              />
              <label class="custom-form-check-label" for="flexCheckDefault">
                {{ "list.select_all" | translate }}
              </label>
            </div>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div
              class="ng-value"
              *ngFor="let item of items | slice : 0 : tagShow"
            >
              <span class="ng-value-label">
                {{ $any(item)[bindLabel] }}
              </span>
              <span
                class="ng-value-icon right"
                (click)="clear(item)"
                aria-hidden="true"
                >×</span
              >
            </div>

            <div class="ng-value" style="background-color: rgba(240, 248, 255, 0.479); color: #0000007c;" *ngIf="items.length > tagShow">
              <span class="ng-value-label"
              style="padding: 0;"
                >+{{ items.length - tagShow }}</span
              >
            </div>
          </ng-template>
        </ng-container>
      </ng-select>
      <div class="invalid-feedback" *ngIf="!validation?.status">
        <span *ngFor="let item of validation?.messages">
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</div>
