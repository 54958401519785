import { ISubject } from '@common/interfaces';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { EcountStatement } from './EcountStatement.entity';
import { Teacher } from './Teacher.entity';
import { CoreSubject } from './core/CoreSubject.entity';
import { CoreTeacher } from './core/CoreTeacher.entity';


export class Behavior extends BaseEntityClass {
    @Expose()
    public lessonId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public teacherId!: number;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    public groupId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public subject!: CoreSubject;

    @Expose()
    public statementId!: number;

    @Expose()
    @Type(() => EcountStatement)
    public statement!: EcountStatement;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    @Transform(item => moment(item.obj.created_at).format('YYYY-MM-DD'))
    public dateStr!: string;

    constructor(payload: Partial<Behavior>) {
        super();

        return plainToClass(Behavior, payload, { excludeExtraneousValues: true });
    }
}
