
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { PayPaymentType } from './PayPaymentTypes.entity';
import * as moment from 'moment';
import { PayStudentPayments } from './PayStudentPayments.entity';

export class PayPayments extends BaseEntityClass {

    @Expose()
    public title!: string;

    @Expose()
    public paymentTypeId!: number;

    @Expose()
    public paymentType!: PayPaymentType;

    @Expose()
    public cost!: number;

    @Expose()
    public date!: string;

    @Expose()
    public status!: number;

    @Expose()
    public studentPayments!: PayStudentPayments[];

    constructor(payload?: Partial<PayPayments>) {
        super();

        return plainToClass(PayPayments, payload, { excludeExtraneousValues: true });
    }
}


