import {
    CoreGrade, CorePeriod, CoreProfile, CoreRelationshipType, CoreSubject,
    CoreGroup, CoreClass, CoreStudent, CoreGroupType, CoreTeacher, CoreGender,
    CoreGroupActivityType, CoreGroupStatus, CoreEducationPhase, CoreHealthMinistry,
    CoreStudentBus,
} from '@common/entities';
import { ExcelOptions, IAction, IFindPaginateOptions, ILesson } from '@common/interfaces';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { HelperService } from '../helpers/helper.service';
import { TeacherStatus } from '@common/enums';
import { CoreLesson } from '@common/entities/core/CoreLesson.entity';

export interface IImportResult {
	rows: number;
	pass: number;
	fail: number;
	errors: any[];
	missedCategories: { [cat: string]: boolean };
}

export interface ICommonApiService {
    getProfile(): Promise<CoreProfile>;
    getRelationshipTypes(): Promise<CoreRelationshipType[]>;
    getLessons(): Promise<CoreLesson[]>;
    getSubjects(): Promise<CoreSubject[]>;
    getGrade(): Promise<CoreGrade[]>;
    getPeriods(): Promise<CorePeriod[]>;
    getClassesOfTeacher(): Promise<CoreClass[]>;
    getGroupsByClasses(classes: CoreClass[]): Promise<CoreGroup[]>;
    getGroupsByIds(groupIds: (string | number)[]): Promise<CoreGroup[]>;
    getGroupsWithClassesById(groupIds: (string | number)[]): Promise<CoreGroup[]>;
    getStudentsByGroupId(groupId: number): Promise<CoreStudent[]>;
    getStudentsByFilter(filter: IFindPaginateOptions): Promise<CoreStudent[]>;
    getStudentsByStudentsIds(studentsIds: number[]): Promise<CoreStudent[]>;
    getClassesByRoleUser(): Promise<CoreClass[]>;
    getGroupTypes(): Promise<CoreGroupType[]>;
    getTeacher(filter?: IFindPaginateOptions): Promise<CoreTeacher[]>;
    getCoreProfileUser(): Promise<CoreProfile>;
    exportDataAsExcelByFilter(table: string, filter: IFindPaginateOptions, config: ExcelOptions): Promise<any>;
    importFile(file: Blob, table: string): Promise<IImportResult>;
    getGroupsByFilter(filter: IFindPaginateOptions): Promise<CoreGroup[]>;
}


export class CommonApiService implements ICommonApiService {
    protected prefix: string;

    constructor(
        protected extraApiService: ExtraApiService,
        prefixRoute: string,
        protected helperService: HelperService,
    ) {
        this.prefix = prefixRoute;
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Core Apis                                 */
    /* -------------------------------------------------------------------------- */
    public async getProfile(): Promise<CoreProfile> {
        return this.extraApiService.request(CoreProfile, 'post', `/${this.prefix}/cache/profile`);
    }

    public async getRelationshipTypes(): Promise<CoreRelationshipType[]> {
        return this.extraApiService.request<CoreRelationshipType[]>(CoreRelationshipType, 'post', `/${this.prefix}/cache/relationship-types`);
    }

    public async getLessons(): Promise<CoreLesson[]> {
        return this.extraApiService.request<CoreLesson[]>(CoreLesson, 'post', `/${this.prefix}/cache/lessons`);
    }

    public async getHealthMinistries(): Promise<CoreHealthMinistry[]> {
        return this.extraApiService.request<CoreHealthMinistry[]>(CoreHealthMinistry, 'post', `/${this.prefix}/cache/HealthMinistrys`);
    }

    public async getSubjects(): Promise<CoreSubject[]> {
        return this.extraApiService.request<CoreSubject[]>(CoreSubject, 'post', `/${this.prefix}/cache/subjects`);
    }

    public async getSubjectsOfTeacher(): Promise<CoreSubject[]> {
        return this.extraApiService.request<CoreSubject[]>(CoreSubject, 'post', `/${this.prefix}/cache/subjects-of-teacher`);
    }

    public async getGenders(): Promise<CoreGender[]> {
        return this.extraApiService.request<CoreGender[]>(CoreGender, 'post', `/${this.prefix}/cache/genders`);
    }

    public async getGrade(): Promise<CoreGrade[]> {
        return this.extraApiService.request<CoreGrade[]>(CoreGrade, 'post', `/${this.prefix}/cache/grades`);
    }

    public async getPeriods(): Promise<CorePeriod[]> {
        return this.extraApiService.request<CorePeriod[]>(CorePeriod, 'post', `/${this.prefix}/cache/periods`);
    }

    public async getEducationPhases(): Promise<CoreEducationPhase[]> {
        return this.extraApiService.request(CoreEducationPhase, 'post',`/${this.prefix}/cache/get-education-phases`);
    }

    public async getClassesOfTeacher(): Promise<CoreClass[]> {
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/${this.prefix}/cache/get-classes-of-teacher`).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getClassesWithClassEducator(filter?: IFindPaginateOptions): Promise<CoreClass[]> {
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/${this.prefix}/cache/get-classes-with-educators`, filter).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getGroupsByClasses(classes: CoreClass[]): Promise<CoreGroup[]> {
        const body = classes.map(item => {
            return _.pick(item, [
                'name1',
                'name2',
                'grade',
                'gradeSection',
                'classType',
                'classTypeDesc',
                'minStudents',
                'maxStudents',
                'status',
                'statusDesc',
                'location',
                'address',
                'studentsCount',
                'classId',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/${this.prefix}/cache/get-groups-by-classes`, body).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getGroupsByIds(groupIds: (string | number)[]): Promise<CoreGroup[]> {
        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/${this.prefix}/cache/get-groups-by-id/${groupIds.join(',')}`);
    }

    public async getSingleGroupsOfTeacher(relations?: (keyof CoreGroup)[]): Promise<CoreGroup[]> {
        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/${this.prefix}/cache/get-single-groups-of-teacher`, { relations });
    }

    public async getGroupsWithClassesById(groupIds: (string | number)[]): Promise<CoreGroup[]> {
        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/${this.prefix}/cache/get-groups-with-classes-by-id/${groupIds.join(',')}`);
    }

    public async getStudentsByGroupId(groupId: number, filter?: IFindPaginateOptions): Promise<CoreStudent[]> {
        return this.extraApiService.request(CoreStudent, 'post', `/${this.prefix}/cache/get-students-by-group-id/${groupId}`, filter);
    }

    public async getStudentsByFilter(filter: IFindPaginateOptions): Promise<CoreStudent[]> {
        return this.extraApiService.request(CoreStudent, 'post', `/${this.prefix}/cache/get-students-by-filter`, filter);
    }

    public async getGroupsByFilter(filter: IFindPaginateOptions): Promise<CoreGroup[]> {
        return this.extraApiService.request(CoreGroup, 'post', `/${this.prefix}/cache/get-groups`, filter);
    }

    public async getCoreGroupsHoursEachTeachersAndTypes(filter?: IFindPaginateOptions): Promise<{
        [teacherId: string]: {
            [groupTypeId: string]: {
                [groupId: string]: number;
            };
        };
    }> {
        return this.extraApiService.post(`/${this.prefix}/cache/get-groups-hours-each-teachers-and-types`, filter);
    }

    public async getStudentsByStudentsIds(studentsIds: number[], relations?: string[]): Promise<CoreStudent[]> {
        return this.extraApiService.request(CoreStudent, 'post', `/${this.prefix}/cache/students`, { studentsIds, relations });
    }

    public async getClassesByRoleUser(): Promise<CoreClass[]> {
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/${this.prefix}/cache/get-classes-by-role-user`).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getClassesByRoleUserStats(): Promise<CoreClass[]> {
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/${this.prefix}/cache/get-classes-by-role-user-stats`).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getClasses(): Promise<CoreClass[]> {
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/${this.prefix}/cache/classes`).then(res => {
            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getGroupTypes(): Promise<CoreGroupType[]> {
        return this.extraApiService.request(CoreGroupType, 'post',`/${this.prefix}/cache/group-types`);
    }

    public async getGroupActivityTypes(): Promise<CoreGroupActivityType[]> {
        return this.extraApiService.request(CoreGroupActivityType, 'post', `/${this.prefix}/cache/group-activity-types`);
    }

    public async getGroupStatuses(): Promise<CoreGroupStatus[]> {
        return this.extraApiService.request(CoreGroupStatus, 'post', `/${this.prefix}/cache/get-group-statues`);
    }

    public async getTeacher(filter?: IFindPaginateOptions, all?: boolean): Promise<CoreTeacher[]> {
        const copyFilter = _.cloneDeep(filter || { });

        if (!all) {
            _.setWith(copyFilter, ['filter', 'where', 'userStatusId'], TeacherStatus.ACTIVE, Object);
        }

        return this.extraApiService.request<CoreTeacher[]>(CoreTeacher, 'post', `/${this.prefix}/cache/teachers`, copyFilter).then(res => {
            return _.orderBy(res, ['firstName1', 'lastName1']);
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                New Core Apis                               */
    /* -------------------------------------------------------------------------- */
    public async getCoreProfileUser(): Promise<CoreProfile> {
        return this.extraApiService.request(CoreProfile, 'post', `/${this.prefix}/cache/profile`);
    }

    public async exportDataAsExcelByFilter(table: string, filter: IFindPaginateOptions, config: ExcelOptions): Promise<any> {
        return this.extraApiService.postFile(`/${this.prefix}/util/export/${table}`, { ...config, ...filter });
    }

    public async exportDataAsXlsx(data: any[],  config: ExcelOptions): Promise<any> {
        return this.extraApiService.postFile(`/${this.prefix}/util/export-data-as-xlsx`, { config, data });
    }

    public async importFile(file: Blob, table: string): Promise<IImportResult> {
        const formParams = new FormData();

        formParams.append('data_file', file);

        return this.extraApiService.uploadFile(`/${this.prefix}/util/import/${table}`, formParams);
    }

    public async resetPassword(body: { tz: number }): Promise<IImportResult> {
        return this.extraApiService.extraRequest('post', '/accounts/reset_password_core', { body }, 'https://accounts-api.kindix.me/api');
    }

    public async getClassesByRoleAndClassesOfTeacher(filter?: IFindPaginateOptions): Promise<CoreClass[]> {
        return this.extraApiService.request(CoreClass, 'post', `/${this.prefix}/cache/get-classes-by-role-and-classes-of-teacher`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Cloud APIs                                 */
    /* -------------------------------------------------------------------------- */
    public async processingActions(body: IAction[]): Promise<any> {
        return this.extraApiService.post(`/${this.prefix}/actions/processing`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Buses API                                 */
    /* -------------------------------------------------------------------------- */
    public async getStudentBuses(filter?: IFindPaginateOptions, loading?: boolean): Promise<CoreStudentBus[]> {
        loading = true;

        return this.extraApiService.request<CoreStudentBus[]>(CoreStudentBus, 'post', `/${this.prefix}/cache/student-buses`, filter).then(res => {
            loading = false;

            return res;
        });
    }
}
