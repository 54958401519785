import { Injectable, isDevMode } from '@angular/core';
import { CoreProfile } from '@common/entities';
import { StorageService } from './storage.service';
import { NavigationExtras, Router } from '@angular/router';
@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    private profile!: CoreProfile;

    constructor(
        private storageService: StorageService,
        private router: Router,
    ) { }

    private get capabilities(): { [key: string]: boolean } {
        if (!this.profile) {
            this.profile = this.storageService.getCoreProfile();
        }

        return this.profile.capabilities || { };
    }

    public checkCapability(capabilities: string[]): boolean {
        if (isDevMode()) {
            return (true);
        } else {
            return capabilities.some(item => !!this.capabilities[item]);
        }
    }

    public destroyProfile(): void {
        this.profile = null as any;
        this.storageService.clearData();
    }

    public navigate(url: string, state: { [k: string]: any }): void {
        const navigationExtras: NavigationExtras = { state };

        this.router.navigate([url], navigationExtras);
    }
}
