import { Injectable } from '@angular/core';
import {
    Attendance, AttendanceStatus, Behavior, ExamSchedule, Group,
    EcountHappened, LessonTopic, CoreClass, EcountSetting, EcountStatement, Student,
    StudentExamSchedule, StudentFileCategory, Teacher, EcountMessage,
} from '@common/entities';
import {
    IClass, IFindOneOptions, IFindPaginateOptions, IGroupType, ILesson,
    ILessonStatusClassesResult, ILessonTopicFilter, ILessonTopicReport,
    ILiveReportResult, ILiveSchoolEachClassReportRow, ILiveSchoolReportResult, IProfile, IRelationship, IReportFilter, IStatementFilter,
} from '@common/interfaces';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { EcountLessonStatus } from '@common/entities/EcountLessonStatus.entity';
import { StatementScope } from '../../entities/StatementScope.entity';
import { TranslateService } from '@ngx-translate/core';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { HelperService } from '../helpers/helper.service';
import { COMMON_FIELDS } from '@common/constants';

export interface IEcountApiService {
    getSettings(filter?: IFindOneOptions): Promise<EcountSetting[]>;
    getUpsertSettings(settings: EcountSetting[]): Promise<EcountSetting[]>;
    getLessonStatusesByFilter(filter: IFindPaginateOptions): Promise<EcountLessonStatus[]>;
    upsertAttendances(attendances: Attendance[]): Promise<Attendance[]>;
    deleteAttendanceByQuery(filter: IFindPaginateOptions): Promise<Attendance[]>;
    getAttendancesByFilter(query: IFindPaginateOptions): Promise<Attendance[]>;
    getAttendanceStatues(): Promise<AttendanceStatus[]>;
    getStatements(filter: IFindPaginateOptions): Promise<EcountStatement[]>;
    upsertStatements(statements: EcountStatement[]): Promise<EcountStatement[]>;
    softDeleteStatements(id: number): Promise<EcountStatement[]>;
    restoreStatements(id: number): Promise<EcountStatement[]>;
    getAllStatementScopes(): Promise<StatementScope[]>;
    getStatementScopesByFilter(filter: IFindPaginateOptions): Promise<StatementScope[]>;
    getBehaviors(filter: IFindPaginateOptions): Promise<Behavior[]>;
    upsertBehaviors(behaviors: Behavior[]): Promise<Behavior[]>;
    deleteBehaviorsByQuery(filter: IFindPaginateOptions): Promise<EcountStatement[]>;
    getLessonTopics(filter: IFindPaginateOptions): Promise<LessonTopic[]>;
    upsertLessonTopics(lessons: LessonTopic[]): Promise<LessonTopic[]>;
    deleteLessonTopicsByQuery(filter: IFindPaginateOptions): Promise<any>;
    getLessonStatusByClasses(filter: { date: string, classIds: string[] }): Promise<ILessonStatusClassesResult>;
    getLiveReport(filter: IReportFilter): Promise<ILiveReportResult>;
    getExcelLiveReport(filter: IReportFilter): Promise<any>;
    getGeneralExcelLiveReport(filter: IReportFilter): Promise<any>;
    getGeneralMonthlyExcelLiveReport(filter: IReportFilter): Promise<any>;
    getIntervalReport(filter: IReportFilter): Promise<ILiveReportResult>;
    getLessonTopicReport(filter: ILessonTopicFilter): Promise<ILessonTopicReport[]>;
    getLiveSchoolReport(filter: IReportFilter): Promise<ILiveSchoolReportResult>;
    getLiveSchoolReportByDate(filter: IReportFilter): Promise<ILiveSchoolReportResult>;
    getLiveSchoolReportEachClass(filter: IReportFilter): Promise<ILiveSchoolEachClassReportRow[]>;
    getStatementScopesByBehavior(query: IStatementFilter): Promise<StatementScope[]>;
    upsertHappenings(happenings: EcountHappened[]): Promise<EcountHappened[]>;
    getHappeningsByStudentHappened(filter: IFindPaginateOptions): Promise<EcountHappened[]>;
    deleteExamSchedulesForever(filter: IFindPaginateOptions): Promise<any>;
    getLessonsMonthlyExcelLiveReport(filter: IReportFilter): Promise<any>;
}

@Injectable({
    providedIn: 'root',
})
export class EcountApiService extends CommonApiService implements IEcountApiService, ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'ecount', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  settings                                  */
    /* -------------------------------------------------------------------------- */
    public async getSettings(filter?: IFindOneOptions): Promise<EcountSetting[]> {
        return this.extraApiService.request(EcountSetting, 'post', '/ecount/settings/by-filter', filter);
    }

    public async getUpsertSettings(settings: EcountSetting[]): Promise<EcountSetting[]> {
        return this.extraApiService.request(EcountSetting, 'post', '/ecount/settings/upsert', settings);
    }

    /* -------------------------------------------------------------------------- */
    /*                            lessons status routes                           */
    /* -------------------------------------------------------------------------- */
    public async getLessonStatusesByFilter(filter: IFindPaginateOptions): Promise<EcountLessonStatus[]> {
        return this.extraApiService.request(EcountLessonStatus, 'post', `/ecount/lessons/statues/by-filter`, filter);
    }

    public async upsertLessonStatuses(status: EcountLessonStatus[]): Promise<EcountLessonStatus[]> {
        const data = status.map(item => _.pick(item, [
            ...COMMON_FIELDS,
            'groupId',
            'lessonId',
            'teacherId',
            'action',
            'approved',
            'approvedDate',
            'date',
        ]));
        return this.extraApiService.request(EcountLessonStatus, 'post', `/ecount/lessons/statues/upsert`, data);
    }

    /* -------------------------------------------------------------------------- */
    /*                             attendances routes                             */
    /* -------------------------------------------------------------------------- */
    public async upsertAttendances(attendances: Attendance[]): Promise<Attendance[]> {
        const data = attendances.map(item => _.pick(item, 'lessonId', 'subjectId', 'teacherId', 'studentId', 'groupId', 'date', 'status', 'statementId'));
        return this.extraApiService.post(`/ecount/attendances/upsert`, data);
    }

    public async deleteAttendanceByQuery(filter: IFindPaginateOptions): Promise<Attendance[]> {
        return this.extraApiService.post(`/ecount/attendances/delete`, filter);
    }

    public async getAttendancesByFilter(query: IFindPaginateOptions): Promise<Attendance[]> {
        return this.extraApiService.request(Attendance, 'post', `/ecount/attendances/by-filter`, query);
    }

    public async getAttendanceStatues(): Promise<AttendanceStatus[]> {
        return this.extraApiService.request(AttendanceStatus, 'get', `/ecount/attendances/statues/all`);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 statements                                 */
    /* -------------------------------------------------------------------------- */
    public async getStatements(filter: IFindPaginateOptions): Promise<EcountStatement[]> {
        return this.extraApiService.request(EcountStatement, 'post', `/ecount/statements/by-filter`, filter);
    }

    public async upsertStatements(statements: EcountStatement[]): Promise<EcountStatement[]> {
        const data = statements.map(item => _.omit(item, ['created_at', 'deleted_at', 'updated_at', 'isDeleted']));
        return this.extraApiService.request(EcountStatement, 'post', `/ecount/statements/upsert`, data);
    }

    public async softDeleteStatements(id: number): Promise<EcountStatement[]> {
        return this.extraApiService.delete(`/ecount/statements/soft-delete/${id}`, { });
    }

    public async restoreStatements(id: number): Promise<EcountStatement[]> {
        return this.extraApiService.put(`/ecount/statements/restore/${id}`, { });
    }

    public async getAllStatementScopes(): Promise<StatementScope[]> {
        return this.extraApiService.request(StatementScope, 'get', `/ecount/statements/statement_scopes/all`);
    }

    public async getStatementScopesByBehavior(query: IStatementFilter): Promise<StatementScope[]> {
        return this.extraApiService.request(StatementScope, 'post', `/ecount/statements/statement_scopes/with-behaviors`, query);
    }

    public async getStatementScopesByFilter(filter: IFindPaginateOptions): Promise<StatementScope[]> {
        return this.extraApiService.request<StatementScope[]>(
            StatementScope,
            'post',
            `/ecount/statements/statement_scopes/by-filter`,
            filter,
        ).then(async (res: StatementScope[]) => {
            const trans: { [key: string]: any } = await this.helperService.getTranslate('statements');

            return res.map(item => ({ ...item, name: trans[item.name] }));
        });
    }

    public async getMessagesByStudent(body: { studentId: number, startDate: string, endDate: string }): Promise<EcountMessage[]> {
        return this.extraApiService.request<EcountMessage[]>(EcountMessage, 'post', `/ecount/students/messages/by-student`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  behaviors                                 */
    /* -------------------------------------------------------------------------- */
    public async getBehaviors(filter: IFindPaginateOptions): Promise<Behavior[]> {
        return this.extraApiService.request(Behavior, 'post', `/ecount/behaviors/by-filter`, filter);
    }

    public async upsertBehaviors(behaviors: Behavior[]): Promise<Behavior[]> {
        const data = behaviors.map(item => _.omit(item, ['created_at', 'deleted_at', 'updated_at', 'isDeleted', 'dateStr']));
        return this.extraApiService.request(Behavior, 'post', `/ecount/behaviors/upsert`, data);
    }

    public async deleteBehaviorsByQuery(filter: IFindPaginateOptions): Promise<EcountStatement[]> {
        return this.extraApiService.post(`/ecount/behaviors/delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   lessons                                  */
    /* -------------------------------------------------------------------------- */
    public async getLessonTopics(filter: IFindPaginateOptions): Promise<LessonTopic[]> {
        return this.extraApiService.request(LessonTopic, 'post', `/ecount/lessons/topics/by-filter`, filter);
    }

    public async getLessonTopicsByStudentTopic(filter: IFindPaginateOptions): Promise<LessonTopic[]> {
        return this.extraApiService.request(LessonTopic, 'post', `/ecount/lessons/topics/by-student-topic`, filter);
    }

    public async upsertLessonTopics(lessons: LessonTopic[]): Promise<LessonTopic[]> {
        const data = lessons.map(item => _.omit(item, ['created_at', 'deleted_at', 'updated_at', 'isDeleted']));
        return this.extraApiService.request(LessonTopic, 'post', `/ecount/lessons/topics/upsert`, data);
    }

    public async deleteLessonTopicsByQuery(filter: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post(`/ecount/lessons/topics/delete`, filter);
    }

    public async getLessonStatusByClasses(filter: { date: string, classIds: string[], teachersIds: number[] }): Promise<ILessonStatusClassesResult> {
        return this.extraApiService.post(`/ecount/lessons/statues/by-classes`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   reports                                  */
    /* -------------------------------------------------------------------------- */
    public async getLiveReport(filter: IReportFilter): Promise<ILiveReportResult> {
        const classes: any[] = (filter.classes as any).map((item: any) => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/live-report', body);
    }

    public async getExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/live-report-excel', body);
    }

    public async getGeneralExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/general-live-report-excel', body);
    }

    public async getGeneralMonthlyExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/general-monthly-live-report-excel', body);
    }

    public async getGeneralMonthlyExcelBusReport(filter: IReportFilter): Promise<any> {
        const body: IReportFilter = {
            ...filter,
        };

        delete (body as any).classes;

        return this.extraApiService.postFile('/ecount/reports/general-monthly-bus-report-excel', body);
    }

    public async getGeneralClassesMonthlyExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/general-classes-monthly-live-report-excel', body);
    }

    public async getGeneralAttendancePercentageMonthlyExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/attendance-percentage-live-report-excel', body);
    }

    public async getLessonsMonthlyExcelLiveReport(filter: IReportFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.postFile('/ecount/reports/lessons-monthly-live-report-excel', body);
    }

    public async getIntervalReport(filter: IReportFilter): Promise<ILiveReportResult> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/interval-report', body);
    }

    public async getLessonTopicReport(filter: ILessonTopicFilter): Promise<ILessonTopicReport[]> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: ILessonTopicFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/lesson-topic-report', body);
    }

    public async getGenerateGeneralLessonTopicReport(filter: ILessonTopicFilter): Promise<any> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: ILessonTopicFilter = {
            ...filter,
            classes,
        };
        return this.extraApiService.postFile('/ecount/reports/general-lesson-topic-report', body);
    }

    public async getLiveSchoolReport(filter: IReportFilter): Promise<ILiveSchoolReportResult> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/live-school-report', body).then(res => res || { });
    }

    public async getLiveSchoolReportByDate(filter: IReportFilter): Promise<ILiveSchoolReportResult> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/live-school-report-by-date', body).then(res => res || { });
    }

    public async getLiveSchoolReportEachClass(filter: IReportFilter): Promise<ILiveSchoolEachClassReportRow[]> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/live-school-report-each-class', body).then(res => res || { });
    }

    public async getStudentsReportEachSubject(filter: IReportFilter): Promise<{ [studentId: string]: { [key: string]: number } }> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/students-report-each-subject', body).then(res => res || { });
    }

    public async getStudentsReportEachStatementScope(filter: IReportFilter): Promise<{ [studentId: string]: { [key: string]: number } }> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/students-report-each-statement-scope', body).then(res => res || { });
    }

    public async getStudentsReportKeys(filter: IReportFilter): Promise<any[]> {
        const classes: any[] = filter.classes.map(item => {
            return _.pick(item, [
                'classId',
                'name1',
                'grade',
                'gradeSection',
            ]);
        });

        const body: IReportFilter = {
            ...filter,
            classes,
        };

        return this.extraApiService.post('/ecount/reports/students-report-keys', body).then(res => res || []);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Happened                                  */
    /* -------------------------------------------------------------------------- */
    public async getHappenings(filter: IFindPaginateOptions): Promise<EcountHappened[]> {
        return this.extraApiService.request(EcountHappened, 'post', `/ecount/happenings/by-filter`, filter);
    }

    public async getHappeningsByStudentHappened(filter: IFindPaginateOptions): Promise<EcountHappened[]> {
        return this.extraApiService.request(EcountHappened, 'post', `/ecount/happenings/by-student-happened`, filter);
    }

    public async upsertHappenings(happenings: Partial<EcountHappened>[]): Promise<EcountHappened[]> {
        return this.extraApiService.request(EcountHappened, 'post', `/ecount/happenings/upsert`, happenings);
    }

    public async deleteHappeningsForever(filter: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post(`/ecount/happenings/delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Exams                                   */
    /* -------------------------------------------------------------------------- */
    public async getExamSchedulesOfTeacher(filter: { startDate: string, endDate: string }): Promise<ExamSchedule[]> {
        return this.extraApiService.request(ExamSchedule, 'post', `/ecount/exams/get-exam-schedules-of-teacher`, filter);
    }

    public async getExamSchedulesOfTeacherNew(
        filter: { startDate: string, endDate: string , classes: any },
    ): Promise<Record<string, Record<string, ExamSchedule[]>>> {
        return this.extraApiService.post(`/ecount/exams/get-exam-of-classes`, filter);
    }

    public async deleteClassExamSchedules(filter: {
        grade: number;
        gradeSection: number;
        groupId: number;
        date: any;
    }): Promise<Record<string, Record<string, ExamSchedule[]>>> {
        return this.extraApiService.post(`/ecount/exams/delete-exam-of-classes`, filter);
    }

    public async upsertExamSchedules(examSchedules: ExamSchedule[]): Promise<ExamSchedule[]> {
        const body = examSchedules.map(item => {
            return _.pick(item, [
                'id', 'year', 'externalId', 'groupId', 'lessonId', 'date', 'teacherId', 'subjectId', 'examTopic', 'examDetail',
            ]);
        });

        return this.extraApiService.request(ExamSchedule, 'post', `/ecount/exams/upsert`, body);
    }

    public async deleteExamSchedulesForever(filter: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post(`/ecount/exams/delete`, filter);
    }

    public async getExamSchedulesByStudentExamSchedule(filter: IFindPaginateOptions): Promise<ExamSchedule[]> {
        return this.extraApiService.request(ExamSchedule, 'post', `/ecount/exams/by-student-exam-schedule`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                            Student File Category                           */
    /* -------------------------------------------------------------------------- */
    public async getStudentFileCategoriesByFilter(filter?: IFindPaginateOptions): Promise<StudentFileCategory[]> {
        return this.extraApiService.request(StudentFileCategory, 'post', `/ecount/students/student-file-categories/by-filter`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                Messages apis                               */
    /* -------------------------------------------------------------------------- */

    public async getEcountMessagesByFilter(filter?: IFindPaginateOptions): Promise<EcountMessage[]> {
        return this.extraApiService.request(EcountMessage, 'post', `/ecount/students/messages/by-filter`, filter);
    }

    public async upsertEcountMessage(msg: EcountMessage & { studentsIds?: number[] }): Promise<EcountMessage> {
        const body = _.pick(msg, [
            ...COMMON_FIELDS,
            'id',
            'title',
            'date',
            'content',
            'teacherId',
            'studentsIds',
        ]);

        return this.extraApiService.request(EcountMessage, 'post', `/ecount/students/messages/upsert-one`, body);
    }
}
