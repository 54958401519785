
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { CoreEduProgramManager } from './CoreEduProgramManager.entity';
import { CoreEduProgram } from './CoreEduProgram.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreEduProgramGroup } from './CoreEduProgramGroup.entity';

export class CoreEduProgramOrg extends BaseEntityClass {
    @Expose()
    public eduProgramId!: number;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    @Type(() => CoreEduProgramManager)
    public eduProgramManager!: CoreEduProgramManager;

    @Expose()
    @Type(() => CoreEduProgram)
    public eduProgram!: CoreEduProgram;

    @Expose()
    @Type(() => CoreEduProgramGroup)
    public eduProgramGroups!: CoreEduProgramGroup[];

    @Expose()
    @Transform(({ obj }) => obj.eduProgramGroups && obj.eduProgramGroups.length || 0)
    public eduProgramGroupsCount!: CoreEduProgramGroup[];

    constructor(payload?: Partial<CoreEduProgramOrg>) {
        super();

        return plainToClass(CoreEduProgramOrg, payload, { excludeExtraneousValues: true });
    }
}
