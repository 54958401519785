
import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreGrade extends BaseEntityClass {
    @Expose()
    public grade!: number;

    @Expose()
    public name1!: string;

    @Expose()
    public name2!: string;

    constructor(payload?: Partial<CoreGrade>) {
        super();

        return plainToClass(CoreGrade, payload, { excludeExtraneousValues: true });
    }
}
