import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Behavior } from './Behavior.entity';

export class StatementScope extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public type!: number;

    @Expose()
    public color!: string;

    @Expose()
    @Type(() => Behavior)
    public behaviors!: Behavior[];

    @Expose()
    @Transform(item => moment(item.obj.created_at).format('YYYY-MM-DD'))
    public dateStr!: string;

    constructor(payload: Partial<StatementScope>) {
        super();

        return plainToClass(StatementScope, payload, { excludeExtraneousValues: true });
    }
}
