import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class MappingLink extends BaseEntityClass {
    @Expose()
    public item!: string;

    @Expose()
    public itemId!: string;

    @Expose()
    public mappingId!: number;

    @Expose()
    public gradeId!: number;

    @Expose()
    public gardeSectionId!: number;

    @Expose()
    public groupId!: number;

    constructor(payload?: Partial<MappingLink>) {
        super();

        return plainToClass(MappingLink, payload, { excludeExtraneousValues: true });
    }
}
