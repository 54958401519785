import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'core-textarea',
    templateUrl: './core-textarea.component.html',
    styleUrls: ['./core-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoreTextareaComponent),
            multi: true,
        },
    ],
})
export class CoreTextareaComponent implements ControlValueAccessor {
    constructor() { }

    @Input()
    public id!: string;

    @Input()
    public name!: string;

    @Input()
    public control!: AbstractControl;

    @Input()
    public direction: 'rtl' | 'ltr' = 'rtl';

    @Input()
    public icon!: string;

    @Input()
    public loading: boolean = true;

    @Input()
    public rows: number = 3;

    @Input()
    public bg: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public title: string = '';

    @Input()
    public required = false;

    @Input()
    public horizontal: { label: number, input: number } = {
            label: 0,
            input: 0,
        };

    @Input()
    public label = false;

    @Input()
    public autocomplete = 'off';

    @Input()
    public validation?: { status?: boolean, messages?: string[] } | null;

    @Output()
    public onType: EventEmitter<any> = new EventEmitter<any>();

    public input = '';

    public inputValue = null;

    public onChange: any = (_: any) => { };
    public onTouch: any = () => { };

    set value(input: any) {

        if (input !== undefined && this.input !== input) {
            this.input = input;
            this.onChange(input);
            this.onTouch(input);
        }
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    onInput(): void {
        const value = this.inputValue as any;

        this.validation = null;

        this.value = value;

        this.onChange(value);
        this.onTouch(value);

        this.onType.emit(value);
    }

    get isInvalid(): boolean {
        if (!this.validation) {
            return false;
        } else {
            return !this.validation.status;
        }
    }

    public get labelCol(): string {
        if (this.horizontal && this.horizontal.label) {
            return `col-${this.horizontal.label}`;
        }
        return '';
    }

    public get inputCol(): string {
        if (this.horizontal && this.horizontal.input) {
            return `col-${this.horizontal.input}`;
        }
        return '';
    }
}
