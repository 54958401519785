import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';


export class EventType extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public status!: number;

    constructor(payload?: Partial<EventType>) {
        super();

        return plainToClass(EventType, payload, { excludeExtraneousValues: true });
    }
}
