
import { Expose, Type, plainToClass } from 'class-transformer';
import { CoreRelationship } from './CoreRelationship.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreRelationshipType extends BaseEntityClass {
    @Expose()
    public desc!: string;

    @Expose()
    public status?: boolean;

    @Expose()
    @Type(() => CoreRelationship)
    public relationships!: CoreRelationship[];

    constructor(payload?: Partial<CoreRelationshipType>) {
        super();

        return plainToClass(CoreRelationshipType, payload, { excludeExtraneousValues: true });
    }
}
