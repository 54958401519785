import { IEngagementStatusRow } from '@common/interfaces';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { EvalComponent } from './EvalComponent.entity';
import { EventType } from './EventType.entity';
import { Student } from './Student.entity';
import { StudentEvent } from './StudentEvent.entity';


export class EvalEvent extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public subjectName!: string;

    @Expose()
    public groupId!: number;

    @Expose()
    public eventTypeId!: number;

    @Expose()
    public subjectId!: number;

    /**
     * code_tkufat_peilut
     */
    @Expose()
    public periodId!: number;

    @Expose()
    public score!: number;

    @Expose()
    public display!: number;

    @Expose()
    public componentId!: number;

    @Expose()
    public capability!: string[];

    @Expose()
    public status!: number;

    @Expose()
    public date!: Date | string;

    @Expose()
    @Transform(({ obj }) => {
        return {
            day: moment(obj.date, 'YYYY-MM-DD').date(),
            month: moment(obj.date, 'YYYY-MM-DD').month() + 1,
            year: moment(obj.date, 'YYYY-MM-DD').year(),
        };
    })
    public objDate!: {
        day: number;
        month: number;
        year: number;
    };

    @Expose()
    @Type(() => EventType)
    public eventType!: EventType;

    @Expose()
    @Type(() => EvalComponent)
    public component!: EvalComponent;

    @Expose()
    @Type(() => Student)
    public students!: Student[];

    @Expose()
    @Type(() => StudentEvent)
    public studentEvents!: StudentEvent[];

    @Expose()
    public permissions!: {
        canEdit: boolean;
        canDelete: boolean;
        canEditComponent: boolean;
    };

    @Expose()
    @Type(() => Number)
    public numberOfStudentEvents!: number;

    @Expose()
    @Type(() => Number)
    public numberOfEventChildren!: number;

    @Expose()
    @Type(() => Number)
    public percentOfParent!: number;

    @Expose()
    public locked!: boolean;

    @Expose()
    public engagementStatusRow?: IEngagementStatusRow;

    public percentOfStudentEvents!: number;
    public avg!: number;
    public avg100!: number;

    constructor(payload?: Partial<EvalEvent>) {
        super();

        return plainToClass(EvalEvent, payload, { excludeExtraneousValues: true });
    }
}
