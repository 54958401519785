import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StartTimeTable extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public status!: number;

    @Expose()
    public shared!: number;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    public note!: string;

    constructor(payload?: Partial<StartTimeTable>) {
        super();

        return plainToClass(StartTimeTable, payload, { excludeExtraneousValues: true });
    }
}
