
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { MemberType } from '@common/enums';
import { CoreTeacher } from './CoreTeacher.entity';
import { CoreStudent } from './CoreStudent.entity';

export class CoreGroupMember extends BaseEntityClass {
    @Expose()
    public groupId!: number;

    @Expose()
    public memberId!: number;

    @Expose()
    public memberType!: MemberType;

    @Expose()
    public idType!: number;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    public reasonLeaving!: number;

    @Expose()
    public createdUserId!: number;

    @Expose()
    public updatedUserId!: number;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    @Type(() => CoreStudent)
    public student!: CoreStudent;

    constructor(payload?: Partial<CoreGroupMember>) {
        super();

        return plainToClass(CoreGroupMember, payload, { excludeExtraneousValues: true });
    }
}
