import { ISubject } from '@common/interfaces';
import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Teacher } from './Teacher.entity';

export class LessonTopic extends BaseEntityClass {
    @Expose()
    public groupId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    @Type(() => Date)
    public deadline!: Date;

    @Expose()
    public teacherId!: number;

    @Expose()
    public topic!: string;

    @Expose()
    public topicDetail!: string;

    @Expose()
    public task!: string;

    @Expose()
    public reasonId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    @Type(() => Teacher)
    public teacher!: Teacher;

    @Expose()
    public subject!: ISubject;

    @Expose()
    public lessonHours!: number;

    constructor(payload?: Partial<LessonTopic>) {
        super();

        return plainToClass(LessonTopic, payload, { excludeExtraneousValues: true });
    }
}
