
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreGender } from './CoreGender.entity';

export class CoreOrg extends BaseEntityClass {
    @Expose()
    public orgName!: string;

    @Expose()
    public cityHall!: number;

    @Expose()
    public cityHallDesc!: string;

    @Expose()
    public orgType!: number;

    @Expose()
    public orgTypeDesc!: string;

    @Expose()
    public reportableFactor!: number;

    @Expose()
    public reportableFactorDesc!: string;

    @Expose()
    public sector!: number;

    @Expose()
    public sectorDesc!: string;

    @Expose()
    public legalStatus!: number;

    @Expose()
    public legalStatusDesc!: string;

    @Expose()
    public supervisionType!: number;

    @Expose()
    public supervisionTypeDesc!: string;

    @Expose()
    public educationType!: number;

    @Expose()
    public educationTypeDesc!: string;

    @Expose()
    public educationPhaseId!: number;

    @Expose()
    public educationPhaseDesc!: string;

    @Expose()
    public villageId!: number;

    @Expose()
    public villageName!: string;

    @Expose()
    public address!: string;

    @Expose()
    public phone!: string;

    @Expose()
    public smsCustomerName!: string;

    @Expose()
    public managerFirstName1!: string;

    @Expose()
    public managerLastName1!: string;

    @Expose()
    public managerFirstName2!: string;

    @Expose()
    public managerLastName2!: string;

    @Expose()
    public managerId!: number;

    @Expose()
    public managerPhone!: string;

    @Expose()
    public managerEmail!: string;

    @Expose()
    public facebookUrl!: string;

    @Expose()
    public fax!: string;

    @Expose()
    public mailBox!: string;

    @Expose()
    public managerGenderId!: number;

    @Expose()
    @Type(() => CoreGender)
    public gender!: CoreGender;

    constructor(payload?: Partial<CoreOrg>) {
        super();

        return plainToClass(CoreOrg, payload, { excludeExtraneousValues: true });
    }
}
