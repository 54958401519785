import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateVersionComponent } from './update-version/update-version.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProviderTranslateChildModule } from '@common/modules/provider-translate-child.module';
import { CoreDynamicTableComponent } from './core-dynamic-table/core-dynamic-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrViewModule } from './err-view/err-view.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { CoreButtonComponent } from './core-button/core-button.component';
import { ConfirmDlgComponent } from './confirm-dlg/confirm-dlg.component';
import { CommonPipesModule, ExtraFilterPipe } from '@common/pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreSelectComponent } from './core-select/core-select.component';
import { CoreInputComponent } from './core-input/core-input.component';
import { CoreCheckboxComponent } from './core-checkbox/core-checkbox.component';
import { CoreLoadingComponent } from './core-loading/core-loading.component';
import { CoreProgressComponent } from './core-progress/core-progress.component';
import { CoreTextareaComponent } from './core-textarea/core-textarea.component';


@NgModule({
    declarations: [
        UpdateVersionComponent,
        CoreDynamicTableComponent,
        CoreSelectComponent,
        CoreButtonComponent,
        ConfirmDlgComponent,
        CoreSelectComponent,
        CoreInputComponent,
        CoreCheckboxComponent,
        CoreLoadingComponent,
        CoreProgressComponent,
        CoreTextareaComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        ErrViewModule,
        MatMenuModule,
        DragDropModule,
        MatCheckboxModule,
        ColorPickerModule,
        MatProgressSpinnerModule,
        ProviderTranslateChildModule,
        CommonPipesModule,
        NgSelectModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
    exports: [
        UpdateVersionComponent,
        CoreDynamicTableComponent,
        CoreSelectComponent,
        CoreButtonComponent,
        ConfirmDlgComponent,
        CoreSelectComponent,
        CoreInputComponent,
        CoreCheckboxComponent,
        CoreLoadingComponent,
        CoreProgressComponent,
        CoreTextareaComponent,
    ],
    entryComponents: [ConfirmDlgComponent],
})
export class CommonComponentsModule { }
