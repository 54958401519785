import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class MappingAnswer extends BaseEntityClass {
    @Expose()
    public mappingId!: number;

    @Expose()
    public questionId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public mappingLinkId!: number;

    @Expose()
    public gradeId!: number;

    @Expose()
    public gardeSectionId!: number;

    @Expose()
    public score!: number;

    constructor(payload?: Partial<MappingAnswer>) {
        super();

        return plainToClass(MappingAnswer, payload, { excludeExtraneousValues: true });
    }
}
