import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { EvalEvent } from './EvalEvent.entity';


export class EventRelation extends BaseEntityClass {
    @Expose()
    public parentId!: number;

    @Expose()
    @Type(() => Event)
    public parent!: EvalEvent;

    @Expose()
    public childId!: number;

    @Expose()
    @Type(() => Event)
    public child!: EvalEvent;

    @Expose()
    public percentOfParent!: number;

    constructor(payload?: Partial<EventRelation>) {
        super();

        return plainToClass(EventRelation, payload, { excludeExtraneousValues: true });
    }
}
