import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreMinistrySubject extends BaseEntityClass {
    @Expose()
    public subjectId!: number;

    @Expose()
    public description!: string;

    @Expose()
    public levelId!: string;

    @Expose()
    public PIKUACH_MAMLACHTI!: string;

    @Expose()
    public PIKUACH_MAMLACHTI_DATI!: string;

    @Expose()
    public PIKUACH_ACHER!: string;

    @Expose()
    public bagrutId!: number;


    constructor(payload?: Partial<CoreMinistrySubject>) {
        super();

        return plainToClass(CoreMinistrySubject, payload, { excludeExtraneousValues: true });
    }
}
