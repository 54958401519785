/* eslint-disable max-classes-per-file */
import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class EvalRangeMeta {
    @Expose()
    public title?: string;

    @Expose()
    public color?: string;

    @Expose()
    public status?: string;

    @Expose()
    @Type(() => Number)
    public start?: number;

    @Expose()
    @Type(() => Number)
    public end?: number;
}


export class EvalRange extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    @Type(() => EvalRangeMeta)
    public meta!: EvalRangeMeta[];

    constructor(payload?: Partial<EvalRange>) {
        super();

        return plainToClass(EvalRange, payload, { excludeExtraneousValues: true });
    }
}
