<div class="core-button">
  <button
    *ngIf="active"
    [title]="title || alt || ''"
    [style.font-size]="activeStyle.fontSize"
    [style.width]="width"
    [style.height.px]="height"
    [class]="activeStyle.class"
    [style.color]="activeStyle.color"
    [style.background-color]="activeStyle.bgColor"
    [style.borderRadius]="activeStyle.borderRadius || '150px'"
    [style.padding]="activeStyle.padding"
    (click)="_onClick()"
    [disabled]="disabled || loading"
  >
    <i *ngIf="activeStyle.wesome" [class]="activeStyle.wesome"></i>

    <img
      *ngIf="activeStyle.img"
      [src]="activeStyle.img"
      [style.width]="activeStyle.imgWidth ? activeStyle.imgWidth : '100%'"
      [style.margin]="activeStyle.imgMargin"
    />
    <ng-container *ngIf="loading">
      <div class="spinner-border" style="width: 20px; height: 20px">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && title">{{ title }}</ng-container>
  </button>

  <button
    *ngIf="!active"
    [title]="title || alt || ''"
    [style.font-size]="notActiveStyle.fontSize"
    [style.width]="width"
    [style.height.px]="height"
    [class]="notActiveStyle.class"
    [style.color]="notActiveStyle.color"
    [style.background-color]="notActiveStyle.bgColor"
    [style.borderRadius]="notActiveStyle.borderRadius || '150px'"
    [style.padding]="notActiveStyle.padding"
    [disabled]="disabled || loading"
    (click)="_onClick()"
  >
    <i *ngIf="notActiveStyle.wesome" [class]="notActiveStyle.wesome"></i>

    <img
      *ngIf="notActiveStyle.img"
      [src]="notActiveStyle.img"
      [style.width]="activeStyle.imgWidth ? activeStyle.imgWidth : '100%'"
    />

    <ng-container *ngIf="loading">
      <div class="spinner-border" style="width: 20px; height: 20px">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && title">{{ title }}</ng-container>
  </button>
</div>
