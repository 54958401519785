import { Injectable } from '@angular/core';
import { UtilFileDictionary, UtilNotificationType, UtilOrgNotificationSetting } from '@common/entities';
import { ExcelOptions, IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { ExcelBuild } from '@common/entities/util/ExcelBuild.entity';

export interface IUtilApiService {
    getOrgFilesList(filter?: IFindPaginateOptions): Promise<UtilFileDictionary[]>;
}

@Injectable({
    providedIn: 'root',
})
export class UtilApiService implements IUtilApiService {
    constructor(
        protected extraApiService: ExtraApiService,
        protected helperService: HelperService,
    ) {
    }

    public async getOrgFilesList(filter?: IFindPaginateOptions): Promise<UtilFileDictionary[]> {
        return this.extraApiService.request(UtilFileDictionary, 'post', `/util/files/get-org-files-list`, filter);
    }

    public async uploadFile(body: FormData): Promise<void> {
        return this.extraApiService.uploadFile(`/util/files/upload`, body);
    }

    public async downloadFileById(id: number): Promise<any> {
        return this.extraApiService.postFile(`/util/files/download-file-by-id/${id}`);
    }

    public async deleteFileById(id: number): Promise<any> {
        return this.extraApiService.postFile(`/util/files/delete-file-by-id/${id}`);
    }

    public async convertHtmlToPdf(body: { content: string, opts?: any }): Promise<any> {
        return this.extraApiService.postFile('/util/generator/html-to-pdf', body);
    }

    public async exportDataAsXlsx(data: any[], config: ExcelOptions): Promise<any> {
        return this.extraApiService.postFile(`/util/generator/export-data-as-xlsx`, { config, data });
    }

    public async generateExcelMultiTable(body: ExcelBuild[]): Promise<any> {
        return this.extraApiService.postFile(`/util/generator/export-data-as-xlsx-multi-sheets-difference-data`, body);
    }

    public async exportDataAsXlsxMultiSheetsDifferenceData(body: ExcelBuild[]): Promise<any> {
        return this.extraApiService.postFile(`/util/generator/export-data-as-xlsx-multi-sheets-difference-data`, body);
    }

    async generateExcel(body: any): Promise<any> {
        return await this.extraApiService.postFile('/util/generator/export-data-as-xlsx', body);
    }

    public async getUtilOrgNotificationSettingsByFilter(filter?: IFindPaginateOptions): Promise<UtilOrgNotificationSetting[]> {
        return this.extraApiService.request(UtilOrgNotificationSetting, 'post', `/util/notifications/org-notification-settings/by-filter`, filter);
    }

    public async deleteUtilOrgNotificationSettingsByFilter(filter?: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post(`/util/notifications/org-notification-settings/delete`, filter);
    }

    public async upsertUtilOrgNotificationSettingsByFilter(models: UtilOrgNotificationSetting[]): Promise<UtilOrgNotificationSetting[]> {
        return this.extraApiService.post(`/util/notifications/org-notification-settings/upsert`, models);
    }

    public async getUtilNotificationTypesByFilter(filter?: IFindPaginateOptions): Promise<UtilNotificationType[]> {
        return this.extraApiService.request(UtilNotificationType, 'post', `/util/notifications/notification-types/by-filter`, filter);
    }
}
