import { Expose, plainToClass, Type } from 'class-transformer';


export class Teacher {
    @Expose()
    public NAME!: string;

    @Expose()
    public CODE_MIN!: number;

    @Expose()
    public MISPAR_ZEHUT!: number;

    @Expose()
    public SHEM_PRATI!: string;

    @Expose()
    public SHEM_MISHPACHA!: string;

    @Expose()
    public SUG_ZEHUT: any;

    @Expose()
    public MISPAR_NAYAD: any;

    @Expose()
    public SHEM_PRATI_KINUI: any;

    @Expose()
    public SHEM_MISHPACHA_KINUI: any;

    @Expose()
    public roles!: number[];

    @Expose()
    public capabilities!: string[];

    @Expose()
    public myGroups!: {
        [classId: string]: number[];
    };

    public get isSchoolManager(): boolean {
        return this.roles && this.roles.length > 0 && this.roles.includes(1);
    }

    constructor(payload?: Partial<Teacher>) {
        return plainToClass(Teacher, payload, { excludeExtraneousValues: true });
    }
}
