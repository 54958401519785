import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class ServeLessonPlans extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public planSessionId!: number;

    @Expose()
    @Type(() => String)
    public lesson!: string;

    @Expose()
    @Type(() => Number)
    public numberLesson!: number;

    constructor(payload?: Partial<ServeLessonPlans>) {
        super();

        return plainToClass(ServeLessonPlans, payload, { excludeExtraneousValues: true });
    }
}
