import { Expose, plainToClass, Type } from 'class-transformer';


export class Org {
    @Expose()
    public SHNAT_LIMUDIM!: number;

    @Expose()
    public SEMEL_MOSAD!: number;

    @Expose()
    public SHEM_MOSAD!: string;

    @Expose()
    public RASHUT_CHINUCH!: number;

    @Expose()
    public RASHUT_CHINUCH_TEUR!: string;

    @Expose()
    public SUG_MOSAD!: number;

    @Expose()
    public SUG_MOSAD_TEUR!: string;

    @Expose()
    public GOREM_MEDAVEACH!: number;

    @Expose()
    public GOREM_MEDAVEACH_TEUR!: string;

    @Expose()
    public MIGZAR!: number;

    @Expose()
    public MIGZAR_TEUR!: string;

    @Expose()
    public MAAMAD_MISHPATI!: number;

    @Expose()
    public MAAMAD_MISHPATI_TEUR!: string;

    @Expose()
    public SUG_PIKUACH!: number;

    @Expose()
    public SUG_PIKUACH_TEUR!: string;

    @Expose()
    public SUG_CHINUCH!: number;

    @Expose()
    public SUG_CHINUCH_TEUR!: string;

    @Expose()
    public SHLAV_HINUCH!: number;

    @Expose()
    public SHLAV_HINUCH_TEUR!: string;

    @Expose()
    public SEMEL_YISHUV!: number;

    @Expose()
    public SHEM_YISHUV!: string;

    @Expose()
    public KTOVET_MOSAD!: string;

    @Expose()
    public TELEFON_MOSAD!: string;

    @Expose()
    public SMS_CUSTOMERT_NAME: any;

    @Expose()
    public SHEM_PRATI_MENAHEL!: string;

    @Expose()
    public SHEM_MISHPACHA_MENAHEL!: string;

    @Expose()
    public SHEM_MENAHEL_2: any;

    @Expose()
    public SHEM_MENAHEL_2_TITLE: any;

    @Expose()
    public MIS_ZEHUT_MENAHEL: any;

    @Expose()
    public MISPAR_TELEPHONE_MENAHEL!: string;

    @Expose()
    public DOAR_ELEKTRONY_MENAHEL!: string;

    @Expose()
    public MIGDAR_MENAHEL!: string;

    @Expose()
    public FACEBOOK: any;

    @Expose()
    public FAX: any;

    @Expose()
    public MAIL_BOX: any;

    @Expose()
    public CODE_MIN!: number;

    constructor(payload?: Partial<Org>) {
        return plainToClass(Org, payload, { excludeExtraneousValues: true });
    }
}
