import { Expose, plainToClass, Transform } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class EcountSetting extends BaseEntityClass {
    @Expose()
    @Transform(({ value }) => {
        return value && value.map((item: any) => Number(item));
    })
    public sendSmsToRelationship!: number[];

    @Expose()
    public allowAbsentSms!: boolean;

    @Expose()
    @Transform(({ value }) => {
        return value && value.map((item: any) => Number(item));
    })
    public absentSmsLessons!: number[];

    @Expose()
    public absentSmsContent!: string;

    @Expose()
    public absentNotifyContent!: string;

    @Expose()
    public behaviorNotifyContent!: string;

    @Expose()
    public lessonNotifyContent!: string;

    constructor(payload?: Partial<EcountSetting>) {
        super();

        return plainToClass(EcountSetting, payload, { excludeExtraneousValues: true });
    }
}
