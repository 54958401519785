import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomTranslateService {
    locale: any = 'ar';

    constructor(public translate: TranslateService) {
        translate.addLangs(['ar', 'en', 'he']);
        translate.setDefaultLang('ar');

        let lang = JSON.parse(localStorage.getItem('kindix_lang') || '{}');

        if (lang === null || lang.locale === undefined) {
            localStorage.setItem('kindix_lang', JSON.stringify({ locale: 'ar' }));

            lang = JSON.parse(localStorage.getItem('kindix_lang') || '{ }');
        }

        translate.use(lang.locale);
        this.locale = lang.locale;
    }

    changeLang(locale: any): void {
        localStorage.removeItem('kindix_lang');

        localStorage.setItem('kindix_lang', JSON.stringify({ locale }));

        this.translate.use(locale);
    }
}
