import { Expose, plainToClass, Type } from 'class-transformer';
import { SheelonStudent } from './SheelonStudent.entity';

export class CustomSheelonStudent extends SheelonStudent {
    @Expose()
    public NAME!: string;

    @Expose()
    public FAMILY!: string;

    @Expose()
    public SHICHVA!: number;

    @Expose()
    public MAKBILA!: number;

    @Expose()
    public CLASS!: string;

    @Expose()
    public semel_sheelon_rashi!: number;

    @Expose()
    public tziun_shnaty_latalmid!: number;

    @Expose()
    public mispar_zehut_measher!: number;

    @Expose()
    public sug_zehut_measher!: number;

    @Expose()
    public mispar_zehut_sholeach!: number;

    @Expose()
    public sug_zehut_sholeach!: number;

    @Expose()
    public code_mishtamesh_yotser!: string;

    @Expose()
    public haim_nigash_lebhina!: number;

    public selected: boolean = false;
    public valid: boolean = false;
    public changeScore: boolean = false;

    constructor(payload?: Partial<CustomSheelonStudent>) {
        super(payload);

        return plainToClass(CustomSheelonStudent, payload, { excludeExtraneousValues: true });
    }
}
