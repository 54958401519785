
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class QuestionsAnswer extends BaseEntityClass {
    @Expose()
    public examId!: number;

    @Expose()
    public questionId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public answer!: string;

    @Expose()
    public answer_score!: number;

    constructor(payload?: Partial<QuestionsAnswer>) {
        super();

        return plainToClass(QuestionsAnswer, payload, { excludeExtraneousValues: true });
    }
}

