/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../helpers/storage.service';

@Injectable({
    providedIn: 'root',
})
export class ExtraApiService {
    private API_URL = '';
    private accountApi = '';
    private headers!: HttpHeaders;
    private authorization!: string;

    constructor(
        private http: HttpClient,
        public location: Location,
        private cookieService: CookieService,
        private router: Router,
        private storageService: StorageService,

    ) {
        this.API_URL = environment.appUrl;
        this.accountApi = environment.accountsApi;
    }

    private get kindixToken(): string {
        const userData = JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token')) || '{}');

        return userData.accessToken;
    }

    private init(): void {
        this.authorization = this.kindixToken;
        const lang = this.storageService.getLang();

        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain',
            authorization: `${this.authorization}`,
            lang,
            'accept-language': lang,
        });
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // eslint-disable-next-line no-console
            console.error('An error occurred:', error.error.message);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Backend returned code ${error.status}, body was: ${error.error.message}`);
        }
        return throwError('Something bad happened; please try again later.');
    }

    public async get(path: string, parameters: any = { }): Promise<any> {
        this.init();

        const url = this.API_URL;

        return new Promise((resolve, reject) => {
            this.http.get(`${url + path}`, { params: parameters, headers: this.headers }).subscribe({
                next: resolve,
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    public async post(path: string, body: any = { }, _url?: string): Promise<any> {
        this.init();

        const url = _url || this.API_URL;

        return new Promise((resolve, reject) => {
            this.http.post(`${url + path}`, body, { headers: this.headers }).subscribe({
                next: resolve,
                error: (err) => {
                    this.handleException(err);
                    reject(err);
                },
            });
        });
    }

    public async getAccountApi(path: string, parameters: any = { }): Promise<any> {
        this.init();

        const url = this.accountApi;

        return new Promise((resolve, reject) => {
            this.http.get(`${url + path}`, { params: parameters, headers: this.headers }).subscribe({
                next: resolve,
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    public async postFile(path: string, body: any = { }): Promise<any> {
        this.init();

        const url = this.API_URL;
        const lang = this.storageService.getLang();

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/*',
            authorization: `${this.authorization}`,
            lang,
            'accept-language': lang,
        });

        return new Promise((resolve, reject) => {
            this.http.post(`${url + path}`, body, { responseType: 'blob', headers }).subscribe({
                next: resolve,
                error: (err) => {
                    this.handleException(err);
                    reject(err);
                },
            });
        });
    }

    public async customRequest(
        method: 'get' | 'post' | 'delete' | 'put',
        path: string,
        body: any = { },
        headersData: { [headers: string]: any },
    ): Promise<any> {
        this.init();

        const url = this.API_URL;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain',
            ...headersData,
        });

        return new Promise((resolve, reject) => {
            this.http.request(method, `${url + path}`, { body, headers }).subscribe({
                next: resolve,
                error: (err) => {
                    this.handleException(err);
                    reject(err);
                },
            });
        });
    }

    public async put(path: string, body: any = { }): Promise<any> {
        this.init();

        const url = this.API_URL;

        return new Promise((resolve, reject) => {
            this.http.put(`${url + path}`, body, { headers: this.headers }).subscribe({
                next: resolve,
                error: (err) => {
                    this.handleException(err);
                    reject(err);
                },
            });
        });
    }

    public async delete(path: string, parameters: any = { }): Promise<any> {
        this.init();

        const url = this.API_URL;

        return this.http.delete(`${url + path}`, { params: parameters, headers: this.headers })
            .pipe(
                catchError(this.handleError),
            ).toPromise();
    }

    private handleException(err: HttpErrorResponse): void {
        if (err.status === 401 || /Unauthorized/.test(err.error.error)) {

        }
    }

    public async request<T extends any>(cls: ClassConstructor<any>, method: 'get' | 'post' | 'delete' | 'put', path: string, body: any = { }): Promise<T> {
        return (this as any)[method](path, body).then((res: T | T[]) => {
            return plainToClass(cls, res, { excludeExtraneousValues: true });
        });
    }

    public async extraRequest(method: 'get' | 'post' | 'delete' | 'put', path: string, options: any = { }, newUrl?: string): Promise<any> {
        this.init();

        const url = newUrl || this.API_URL;

        return this.http.request(method, `${url + path}`, { ...options, headers: this.headers }).pipe(
            catchError(this.handleError),
        ).toPromise();
    }

    public async uploadFile(path: string, bodyOld: any = { }): Promise<any> {
        this.init();

        const url = this.API_URL;

        const headers: HttpHeaders = new HttpHeaders({
            Accept: 'application/json',
            Authorization: `Bearer ${this.authorization}`,
        });

        return new Promise((resolve, reject) => {
            this.http.post(`${url + path}`, bodyOld, { headers }).subscribe({
                next: resolve,
                error: (err) => {
                    this.handleException(err);
                    reject(err);
                },
            });
        });
    }
}
