import { Expose, plainToClass, Transform } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StudentScoreSheelon extends BaseEntityClass {
    @Expose()
    public semel_mosad!: number;

    @Expose()
    public shnat_limudim!: number;

    @Expose()
    public code_moed_bchina!: number;

    @Expose()
    public semel_sheelon!: number;

    @Expose()
    public mispar_zehut_nivchan!: number;

    @Expose()
    public semel_sheelon_rashi!: number;

    @Expose()
    public tziun_shnaty_latalmid!: number;

    @Expose()
    public sug_zehut_nivchan!: number;

    @Expose()
    public taarich_yetsirat_reshuma!: Date;

    @Expose()
    @Transform(({ obj }) => moment(obj && obj.taarich_yetsirat_reshuma).format('YYYY-MM-DD hh:mm:ss'))
    public taarich_yetsirat_reshuma_str!: string;

    @Expose()
    public code_mishtamesh_yotser!: string;

    @Expose()
    public haim_nigash_lebhina!: number;

    @Expose()
    public mispar_zehut_measher!: number;

    @Expose()
    public sug_zehut_measher!: number;

    @Expose()
    public mispar_zehut_sholeach!: number;

    @Expose()
    public sug_zehut_sholeach!: number;

    @Expose()
    public haim_nivchan_mishnee!: number;

    @Expose()
    public shem_prati!: string;

    @Expose()
    public shem_mishpacha!: string;

    @Expose()
    public taarich_leida_nivchan_mishnee!: Date;

    @Expose()
    @Transform(({ obj }) => moment(obj && obj.taarich_leida_nivchan_mishnee).format('YYYY-MM-DD hh:mm:ss'))
    public taarich_leida_nivchan_mishnee_str!: string;

    @Expose()
    public code_min_nivchan_mishnee!: string;

    constructor(payload?: Partial<StudentScoreSheelon>) {
        super();

        return plainToClass(StudentScoreSheelon, payload, { excludeExtraneousValues: true });
    }
}

