import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogConfig } from '@common/interfaces';

@Component({
    selector: 'confirm-dlg',
    templateUrl: './confirm-dlg.component.html',
    styleUrls: ['./confirm-dlg.component.scss'],
})
export class ConfirmDlgComponent {
    public title!: string | undefined;
    public message!: string | undefined;
    public confirmButtonText!: string | undefined;
    public cancelButtonText!: string | undefined;
    public imgSrc!: string | undefined;
    public showActions!: boolean | undefined;
    public action!: string | undefined;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDlgComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogConfig,
    ) {
        if (data) {
            this.imgSrc = data.imgSrc;
            this.imgSrc = data.imgSrc;
            this.showActions = data.showActions;
            this.action = data.action;
            this.title = data.title;
            this.message = data.message;
            this.confirmButtonText = data.confirmButtonText;
            this.cancelButtonText = data.cancelButtonText;
        }
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
