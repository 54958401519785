import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class DisplayType extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public order!: number;

    constructor(payload?: Partial<DisplayType>) {
        super();

        return plainToClass(DisplayType, payload, { excludeExtraneousValues: true });
    }
}
