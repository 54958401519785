import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class GroupSheelon extends BaseEntityClass {
    @Expose()
    public semel_mosad!: number;

    @Expose()
    public shnat_limudim!: number;

    @Expose()
    public code_moed_bchina!: number;

    @Expose()
    public semel_sheelon!: number;

    @Expose()
    public mispar_kvutsa!: number;

    @Expose()
    public mispar_kvutsa_manbas!: number;

    @Expose()
    public taarich_idkun_acharon!: Date;

    @Expose()
    public taarich_siyum!: Date;

    constructor(payload?: Partial<GroupSheelon>) {
        super();

        return plainToClass(GroupSheelon, payload, { excludeExtraneousValues: true });
    }
}
