import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class MappingQuestion extends BaseEntityClass {
    @Expose()
    public mappingId!: number;

    @Expose()
    public questionId!: number;

    @Expose()
    public score!: number;

    @Expose()
    public order!: number;

    constructor(payload?: Partial<MappingQuestion>) {
        super();

        return plainToClass(MappingQuestion, payload, { excludeExtraneousValues: true });
    }
}
