
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { FormForm } from './Form.entity';
import * as moment from 'moment';

export class FormOrg extends BaseEntityClass {

    @Expose()
    public formId!: number;

    @Expose()
    public orgName!: string;

    @Expose()
    public settings!: SettingsFormOrgs;

    @Expose()
    public form!: FormForm;

    @Expose()
    public data!: object;

    @Expose()
    @Transform(({ obj }) => {
        return moment(obj.updated_at).format('YYYY-MM-DD hh:mm A');
    })
    public updated_date!: Date;


    constructor(payload?: Partial<FormOrg>) {
        super();

        return plainToClass(FormOrg, payload, { excludeExtraneousValues: true });
    }
}

export interface SettingsFormOrgs {
    price: number;
    lang: string;
    currency: string;
    email: string;
    customerName: string;
    invoices: boolean;
    CARDCOM: {
        check: boolean;
        Operation: number;
        TerminalNumber: number;
        UserName: string;
        SumToBill: number;
        CoinId: string;
        Language: string;
        ProductName: string;
        APILevel: number;
        Codepage: number;
        ReturnValue: string;
        ShowInvoiceHead: boolean;
        AutoRedirect: boolean;
        SuccessRedirectUrl: string;
        ErrorRedirectUrl: string;
        IndicatorUrl: string;
        InvoiceHead: number;
        'InvoiceHead.CustName': string;
        'InvoiceHead.SendByEmail': boolean;
        'InvoiceHead.CoinID': string;
        'InvoiceHead.Language': string;
        'InvoiceHead.Email': string;
        'InvoiceLines1.Description': string;
        'InvoiceLines1.Price': number;
        'InvoiceLines1.Quantity': number;
    };
    transferBank: {
        check: boolean;
        description: '';
    };
    BitBay: {
        check: boolean;
    };
    coupons: {
        code: string;
        discount: string;
    };
    core: {
        check: boolean;
        groups: FieldConnectKindix[];
        classes: FieldConnectKindix[];
        students: FieldConnectKindix[];
    };
}

export interface FieldConnectKindix {
    formField: string;
    coreField: string;
}
