import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class EcountStatement extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public type!: number;

    @Expose()
    public statementScopeId!: number;

    constructor(payload?: Partial<EcountStatement>) {
        super();

        return plainToClass(EcountStatement, payload, { excludeExtraneousValues: true });
    }
}
