
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { BaseColumnWithClassIdSchemaPart } from './BaseColumnWithClassIdSchemaPart';
import { CoreRoleUser } from './CoreRoleUser.entity';
import { CoreTeacher } from './CoreTeacher.entity';
import { CoreGroup } from './CoreGroup.entity';

export class CoreClass extends BaseColumnWithClassIdSchemaPart {
    @Expose()
    public name1!: string;

    @Expose()
    public name2!: string;

    @Expose()
    public classType!: number;

    @Expose()
    public classTypeDesc!: string;

    @Expose()
    public minStudents!: number;

    @Expose()
    public maxStudents!: number;

    @Expose()
    public status!: number;

    @Expose()
    public statusDesc!: string;

    @Expose()
    public location: any;

    @Expose()
    public address!: string;

    @Expose()
    @Type(() => Number)
    public studentsCount!: number;

    @Expose()
    @Type(() => CoreRoleUser)
    public roleUsers!: CoreRoleUser[];

    @Expose()
    @Type(() => CoreTeacher)
    public classEducators!: CoreTeacher[];

    @Expose()
    @Transform(({ obj }) => `${obj.grade}-${obj.gradeSection}`)
    public classId!: string;

    @Expose()
    @Type(() => CoreGroup)
    public groups!: CoreGroup[];

    @Expose()
    @Type(() => Number)
    public groupsIds!: number[];

    @Expose()
    public subjectsIds!: number[];

    constructor(payload?: Partial<CoreClass>) {
        super();

        return plainToClass(CoreClass, payload, { excludeExtraneousValues: true });
    }

    public get managersNames(): string {
        return this.roleUsers && this.roleUsers.map(item => item.teacher).map(_item => `${_item.firstName1} ${_item.lastName1}`).join(',');
    }
}
