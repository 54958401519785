import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreStudent } from '../core/CoreStudent.entity';
import { ServeMusicTool } from './ServeMusicTool';
import * as moment from 'moment';

export class ServeMusicToolReservation extends BaseEntityClass {
    @Expose()
    @Type(() => CoreStudent)
    public student?: CoreStudent;

    @Expose()
    public studentId!: number;

    @Expose()
    public status!: number;

    @Expose()
    public musicToolId!: number;

    @Expose()
    @Type(() => Date)
    @Transform(({ obj }) => {
        return obj.startDate ? moment(obj.startDate).format('YYYY-MM-DD') : '';
    })
    public startDate!: string;

    @Expose()
    @Type(() => Date)
    @Transform(({ obj }) => {
        return obj.endDate ? moment(obj.endDate).format('YYYY-MM-DD') : '';
    })
    public endDate!: string;

    @Expose()
    public musicTool!: ServeMusicTool;


    constructor(payload?: Partial<ServeMusicToolReservation>) {
        super();

        return plainToClass(ServeMusicToolReservation, payload, { excludeExtraneousValues: true });
    }
}
