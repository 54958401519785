<div class="custom-input" [class.input-rtl]="direction">
    <div
        [class.row]="horizontal.label && horizontal.input"
        [class.form-group]="label && !horizontal.label && !horizontal.input"
    >
        <label [for]="id" *ngIf="label" class="form-label {{ labelCol }}">
            <div>
                <span style="display: inline-block;">
                    {{ title }}
                </span>
                <span style="display: inline-block;" *ngIf="required" style="color: red;"> * </span>
            </div>
        </label>
        <div class="input-with-icon {{ inputCol }}">
            <i *ngIf="!!icon" class="i-icon" [class]="icon"></i>
            <input
                [id]="id"
                [name]="name"
                [value]="input"
                [required]="required"
                [placeholder]="title"
                (input)="onInput($event)"
                [(ngModel)]="inputValue"
                [type]="type"
                [class.is-invalid]="isInvalid"
                class="form-control fc-datepicker"
                [autocomplete]="autocomplete"
                [step]="step"
                [class.input-bg]="bg"
                [disabled]="disabled"
                [max]="max"
                [min]="min"
                [dir]="direction"
                [multiple]="multiple"
            />

            <div class="invalid-feedback" *ngIf="!validation?.status">
                <span *ngFor="let item of validation?.messages">
                    {{ item }}
                </span>
            </div>
        </div>
    </div>
</div>
