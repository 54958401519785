/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDlgComponent } from '@common/components/confirm-dlg/confirm-dlg.component';
import { IDialogPopUpConfig, IGeneralSelect, IResultValidate } from '@common/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    private timer: { [fun: string]: any } = { };
    public apps = ['core', 'eval', 'ecount', 'vclass', 'start', 'stats', 'serv', 'pay'];

    constructor(
        public dialog: MatDialog,
        private translate: TranslateService,
    ) {
        console.log('HelperService');
    }


    public async validateFromArray(formArray: FormArray): Promise<IResultValidate[]> {
        const statuses: IResultValidate[] = [];

        for (const fg of formArray.controls) {
            const status = await this.resolveError(fg as FormGroup);

            statuses.push(status);
        }

        return statuses;
    }

    public async resolveError(formGroup: FormGroup): Promise<IResultValidate> {
        let status: IResultValidate = { };
        const ValidationMessages = await this.getTranslate('ValidationMessages');

        for (const key of Object.keys(formGroup.controls)) {
            const control: any = formGroup.controls[key];

            const obj: any = {
                [key]: {
                    status: true,
                    messages: [],
                },
            };

            if (control instanceof FormArray) {
                continue;
            }

            if (control.invalid) {
                obj[key].status = false;

                Object.keys(control.errors).forEach(type => {
                    if (type === 'minlength') {
                        obj[key].messages.push(ValidationMessages[type] + control.errors[type].requiredLength);
                    } else if (type === 'maxlength') {
                        obj[key].messages.push(ValidationMessages[type] + control.errors[type].requiredLength);
                    } else if (type === 'min') {
                        obj[key].messages.push(ValidationMessages[type] + control.errors[type].min);
                    } else if (type === 'max') {
                        obj[key].messages.push(ValidationMessages[type] + control.errors[type].max);
                    } else {
                        obj[key].messages.push(ValidationMessages[type]);
                    }
                });
            }
            status = {
                ...status,
                ...obj,
            };

            // f (control instanceof FormArray) {
            //     for (const fc of control.controls) {
            //         this.resolveError(fc as any, status);
            //     }
            // }
        }

        console.log(status);

        return status;
    }

    public async messageDialog(config: IDialogPopUpConfig, cb?: (confirm?: boolean) => void | Promise<void>): Promise<void> {
        const list = await this.getTranslate('list');

        const icon = {
            confirm: 'assets/images/ok.png',
            delete: 'assets/images/icon_delete.png',
        };

        const dialog = await this.dialog.open(ConfirmDlgComponent, {
            width: config.width || '300px',
            height: config.height || '300px',
            panelClass: '',
            data: {
                title: list[config.title] || config.title,
                message: list[config.message] || config.message,
                confirmButtonText: list.CONFIRM_BUTTON_TEXT,
                cancelButtonText: list.CANCEL_BUTTON_TEXT,
                imgSrc: icon[config.type],
            },
        });

        dialog.afterClosed().subscribe(async confirm => {
            if (cb) {
                await cb(confirm);
            }
        });
    }

    public async getTranslate(key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.translate.get(key).subscribe((res) => {
                resolve(res);
            });
        });
    }

    public async getApps(): Promise<IGeneralSelect[]> {
        this.apps.sort();

        const trans = (await this.getTranslate('apps')) || { };

        return this.apps.map(app => ({ id: app, itemName: trans[app] || app }));
    }

    public onceCallFunc(id: string, callback: () => any, gapTime = 200): void {
        const funName = `${id}_${callback.name}`;

        // eslint-disable-next-line no-console
        console.log('onceCallFunc', funName, this.timer[funName]);

        if (typeof this.timer[funName] === 'undefined') {
            this.timer[funName] = null;
        }

        clearTimeout(this.timer[funName]);

        this.timer[funName] = setTimeout(async () => {
            await callback();
        }, gapTime);
    }
}
