import { Component } from '@angular/core';
import { CoreProfile } from '@common/entities';
import { StorageService } from '@common/services';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    public profile!: CoreProfile;

    constructor(
        private storageService: StorageService,
        public loginService: LoginService,
    ) {
        this.profile = this.storageService.getCoreProfile();
    }

    onLogout(): void {
        this.loginService.onLogout();
    }
}
