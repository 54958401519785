import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { Group } from './Group.entity';


export class OrgClass {
    @Expose()
    public CLASS_ID!: string;

    @Expose()
    public SHICHVA!: number;

    @Expose()
    public MAKBILA!: number;

    @Expose()
    public NAME!: string;

    @Expose()
    public NAME_KINUI: any;

    @Expose()
    public myGroups!: number[];

    @Expose()
    @Transform(({ value }) => (value ? value.filter((item: any) => !!item) : []))
    public mySubjects!: number[];

    @Expose()
    @Transform(({ value }) => (value ? value.filter((item: any) => !!item) : []))
    public myManagers!: number[];

    public groups: (Group & { selected?: boolean })[] = [];
    public selected: boolean = false;
    public showGroups!: boolean;
    public groupLoading: boolean = false;

    constructor(payload: Partial<OrgClass>) {
        return plainToClass(OrgClass, payload, { excludeExtraneousValues: true });
    }
}
