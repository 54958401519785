export enum GroupType {
    FACE_TO_FACE = 1,
    SINGLE = 2,
    STAY = 4,
    JOB = 5,
}

export enum GroupActivity {
    CREW_SESSION = 7,
    FACE_TO_FACE = 1,
}
