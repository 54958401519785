
import { Expose, Type, plainToClass } from 'class-transformer';

export class CoreEducationPhase {
    @Expose()
    @Type(() => Number)
    public id!: number;

    @Expose()
    public desc!: string;

    @Expose()
    @Type(() => Number)
    public grades!: number[];

    constructor(payload?: Partial<CoreEducationPhase>) {
        return plainToClass(CoreEducationPhase, payload, { excludeExtraneousValues: true });
    }
}
