import { Injectable } from '@angular/core';
import { CoreProfile, CoreGroup, KindixUser } from '@common/entities';
import { IProfile } from '@common/interfaces';
import { ILessonStoredData } from '@common/interfaces/lesson.interface';
import { CookieService } from 'ngx-cookie-service';
import { ExtraAuthService } from './extra-auth.service';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    constructor(
        private cookieService: CookieService,
        private extraAuthService: ExtraAuthService,
    ) { }

    public saveDataObject(key: string, value: object): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public getDataObject(key: string): any {
        return JSON.parse(localStorage.getItem(key) || '{}');
    }

    public saveData(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getData(key: string): string {
        return localStorage.getItem(key) as any;
    }

    public removeData(key: string): void {
        localStorage.removeItem(key);
    }

    public clearData(): void {
        localStorage.clear();
    }

    public getLesson(): ILessonStoredData {
        return this.getDataObject('lesson');
    }

    public saveLesson(lesson: ILessonStoredData): void {
        this.saveDataObject('lesson', lesson);
    }

    public saveLang(lang: string): string {
        this.saveData('lang', lang);

        return lang;
    }

    public getLang(): string {
        if (this.getData('lang')) {
            return this.getData('lang');
        }

        const userData = JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token')) || '{}');

        const lang = (userData && userData.lang) ? (Array.isArray(userData.lang) ? userData.lang[0] : userData.lang) : 'ar';

        this.saveData('lang', lang);

        return lang;
    }

    public setSelectedGroup(group: CoreGroup): void {
        this.saveDataObject('selectedGroup', group);
    }

    public getSelectedGroup(): CoreGroup {
        const data = this.getDataObject('selectedGroup');

        return new CoreGroup(data);
    }

    // public getProfile(): IProfile {
    //     return this.getDataObject('profile');
    // }

    // public setProfile(profile: IProfile): void {
    //     this.saveDataObject('profile', profile);
    // }

    public getCoreProfile(): CoreProfile {
        const profile = new CoreProfile(this.getDataObject('profile'));

        return profile;
    }

    public setCoreProfile(profile: CoreProfile): void {
        this.saveDataObject('profile', profile);
    }

    public getUser(): KindixUser {
        return this.cookieService.check('kindix-token') ? JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token'))) : { };
    }

    public get kindixToken(): string {
        const userData = JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token')) || '{}');

        return userData.accessToken;
    }

}
