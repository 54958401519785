import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { HeaderComponent } from './components/header/header.component';
import { CustomTranslateService } from './services/custom-translate.service';
import { LoginService } from './services/login.service';
import { CustomCommonModule } from '@common/modules/custom-common.module';
import { CommonServicesModule } from '@common/services/common-services.module';
import { CommonComponentsModule } from '@common/components';
import { ProfileService } from './services/profile.service';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LoadingBarModule,
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        CustomCommonModule,
        CommonServicesModule,
        CommonComponentsModule,
    ],
    providers: [
        LoginService,
        CustomTranslateService,
        ProfileService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
