import { Expose, Transform, Type } from 'class-transformer';

export class BaseColumnWithClassIdSchemaPart {
    @Expose()
    @Type(() => Number)
    public id?: number;

    @Expose()
    @Type(() => Number)
    public externalId?: number;

    @Expose()
    @Type(() => Number)
    public year?: number;

    @Expose()
    @Type(() => Date)
    public created_at?: Date;

    @Expose()
    @Type(() => Date)
    public updated_at?: Date;

    @Expose()
    @Type(() => Date)
    public deleted_at?: Date;

    @Expose()
    @Transform(({ obj }) => obj && !!obj.deleted_at)
    public isDeleted?: boolean;

    @Expose()
    @Type(() => Number)
    public grade!: number;

    @Expose()
    @Type(() => Number)
    public gradeSection!: number;
}
