import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { CoreSubject } from './core/CoreSubject.entity';
import { CoreStudent } from './core/CoreStudent.entity';
import { CoreGroup } from './core/CoreGroup.entity';
import { CorePeriod } from './core/CorePeriod.entity';
import { EvalComponent } from './EvalComponent.entity';
import { EventType } from './EventType.entity';


export class StudentEvent extends BaseEntityClass {
    @Expose()
    public event!: Event;

    @Expose()
    public eventId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public classId!: string;

    /**
     * code_tkufat_peilut
     */
    @Expose()
    public periodId!: number;

    @Expose()
    public eventTypeId!: number;

    @Expose()
    public componentId!: number;

    @Expose()
    public eventScore!: number;

    @Expose()
    public percentOfParent!: number;

    @Expose()
    public score!: number;

    @Expose()
    public statementId!: number;

    @Expose()
    public statementIds!: { id: number, name: string }[];

    @Expose()
    public note!: string;

    @Expose()
    public teacherId!: number;

    @Expose()
    public date!: Date;

    @Expose()
    public eventTitle?: string;

    @Expose()
    public inputStatementIds?: string;

    @Expose()
    @Type(() => CoreSubject)
    public subject!: CoreSubject;

    @Expose()
    @Type(() => CoreStudent)
    public student!: CoreStudent;

    @Expose()
    @Type(() => CoreGroup)
    public group!: CoreGroup;

    @Expose()
    @Type(() => CorePeriod)
    public period!: CorePeriod;

    @Expose()
    @Type(() => EvalComponent)
    public component!: EvalComponent;

    @Expose()
    @Type(() => EventType)
    public eventType!: EventType;

    constructor(payload?: Partial<StudentEvent>) {
        super();

        return plainToClass(StudentEvent, payload, { excludeExtraneousValues: true });
    }
}
