
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { CoreStudent } from './CoreStudent.entity';
import { CoreRelationshipType } from './CoreRelationshipType.entity';
import { CoreGender } from './CoreGender.entity';
import { CoreRelationshipDetail } from './CoreRelationshipDetail.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreRelationship extends BaseEntityClass {
    @Expose()
    public relationshipId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public studentIdType!: number;

    @Expose()
    public relationshipIdType!: number; // type of ID

    @Expose()
    public relationshipTypeId!: number;

    @Expose()
    public genderId!: number;

    @Expose()
    public lastName!: string;

    @Expose()
    public firstName!: string;

    @Expose()
    public allowGetInfo!: number;

    @Expose()
    public allowPay!: number;

    @Expose()
    public relationshipStatusId!: number;

    @Expose()
    public dateOfBirth!: Date;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    public username!: string;

    @Expose()
    @Type(() => CoreRelationshipDetail)
    @Transform(({ value }) => value || { })
    public relationshipDetail!: CoreRelationshipDetail;

    @Expose()
    @Type(() => CoreStudent)
    public student!: CoreStudent;

    @Expose()
    @Type(() => CoreRelationshipType)
    public relationshipType!: CoreRelationshipType;

    @Expose()
    @Type(() => CoreGender)
    public gender!: CoreGender;

    constructor(payload?: Partial<CoreRelationship>) {
        super();

        return plainToClass(CoreRelationship, payload, { excludeExtraneousValues: true });
    }
}
