
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { PayPayments } from './PayPayments.entity';
import { PayInvoices } from './PayInvoices.entity';
import { CoreStudent } from '../core/CoreStudent.entity';
import * as moment from 'moment';
import { PayStudentPayments } from './PayStudentPayments.entity';

export class PayStudentPaymentsInvoices extends BaseEntityClass {

    @Expose()
    public paymentId!: number;

    @Expose()
    public paymentId2!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public invoiceId!: number;

    @Expose()
    public cost!: number;

    @Expose()
    public note!: string;

    @Expose()
    public payment!: PayPayments;

    @Expose()
    public date!: string;

    @Expose()
    @Type( () => CoreStudent)
    public student!: CoreStudent;

    @Expose()
    @Type( () => PayInvoices)
    public invoice!: PayInvoices;


    @Expose()
    @Type( () => PayStudentPayments)
    public studentPayment!: PayStudentPayments;

    public totalDebt?: number;
    public totalReminder: number = 0;
    public totalPaid?: number;
    public totalAmount: number = 0;

    constructor(payload?: Partial<PayStudentPaymentsInvoices>) {
        super();

        return plainToClass(PayStudentPaymentsInvoices, payload, { excludeExtraneousValues: true });
    }
}


