
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { CoreGroupType } from './CoreGroupType.entity';
import { CoreGroupStatus } from './CoreGroupStatus.entity';
import { CoreSubject } from './CoreSubject.entity';
import { CoreGroupLevel } from './CoreGroupLevel.entity';
import { CoreGroupActivityType } from './CoreGroupActivityType.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CorePeriod } from './CorePeriod.entity';
import { CoreClass } from './CoreClass.entity';
import { CoreGroupMember } from './CoreGroupMember.entity';
import { MemberType, ReasonLeaving } from '@common/enums';
import { CoreTeacher } from './CoreTeacher.entity';
import { CoreStudent } from './CoreStudent.entity';

export class CoreGroup extends BaseEntityClass {
    @Expose()
    public groupId!: number;

    @Expose()
    public groupTypeId!: number;

    @Expose()
    public periodId!: number;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    public subjectId!: number;

    @Expose()
    public groupName!: string;

    @Expose()
    public ministrySubjectId!: number;

    @Expose()
    public groupLevelId!: number;

    @Expose()
    public educationPhaseId!: number;

    @Expose()
    public createdUserId!: number;

    @Expose()
    public updatedUserId!: number;

    @Expose()
    public activityTypeId!: number;

    @Expose()
    public groupStatusId!: number;

    @Expose()
    public numOfHours!: number;

    @Expose()
    @Transform(({ obj }: { obj: CoreGroup }) => {
        return obj.groupingId || obj.groupId;
    })
    public groupingId!: number;

    @Expose()
    public grade!: number;

    @Expose()
    public gradeSection!: number;

    @Expose()
    public numOfStudents!: number;

    @Expose()
    public studentIds!: number[];

    @Expose()
    @Type(() => CoreGroupType)
    public groupType!: CoreGroupType;

    @Expose()
    @Type(() => CoreGroupStatus)
    public groupStatus!: CoreGroupStatus;

    @Expose()
    @Type(() => CoreGroupLevel)
    public groupLevel!: CoreGroupLevel;

    @Expose()
    @Type(() => CoreGroupActivityType)
    public groupActivityType!: CoreGroupActivityType;

    @Expose()
    @Type(() => CoreSubject)
    public subject!: CoreSubject;

    @Expose()
    @Type(() => CorePeriod)
    public period!: CorePeriod;

    @Expose()
    @Type(() => CoreClass)
    public class!: CoreClass;

    @Expose()
    @Type(() => CoreClass)
    public classes!: CoreClass[];

    @Expose()
    @Transform(({ obj }) => obj.grade ? `${obj.grade}-${obj.gradeSection}` : '')
    public classId!: string;

    @Expose()
    @Type(() => CoreGroupMember)
    public groupMembers!: CoreGroupMember[];

    @Expose()
    @Transform(({ obj }: { obj: CoreGroup }) => {
        return plainToClass(
            CoreGroupMember,
            obj.groupMembers && obj.groupMembers.filter(item => item.memberType === MemberType.TEACHER) || [],
            { excludeExtraneousValues: true },
        );
    })
    @Type(() => CoreGroupMember)
    public teacherGroupMembers!: CoreGroupMember[];

    @Expose()
    @Transform(({ obj }: { obj: CoreGroup }) => {
        return plainToClass(
            CoreGroupMember,
            obj.groupMembers && obj.groupMembers.filter(item => item.memberType === MemberType.STUDENT) || [],
            { excludeExtraneousValues: true },
        );
    })
    @Type(() => CoreGroupMember)
    public studentGroupMembers!: CoreGroupMember[];

    @Expose()
    @Type(() => CoreTeacher)
    public teachers!: CoreTeacher[];

    @Expose()
    @Type(() => CoreStudent)
    public students!: CoreStudent[];

    @Expose()
    @Type(() => Number)
    public teacherIds!: number[];

    public get teacherNames(): string {
        if (this.teachers && this.teachers.length > 0) {
            if (this.teachers.length > 1) {
                return `${this.teachers[0] && this.teachers[0].fullName || '' } (${this.teachers.length})`;
            }
            return `${this.teachers[0] && this.teachers[0].fullName || ''}`;
        }

        return '';
    }

    public get classesNames(): string {
        return this.classes.map(item => item.name1).join(', ');
    }

    constructor(payload?: Partial<CoreGroup>) {
        super();

        return plainToClass(CoreGroup, payload, { excludeExtraneousValues: true });
    }
}
