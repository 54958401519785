import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class MusicTool extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public type!: string;

    @Expose()
    public code!: string;

    @Expose()
    public manager!: string;

    @Expose()
    public storagePlace!: string;

    constructor(payload?: Partial<MusicTool>) {
        super();

        return plainToClass(MusicTool, payload, { excludeExtraneousValues: true });
    }
}
