import { NgModule } from '@angular/core';
import { EcountApiService } from './api/ecount.service';
import { ExtraApiService } from './api/extra-api.service';
import { StorageService } from './helpers/storage.service';
import { HelperService } from './helpers/helper.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ServeApiService } from './api/serve-api.service';
import { EvalApiService } from './api/eval-api.service';
import { CoreApiService } from './api/core-api.service';
import { GeneralService } from './helpers/general.service';
import { StartApiService } from './api/start-api.service';
import { FormApiService } from './api/form-api.service';
import { UtilApiService } from './api/util-api.service';
import { ThemeService } from './helpers/theme.service';
import { ExamsApiService } from './api/exams-api.service';
import { StateService } from './helpers/state.service';
import { CloudApiService } from './api/cloud-api.service';


@NgModule({
    imports: [
        MatDialogModule,
    ],
    providers: [
        HelperService,
        ExtraApiService,
        EcountApiService,
        StorageService,
        ServeApiService,
        EvalApiService,
        CoreApiService,
        GeneralService,
        StartApiService,
        FormApiService,
        ExamsApiService,
        UtilApiService,
        ThemeService,
        StateService,
        CloudApiService,
    ],
    declarations: [
    ],
    exports: [
    ],
})
export class CommonServicesModule { }
