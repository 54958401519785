<ng-container>
  <h1 *ngIf="title" style="text-align: center" mat-dialog-title>{{ title }}</h1>

  <div dir="rtl" mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 text-c">
          <img [src]="imgSrc" width="50%" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-c">
          <div class="text-c fs-14 text-dark">{{ message }}</div>
        </div>
      </div>
    </div>
  </div>

  <div dir="rtl" class="text-center" *ngIf="showActions" mat-dialog-actions>
    <button
        class="btn text-white rounded me-1"
        (click)="onConfirm()"
        [class.btn-primary]="!action"
        [class.btn-danger]="action === 'delete'"
    >
      {{ "list.ok" | translate }}
    </button>
    <button class="btn btn-light rounded mx-2" (click)="onCancel()">
      {{ "list.cancel" | translate }}
    </button>
  </div>
</ng-container>
