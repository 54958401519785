import { Expose, plainToClass, Type } from 'class-transformer';
import { RelationDetail } from './RelationDetail.entity';


export class Relationship {
    @Expose()
    public MISPAR_ZEHUT_GOREM_KESHER!: number;

    @Expose()
    public MISPAR_ZEHUT_TALMID!: number;

    @Expose()
    public CODE_SUG_KIRVA!: number;

    @Expose()
    public SHEM_MISHPACHA_KINUI!: string;

    @Expose()
    public SHEM_PRATI_KINUI!: string;

    @Expose()
    public TEUR_SUG_KIRVA!: string;

    @Expose()
    public firstName!: string;

    @Expose()
    public lastName!: string;

    @Expose()
    public relationshipId!: number;

    @Expose()
    @Type(() => RelationDetail)
    public relationDetail!: RelationDetail;

    constructor(payload?: Partial<Relationship>) {
        return plainToClass(Relationship, payload, { excludeExtraneousValues: true });
    }
}
