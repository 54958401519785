import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreStudent } from './CoreStudent.entity';

export class CoreStudentBus extends BaseEntityClass {
    @Expose()
    public busName!: string;

    @Expose()
    public companyName!: string;

    @Expose()
    public busRoute!: string;

    @Expose()
    public note!: string;

    @Expose()
    @Type(() => CoreStudent)
    public students!: CoreStudent[];

    constructor(payload?: Partial<CoreStudentBus>) {
        super();

        return plainToClass(CoreStudentBus, payload, { excludeExtraneousValues: true });
    }
}
