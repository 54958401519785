
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { StartTeacherAbsentStatus } from '@common/enums';
import { CoreTeacher } from '../core/CoreTeacher.entity';
import { CoreGroup } from '../core/CoreGroup.entity';

export class StartTeacherAbsent extends BaseEntityClass {
    @Expose()
    public teacherId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    public date!: Date;

    @Expose()
    public dayId!: number;

    @Expose()
    public reason!: number;

    @Expose()
    public note!: string;

    @Expose()
    public imgBase64: undefined;

    @Expose()
    public startTime!: Date;

    @Expose()
    public endTime!: Date;

    @Expose()
    public status!: StartTeacherAbsentStatus;

    @Expose()
    public approve: undefined;

    @Expose()
    public teacherReplaceId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    public attachment!:  {
        id: number;
        file: string;
    };

    @Expose()
    @Type(() => CoreTeacher)
    public teacher?: CoreTeacher;

    @Expose()
    @Type(() => CoreGroup)
    public group!: CoreGroup;

    @Expose()
    @Type(() => CoreTeacher)
    public teacherReplace?: CoreTeacher;


    constructor(payload?: Partial<StartTeacherAbsent>) {
        super();

        return plainToClass(StartTeacherAbsent, payload, { excludeExtraneousValues: true });
    }
}
