import { Expose, plainToClass, Type } from 'class-transformer';

export interface ICoreClass {
    CLASS_ID: string;
    SHICHVA: number;
    MAKBILA: number;
    NAME: string;
}

export interface OvdeyHoraa {
    MISPAR_ZEHUT: number;
    SHEM_PRATI: string;
    SHEM_MISHPACHA: string;
}

export class Group {
    @Expose()
    public groupName!: number;

    @Expose()
    public CODE_SUG_KVUTSA!: number;

    @Expose()
    public CODE_TKUFAT_PEILUT!: string;

    @Expose()
    public MISPAR_MIKTSOA_BEIT_SIFRI!: number;

    @Expose()
    public SHEM_KVUTSA!: string;

    @Expose()
    public CODE_SUG_PELOT_KVUTSA!: number;

    @Expose()
    public CODE_STATUS_KVUTSA!: number;

    @Expose()
    public MISPAR_SHAOT!: number;

    @Expose()
    public CLASS_ID!: string;

    @Expose()
    public KITOT_ARR!: ICoreClass[];

    @Expose()
    public OVDEY_HORAA!: OvdeyHoraa[];

    @Expose()
    public NUMBER_TALMIDIM!: number;

    @Expose()
    public SHEM_MIKTSOA_BEIT_SIFRI!: string;

    @Expose()
    public TEUR_TKUFAT_PEILUT!: string;

    @Expose()
    public TEUR_SUG_KVUTSA!: string;

    @Expose()
    public TEUR_STATUS_KVUTSA!: string;

    @Expose()
    public studentIds!: number[];

    constructor(payload?: Partial<Group>) {
        return plainToClass(Group, payload, { excludeExtraneousValues: true });
    }
}
