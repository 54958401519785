import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreRole } from './CoreRole.entity';
import { CoreCapability } from './CoreCapability.entity';

export class CoreRoleCapability extends BaseEntityClass {
    @Expose()
    public roleId!: number;

    @Expose()
    public capabilityId!: number;

    @Expose()
    @Type(() => CoreRole)
    public role!: CoreRole;

    @Expose()
    @Type(() => CoreCapability)
    public capability!: CoreCapability;

    constructor(payload?: Partial<CoreRoleCapability>) {
        super();

        return plainToClass(CoreRoleCapability, payload, { excludeExtraneousValues: true });
    }
}
