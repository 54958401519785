import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ProviderTranslateChildModule } from './provider-translate-child.module';
import { ProviderTranslateModule } from './provider-translate.module';

export const HttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?cb=' + new Date().getTime());
};

@NgModule({
    imports: [],
    exports: [
        ProviderTranslateChildModule,
        ProviderTranslateModule,
    ],
    declarations: [],
})
export class CustomCommonModule { }
