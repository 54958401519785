import { IAttendStatusCount } from '@common/interfaces';
import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class EvalAttendance extends BaseEntityClass {
    @Expose()
    public studentId!: number;

    @Expose()
    public periodId!: number;

    @Expose()
    public eventTypeId!: number;

    @Expose()
    public status!: IAttendStatusCount;

    @Expose()
    public statementsIds!: { id: number; itemName: string; }[];

    @Expose()
    public note!: string;

    @Expose()
    public managerStatementsIds!: { id: number; itemName: string; }[];

    @Expose()
    public managerNote!: string;

    constructor(payload?: Partial<EvalAttendance>) {
        super();

        return plainToClass(EvalAttendance, payload, { excludeExtraneousValues: true });
    }
}
