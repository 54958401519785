import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class SMSPurchases extends BaseEntityClass {
    @Expose()
    public segmentLength!: number;

    @Expose()
    public price!: number;

    @Expose()
    public amount!: number;

    constructor(payload?: Partial<SMSPurchases>) {
        super();

        return plainToClass(SMSPurchases, payload, { excludeExtraneousValues: true });
    }
}
