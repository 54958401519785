
import { Expose, Type, plainToClass } from 'class-transformer';
import { CloudOrg } from './CloudOrg.entity';
import { CloudApplication } from './CloudApplication.entity';

export class CloudOrgApp {
    @Expose()
    public org_id!: number;

    @Expose()
    public app_code!: string;

    @Expose()
    public year!: number;

    @Expose()
    public url!: string;

    @Expose()
    public port!: number;

    @Expose()
    public status!: number;

    @Expose()
    @Type(() => CloudOrg)
    public org!: CloudOrg;

    @Expose()
    @Type(() => CloudApplication)
    public app!: CloudApplication;

    constructor(payload?: Partial<CloudOrgApp>) {
        return plainToClass(CloudOrgApp, payload, { excludeExtraneousValues: true });
    }
}
