import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'err-view',
    templateUrl: './err-view.component.html',
    styleUrls: ['./err-view.component.css'],
})
export class ErrViewComponent implements OnInit {
    @Input() state: any;
    constructor() { }

    ngOnInit(): void {
    }

}
