import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StartLockedCell extends BaseEntityClass {
    @Expose()
    public timeTableId!: number;

    @Expose()
    public itemId!: string;

    @Expose()
    public lessonId!: number;

    @Expose()
    public dayId!: number;

    constructor(payload?: Partial<StartLockedCell>) {
        super();

        return plainToClass(StartLockedCell, payload, { excludeExtraneousValues: true });
    }
}
