import { Component, OnInit } from '@angular/core';
import { ApiService } from '@common/services/api/api-old.service';

interface IVersion {
    app_name?: string;
    org_id?: string;
    formal_version?: string;
    active_version?: string;
}

@Component({
    selector: 'common-update-version',
    templateUrl: './update-version.component.html',
    styleUrls: ['./update-version.component.scss']
})
export class UpdateVersionComponent implements OnInit {
    public version: IVersion = {};
    public loading = false;

    constructor(
        private apiService: ApiService
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.loadVersion();
    }

    private async loadVersion(): Promise<void> {
        this.version = await this.apiService.get('version', null, false);
    }

    public get isUpdated(): boolean {
        return this.version && this.version.active_version === this.version.formal_version;
    }

    public async onUpdate(): Promise<void> {
        this.loading = true;

        try {
            await this.apiService.put('upgrade-version', null, false);
        } catch { }

        setTimeout(async () => {
            await this.loadVersion();
            this.loading = false;
        }, 10000);
    }
}
