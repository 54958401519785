
import { Expose, Type, plainToClass } from 'class-transformer';
import { CoreRelationship } from './CoreRelationship.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreRelationshipDetail extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public relationshipId!: number;

    @Expose()
    @Type(() => Number)
    public relationshipIdType!: number;

    @Expose()
    @Type(() => Number)
    public relationshipDetailStatusId!: number;

    @Expose()
    @Type(() => Number)
    public villageId1!: number;

    @Expose()
    public villageName1!: string;

    @Expose()
    @Type(() => Number)
    public streetId1!: number;

    @Expose()
    public streetName1!: string;

    @Expose()
    @Type(() => Number)
    public homeId1!: number;

    @Expose()
    @Type(() => Number)
    public apartmentId1!: number;

    @Expose()
    @Type(() => Number)
    public zipCode1!: number;

    @Expose()
    @Type(() => Number)
    public villageId2!: number;

    @Expose()
    public villageName2!: string;

    @Expose()
    @Type(() => Number)
    public streetId2!: number;

    @Expose()
    public streetName2!: string;

    @Expose()
    @Type(() => Number)
    public homeId2!: number;

    @Expose()
    public homeDetail2!: string;

    @Expose()
    @Type(() => Number)
    public apartmentId2!: number;

    @Expose()
    @Type(() => Number)
    public mailBox!: number;

    @Expose()
    @Type(() => Number)
    public zipCode2!: number;

    @Expose()
    public neighborhood!: string;

    @Expose()
    public neighborhoodDesc!: string;

    @Expose()
    public mailBox2!: string;

    @Expose()
    public prefixTel!: string;

    @Expose()
    public tel1!: string;

    @Expose()
    public prefixTel2!: string;

    @Expose()
    public tel2!: string;

    @Expose()
    public prefixMobile1!: string;

    @Expose()
    public mobile1!: string;

    @Expose()
    public prefixMobile2!: string;

    @Expose()
    public mobile2!: string;

    @Expose()
    public email1!: string;

    @Expose()
    public email2!: string;

    @Expose()
    @Type(() => CoreRelationship)
    public relationship!: CoreRelationship;

    constructor(payload?: Partial<CoreRelationshipDetail>) {
        super();

        return plainToClass(CoreRelationshipDetail, payload, { excludeExtraneousValues: true });
    }
}

