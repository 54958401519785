export enum CloudAction {
    ADD_ROLES = 'add_roles',
    ADD_ROLES_USERS = 'add_roles_users',
    ADD_PERIODS = 'add_periods',
    ADD_ORG = 'add_org',
    ADD_SUPER_ADMIN = 'add_super_admin',
    ADD_ROLES_CAPABILITIES = 'add_roles_capabilities',
    LINK_APPS = 'link_apps',

    CLONE_SUBJECTS = 'clone_subjects',
    CLONE_TEACHERS = 'clone_teachers',
    CLONE_ORGS = 'clone_orgs',
    CLONE_GRADES = 'clone_grades',
    CLONE_ROLES = 'clone_roles',
    CLONE_ROLES_USERS = 'clone_roles_users',
    CLONE_ROLE_CAPABILITIES = 'clone_role_capabilities',
    CLONE_LESSONS = 'clone_lessons',
    CLONE_ALL_CORE = 'clone_all_core',
    CLEAR_CACHE = 'clear_cache',
    CLONE_GROUPS = 'clone_groups',
    CLONE_GROUP_MEMBERS = 'clone_group_members',

    // Start
    CLONE_TIME_TABLES = 'clone_time_tables',
    CLONE_TIME_TABLE_DATA = 'clone_time_table_data',
    CLONE_LOCKED_CELLS = 'clone_locked_cells',
    CLONE_DAYS = 'clone_days',
    CLONE_ALL_START = 'clone_all_start',
}
