import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';


export class StudentHappened extends BaseEntityClass {
    @Expose()
    public happenedId!: string;

    @Expose()
    public studentId!: string;

    constructor(payload?: Partial<StudentHappened>) {
        super();

        return plainToClass(StudentHappened, payload, { excludeExtraneousValues: true });
    }
}
