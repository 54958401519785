import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StartConstraint extends BaseEntityClass {
    @Expose()
    public timeTableId!: number;

    @Expose()
    public itemId!: string;

    @Expose()
    public constTypeId!: number;

    @Expose()
    public min!: number;

    @Expose()
    public max!: number;

    @Expose()
    public metadata!: object;

    constructor(payload?: Partial<StartConstraint>) {
        super();

        return plainToClass(StartConstraint, payload, { excludeExtraneousValues: true });
    }
}
