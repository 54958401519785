import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { CoreSubject } from './core/CoreSubject.entity';

export class EvalStatement extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public titleMin1!: string;

    @Expose()
    public titleMin2!: string;

    @Expose()
    public subjectId!: number;

    @Expose()
    @Transform(({ value }: { value: any[] }) => !value ? [] : value.map(item => Number(item)))
    public layerIds!: number[];

    @Expose()
    public status!: number;

    @Expose()
    public subject!: CoreSubject;

    constructor(payload?: Partial<EvalStatement>) {
        super();

        return plainToClass(EvalStatement, payload, { excludeExtraneousValues: true });
    }
}
