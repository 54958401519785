<div class="container-fluid">
  <ng-container>
    <div class="row mt-3 align-content-center" *ngIf="loading">
      <div class="col-12 text-center">
        <div class="spinner-border" style="width: 5rem; height: 5rem">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="data && data.length === 0">
      <div class="row">
        <div class="col-12">
          <err-view [state]="{ code: 403, size: '30%' }"></err-view>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-12" *ngIf="searchable && data.length > 0">
        <div class="row">
          <div class="col-md-3 m-b-10">
            <i
              class="fa fa-search fa-search pos-absolute top-m right-25 text-color-gray1"
            ></i>
            <input
              [(ngModel)]="search"
              placeholder="{{ 'list.search' | translate }}"
              class="h-full w-full b-radius-circle p-r-35 p-t-7 p-b-7 fs-14 b-r-15 out-l-0 p-all-2 text-color-gray border-0 bg-gray"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="data && data.length > 0">
              <table dir="rtl">
                <thead *ngIf="showHeader">
                  <tr>
                    <ng-container *ngFor="let col of columns; index as ci">
                      <ng-container *ngIf="col.visible">
                        <th
                          [width]="col.width"
                          class="th-custom"
                          [ngClass]="col.th_class"
                        >
                          <ng-container
                            *ngIf="col.label == 'checkbox' && !singleSelect"
                          >
                            <core-button
                              (click)="onClickSelectAll()"
                              [width]="35"
                              [active]="selectAll"
                              [activeStyle]="{
                                class: 'b-radius-circle',
                                color: 'white',
                                bgColor: 'unset',
                                img: '/assets/images/selected.png'
                              }"
                              [notActiveStyle]="{
                                class: 'b-radius-circle',
                                color: 'white',
                                bgColor: 'unset',
                                img: '/assets/images/nonselected.png'
                              }"
                            >
                            </core-button>
                          </ng-container>

                          <div>
                            <ng-container *ngIf="col.sort">
                              <i
                                (click)="
                                  orderBy([col.key], true); $event.stopPropagation()
                                "
                                class="fa fa-sort pointer"
                                aria-hidden="true"
                              ></i>
                            </ng-container>
                            {{ "list." + col.label | translate }}
                          </div>
                        </th>
                      </ng-container>
                    </ng-container>
                  </tr>
                </thead>
                <tbody
                  cdkDropList
                  cdkDropList
                  (cdkDropListDropped)="drop($event)"
                >
                  <ng-container
                    *ngFor="let row of _data | ExtraFilter : search; index as i"
                  >
                    <tr
                      cdkDrag
                      [cdkDragDisabled]="dargDisable"
                      ngClass="shadow2"
                      [style.background-color]="
                        i % 2 === 0 ? setting.eBgColor : setting.oBgColor
                      "
                      [style.background-color]="row.bgColor"
                      (click)="onRowClick(row); $event.stopPropagation()"
                      [class.locked]="row.locked"
                    >
                      <ng-container *ngFor="let col of columns">
                        <ng-container *ngIf="col.visible">
                          <td [width]="col.width" [ngClass]="col.td_class">
                            <ng-container *ngIf="col.label == 'color'">
                              <core-button
                                [active]="true"
                                [width]="80"
                                [height]="20"
                                [activeStyle]="{
                                  class: 'flx hover outhover',
                                  borderRadius: '5px',
                                  color: 'white',
                                  bgColor: getValue(row.row, col)
                                }"
                              >
                              </core-button>
                            </ng-container>

                            <ng-container
                              *ngIf="col.label == 'checkbox' && !row.locked"
                            >
                              <core-button
                                (click)="
                                  onClickCheckBox(row); $event.stopPropagation()
                                "
                                [width]="35"
                                [active]="row.selected"
                                [activeStyle]="{
                                  class: 'b-radius-circle',
                                  color: 'white',
                                  bgColor: 'unset',
                                  img: '/assets/images/selected.png'
                                }"
                                [notActiveStyle]="{
                                  class: 'b-radius-circle',
                                  color: 'white',
                                  bgColor: 'unset',
                                  img: '/assets/images/nonselected.png'
                                }"
                              >
                              </core-button>
                            </ng-container>

                            <!-- [(ngModel)]="getValue(row.row, col)" -->
                            <ng-container *ngIf="col.input">
                              <input
                                [readOnly]="col.readOnly"
                                [type]="col.input_type"
                                (keyup)="onKeyUp(row)"
                                [(ngModel)]="row.row[col.key]"
                                class="text-r fs-14 b-r-15 p-r-10 p-t-5 p-b-3 out-l-0"
                              />
                            </ng-container>

                            <ng-container *ngIf="col.button">
                              <ng-container *ngFor="let b of col.button">
                                <core-button
                                  (click)="
                                    upDateParentActionFunction(
                                      row,
                                      b.action_code
                                    )
                                  "
                                  [loading]="row[b.action_code + '_loading']"
                                  [title]="b.title"
                                  [active]="true"
                                  [activeStyle]="{
                                    class: b.class,
                                    borderRadius: b.borderRadius,
                                    padding: b.padding,
                                    bgColor: b.bgColor,
                                    color: b.color,
                                    wesome: b.wesome
                                  }"
                                >
                                </core-button
                                >&nbsp;
                              </ng-container>
                            </ng-container>

                            <div [class.d-flex]="true">
                              <ng-container *ngIf="col.fa">
                                <ng-container *ngFor="let b of col.fa">
                                  <core-button
                                    (click)="
                                      upDateParentActionFunction(
                                        row,
                                        b.action_code
                                      )
                                    "
                                    [alt]="'list.' + b.action_code | translate"
                                    [loading]="row[b.action_code + '_loading']"
                                    [width]="30"
                                    [height]="30"
                                    [active]="true"
                                    [activeStyle]="{
                                      class: b.class,
                                      bgColor: b.bgColor,
                                      wesome: b.wesome
                                    }"
                                  >
                                  </core-button>
                                  &nbsp;&nbsp;&nbsp;
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  !col.input &&
                                  !col.button &&
                                  !col.checkbox &&
                                  col.label != 'color'
                                "
                              >
                                <ng-container
                                  *ngIf="isArray(getValue(row.row, col))"
                                >
                                  <core-button
                                    [alt]="getValue(row.row, col)"
                                    [title]="getValue(row.row, col)"
                                    [active]="true"
                                    [activeStyle]="{
                                      class: 'fs-14',
                                      borderRadius: '15px',
                                      padding: '6px 15px 2px 15px',
                                      bgColor: 'unset',
                                      color: 'black'
                                    }"
                                  >
                                  </core-button>

                                  <div class="dropdown-menu">
                                    <div>
                                      {{ getValue(row.row, col) }}
                                    </div>
                                  </div>
                                </ng-container>

                                <ng-container
                                  *ngIf="!isArray(getValue(row.row, col))"
                                >
                                  <ng-container
                                    *ngIf="
                                      col.label === 'index';
                                      else elseBlock
                                    "
                                  >
                                    {{ i + 1 }}
                                  </ng-container>

                                  <ng-template #elseBlock>
                                    <ng-container>
                                      {{ getValue(row.row, col) }}
                                    </ng-container>
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </div>
                          </td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-container>
                  <tr height="100%">
                    <td [colSpan]="columns.length"></td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
