
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { ExamsQuestions } from './ExamsQuestions.entity';
import * as moment from 'moment';

export class Exams extends BaseEntityClass {
    @Expose()
    public sectionId!: number;

    @Expose()
    public typeId!: number;

    @Expose()
    public title!: string;

    @Expose()
    public desc!: string;

    @Expose()
    public startDate!: string;

    @Expose()
    public endDate!: string;

    @Expose()
    public time!: number;

    @Expose()
    public examsQuestions!: ExamsQuestions[];

    @Expose()
    @Transform(item => moment(item.obj.startDate).format('YY/MM/DD hh:mm a'))
    public startDateTime!: string;

    @Expose()
    @Transform(item => moment(item.obj.endDate).format('YY/MM/DD hh:mm a'))
    public endDateTime!: string;

    constructor(payload?: Partial<Exams>) {
        super();

        return plainToClass(Exams, payload, { excludeExtraneousValues: true });
    }
}
