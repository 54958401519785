<div class="row">
  <div class="col-md-12 err" *ngIf="state && state.code">
    <img
      [attr.width]="state.size"
      alt="{{ 'errors.' + state.code | translate }}"
      [src]="'./assets/images/errors/' + state.code + '.png'"
    />
  </div>
  <div class="col-md-12 err" *ngIf="!state">
    <img width="400px" src="./assets/images/errors/404.png" />
  </div>
</div>
