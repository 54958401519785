import { ISubject } from '@common/interfaces';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Group } from './Group.entity';
import { Teacher } from './Teacher.entity';
import * as moment from 'moment';

export class ExamSchedule extends BaseEntityClass {
    @Expose()
    public lessonId!: number;

    @Expose()
    public teacherId!: number;

    @Expose()
    @Type(() => Teacher)
    public teacher!: Teacher;

    @Expose()
    public groupId!: number;

    @Expose()
    public groupName!: string;

    @Expose()
    @Type(() => Group)
    public group!: Group;

    @Expose()
    public subjectId!: number;

    @Expose()
    public subject!: ISubject;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    @Transform(({ obj }) => {
        return moment(obj.date).format('YYYY-MM-DD');
    })
    public dateStr?: string;

    @Expose()
    public examTopic!: string;

    @Expose()
    public examDetail!: string;

    @Expose()
    public grade!: number;

    @Expose()
    public gradeSection!: number;

    @Expose()
    @Transform(({ obj }) => `${obj.grade}-${obj.gradeSection}`)
    public classId!: string;

    @Expose()
    public className!: string;

    constructor(payload?: Partial<ExamSchedule>) {
        super();

        return plainToClass(ExamSchedule, payload, { excludeExtraneousValues: true });
    }
}
