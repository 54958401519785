
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class FormForm extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public title!: string;

    @Expose()
    public desc!: string;

    @Expose()
    public config!: ConfigForm;

    constructor(payload?: Partial<FormForm>) {
        super();

        return plainToClass(FormForm, payload, { excludeExtraneousValues: true });
    }
}

export interface ConfigForm {
    header: {
        title: string;
        color: string;
        background: string;
        leftImage: string;
        rightImage: string;
    };
    body: {
        title: string;
        desc: string;
        backgroundLeft: string;
        backgroundRight: string;
        dir: string;
    };
    footer: {
        desc: string;
    };
    setting: {
        name: string;
        desc: string;
        path: string;
        method: string;
        background: string;
    };
    fields: [{
        key: string;
        value: any;
    }];
}

