import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { FormForm } from './Form.entity';

export class FormCoupon extends BaseEntityClass {
    @Expose()
    public formId!: number;

    @Expose()
    public code!: string;

    @Expose()
    public discountType!: number;

    @Expose()
    public discountNumber!: number;

    @Expose()
    public numberUsed!: number;

    @Expose()
    @Type(() => FormForm)
    public form?: FormForm;

    constructor(payload?: Partial<FormCoupon>) {
        super();

        return plainToClass(FormCoupon, payload, { excludeExtraneousValues: true });
    }
}
