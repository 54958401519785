import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export interface IEvalStatsSetting {
    subjectsIds?: number[];
    rangeId?: number;
}

export class EvalStatsSetting extends BaseEntityClass {
    @Expose()
    public key!: string;

    @Expose()
    public value!: IEvalStatsSetting;

    constructor(payload?: Partial<EvalStatsSetting>) {
        super();

        return plainToClass(EvalStatsSetting, payload, { excludeExtraneousValues: true });
    }
}
