import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreClass } from './CoreClass.entity';
import { CoreGender } from './CoreGender.entity';
import { CoreRelationship } from './CoreRelationship.entity';
import { StudentEvent } from '../StudentEvent.entity';
import { EvalAttendance } from '../EvalAttendance.entity';
import { CoreStudentStatus } from './CoreStudentStatus.entity';
import { CoreStudentBus } from './CoreStudentBus.entity';
import { Attendance } from '../Attendance.entity';
import { Behavior } from '../Behavior.entity';

export class CoreStudent extends BaseEntityClass {
    @Expose()
    public idType!: number;

    @Expose()
    public firstName1!: string;

    @Expose()
    public lastName1!: string;

    @Expose()
    public firstName2!: string;

    @Expose()
    public lastName2!: string;

    @Expose()
    public firstName3!: string;

    @Expose()
    public lastName3!: string;

    @Expose()
    public busName!: string;

    @Expose()
    public busId!: number;

    @Expose()
    public schoolId!: number;

    @Expose()
    public genderId!: number;

    @Expose()
    public grade!: number;

    @Expose()
    public gradeSection!: number;

    @Expose()
    public eduStartDate!: Date;

    @Expose()
    public eduEndDate!: Date;

    @Expose()
    public passportId!: string;

    @Expose()
    public passportGroup!: number;

    @Expose()
    public countryId!: number;

    @Expose()
    public dateOfBirth!: Date;

    @Expose()
    public nativeCountryId!: number;

    @Expose()
    public nativeCountryDate!: Date;

    @Expose()
    public nationalismStudentId!: number;

    @Expose()
    public specialtyMinistryId!: number;

    @Expose()
    public specialtyOrgId!: number;

    @Expose()
    public branchId!: number;

    @Expose()
    public CODE_MASLUL!: number;

    @Expose()
    public approvedBranchId!: number;

    @Expose()
    public TKINUT_SHIBUTZ!: number;

    @Expose()
    public LO_LE_DIVUACH_MATZEVET!: number;

    @Expose()
    public leaveSchoolDate!: Date;

    @Expose()
    public exemptionId!: number;

    @Expose()
    public numOfBrotherLT18!: number;

    @Expose()
    public lastStudentId!: number;

    @Expose()
    public lastStudentIdType!: number;

    @Expose()
    public studentStatusId!: number;

    @Expose()
    public email!: string;

    @Expose()
    public phone!: string;

    @Expose()
    public username!: string;

    @Expose()
    public healthMinistryId!: number;


    @Expose()
    @Type(() => CoreGender)
    public gender!: CoreGender;

    @Expose()
    @Type(() => CoreClass)
    public class!: CoreClass;

    @Expose()
    @Type(() => CoreRelationship)
    public relationships!: CoreRelationship[];

    @Expose()
    @Transform(({ obj }: { obj: CoreStudent }) => {
        const row = obj && obj.relationships && obj.relationships.find(i => {
            return i.relationshipTypeId === 5;
        });

        return new CoreRelationship(row);
    })
    public father?: CoreRelationship;

    @Expose()
    @Transform(({ obj }: { obj: CoreStudent }) => {
        const row = obj && obj.relationships && obj.relationships.find(i => {
            return i.relationshipTypeId === 6;
        });

        return new CoreRelationship(row);
    })
    public mather?: CoreRelationship;

    @Expose()
    @Transform(({ obj }) => `${obj.grade}-${obj.gradeSection}`)
    public classId!: string;

    public get fullName(): string {
        return `${this.firstName1} ${this.lastName1}`;
    }

    @Expose()
    @Type(() => EvalAttendance)
    public evalAttendance?: EvalAttendance;

    @Expose()
    @Type(() => CoreStudentStatus)
    public studentStatus?: CoreStudentStatus;

    @Expose()
    @Type(() => CoreStudentBus)
    public studentBus?: CoreStudentBus;

    // ecount field
    @Expose()
    @Type(() => Attendance)
    public attendance!: Attendance;

    @Expose()
    @Type(() => Behavior)
    public behaviors: Behavior[] = [];

    public studentEvents?: StudentEvent[];

    constructor(payload?: Partial<CoreStudent>) {
        super();

        return plainToClass(CoreStudent, payload, { excludeExtraneousValues: true });
    }
}
