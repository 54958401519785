import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CoreApiService, ExtraAuthService, StorageService } from '@common/services';
import { CoreProfile } from '@common/entities';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    public router: Router;
    public user!: CoreProfile;
    public login_url = environment.loginUrl;
    public service_url = environment.service_url;
    public core_service_url = environment.appUrl;
    public userCanAccessCore: boolean = false;
    public objectTest: any;

    constructor(
        private http: HttpClient,
        public appRouter: Router,
        private coreApiService: CoreApiService,
        public storageService: StorageService,
        public authService: ExtraAuthService,
    ) {
        this.router = appRouter;
    }

    login(user: any): void {
        window.location.href = this.login_url;
    }

    getToken(): void {
        this.user = this.storageService.getCoreProfile();
    }

    isAuthorized(): boolean {
        this.getToken();

        if (this.user) {
            return true;
        } else {
            return false;
        }
    }

    public getProfileData(): Promise<CoreProfile> {
        return this.coreApiService.getCoreProfileUser();
    }

    HandleRequestErrors(result: any): void {
        if (result.code === 'TokenExpiredError' || result.code === 'TokenIdelError' || result.name === 'TimeoutError') {
            this.onLogout();
            return;
        }
    }

    onLogout(): void {
        this.authService.logout('apps');
    }
}
