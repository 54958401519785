
import { Expose, plainToClass } from 'class-transformer';

export class CoreEduProgram {
    @Expose()
    public eduProgramId!: number;

    @Expose()
    public name1!: string;

    @Expose()
    public name2!: string;

    @Expose()
    public desc: undefined;

    @Expose()
    public goal: undefined;

    @Expose()
    public scopeId!: number;

    @Expose()
    public scopeDesc!: string;

    @Expose()
    public partId!: string;

    @Expose()
    public partDesc!: string;

    @Expose()
    public statusId!: number;

    @Expose()
    public statusDesc!: string;

    @Expose()
    public orgName!: string;

    @Expose()
    public fundingSourceId!: number;

    @Expose()
    public CODE_MINHAL_ACHRAI!: number;

    @Expose()
    public TEUR_MINHAL_ACHRAI!: string;

    @Expose()
    public BELIVUY_HAMISRAD!: number;


    constructor(payload?: Partial<CoreEduProgram>) {
        return plainToClass(CoreEduProgram, payload, { excludeExtraneousValues: true });
    }
}
