import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'core-checkbox',
    templateUrl: './core-checkbox.component.html',
    styleUrls: ['./core-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoreCheckboxComponent),
            multi: true,
        },
    ],
})
export class CoreCheckboxComponent implements ControlValueAccessor {
    @Input()
    public id: string = '';

    @Input()
    public label: boolean = false;

    @Input()
    public direction: 'rtl' | 'ltr' = 'rtl';

    @Input()
    public name!: string;

    @Input()
    public disabled: boolean = false;

    @Input()
    public required: boolean = false;

    @Input()
    public horizontal: { label: number, input: number } = { label: 0, input: 0 };

    @Input()
    public validation?: { status?: boolean, messages?: string[] } | null;

    @Input()
    public title: string = '';

    public checked = null;
    public inputValue: any = null;

    onChange: any = (_: any[]) => { };
    onTouch: any = () => { };

    constructor() { }

    set value(val: boolean) {
        if (val !== undefined) {
            this.inputValue = val || false;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    onChecked(isChecked: any): void {
        const checked = isChecked.currentTarget.checked || false;

        this.onChange(checked);
        this.onTouch(checked);
    }

    public get labelCol(): string {
        if (this.horizontal && this.horizontal.label) {
            return `col-${this.horizontal.label}`;
        }
        return '';
    }

    public get inputCol(): string {
        if (this.horizontal && this.horizontal.input) {
            return `col-${this.horizontal.input}`;
        }
        return '';
    }
}
