import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreSubject extends BaseEntityClass {
    @Expose()
    public subjectId!: number;

    @Expose()
    public subjectName1!: string;

    @Expose()
    public subjectName2!: string;

    @Expose()
    public ministrySubjectId!: number;

    @Expose()
    public ministrySubjectDesc!: string;

    @Expose()
    public order!: number;

    @Expose()
    public notVisibleInStart!: number;

    constructor(payload?: Partial<CoreSubject>) {
        super();

        return plainToClass(CoreSubject, payload, { excludeExtraneousValues: true });
    }
}
