
import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreRoleUser } from './CoreRoleUser.entity';
import { CoreGender } from './CoreGender.entity';
import { CoreUserStatus } from './CoreUserStatus.entity';

export class CoreTeacher extends BaseEntityClass {
    @Expose()
    public userNumber!: number;

    @Expose()
    public idType!: number;

    @Expose()
    public firstName1!: string;

    @Expose()
    public department!: string;

    @Expose()
    public lastName1!: string;

    @Expose()
    public firstName2!: string;

    @Expose()
    public lastName2!: string;

    @Expose()
    public dateOfBirth!: Date;

    @Expose()
    public genderId!: number;

    @Expose()
    public descGender!: string;

    @Expose()
    public phone!: string;

    @Expose()
    public email!: string;

    @Expose()
    public descAddress!: string;

    @Expose()
    public address!: string;

    @Expose()
    public username!: string;

    @Expose()
    public homeId!: number;

    @Expose()
    public userStatusId!: number;

    @Expose()
    public notVisibleInStart!: boolean;

    @Expose()
    @Type(() => CoreRoleUser)
    public roleUsers!: CoreRoleUser[];

    @Expose()
    @Type(() => CoreGender)
    public gender!: CoreGender;

    @Expose()
    @Type(() => CoreUserStatus)
    public userStatus!: CoreUserStatus;

    public get fullName(): string {
        return `${this.firstName1} ${this.lastName1}`;
    }

    constructor(payload?: Partial<CoreTeacher>) {
        super();

        return plainToClass(CoreTeacher, payload, { excludeExtraneousValues: true });
    }
}

