import { Injectable } from '@angular/core';
import { COMMON_FIELDS } from '@common/constants';
import {
    CorePeriod,
    CustomSheelonStudent,
    DisplayType, EvalAttendance, EvalComponent, EvalRange, EvalStatement,
    EvalTemplate, EventRelation, EventType, MainSheelon, Mapping, MappingAnswer, MappingLink,
    MappingQuestion, CoreClass, Question, Sheelon, Student, StudentEvent, StudentScoreSheelon, CoreSubject, CoreStudent, MappingRange, EvalStatsSetting,
} from '@common/entities';
import { EvalEvent } from '@common/entities/EvalEvent.entity';
import {
    EventScoreNodeTree, ICertificateBody, IClass, IDocxKeyRow, IEvalCustomSubject,
    IFindPaginateOptions, IGeneralSelect, IScoreRange, IScoreRangeBody, IStudentCertificateResult, IStudentScoreResult, IStudentsEvents, ISubject,
} from '@common/interfaces';
import * as _ from 'lodash';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import { HelperService } from '../helpers/helper.service';

export interface IEvalApiService {
    getEventTypes(filter: IFindPaginateOptions): Promise<EventType[]>;
    upsertEventTypes(eventTypes: EventType[]): Promise<EventType[]>;
    deleteEventTypesByIds(ids: number[]): Promise<any>;
    getComponents(filter: IFindPaginateOptions): Promise<EvalComponent[]>;
    upsertComponents(components: EvalComponent[]): Promise<EvalComponent[]>;
    getSubjectByComponents(filter: IFindPaginateOptions): Promise<CoreSubject[]>;
    deleteComponentByIds(ids: number[]): Promise<any[]>;
    getEvalRangesByFilter(filter: IFindPaginateOptions): Promise<EvalRange[]>;
    upsertEvalRanges(evalRanges: EvalRange[]): Promise<EvalRange[]>;
    deleteEvalRangesByIds(ids: number[]): Promise<any>;
    getEvalStatementsByFilter(filter: IFindPaginateOptions): Promise<EvalStatement[]>;
    upsertEvalStatements(evalStatements: EvalStatement[]): Promise<EvalStatement[]>;
    deleteEvalStatementsByIds(ids: number[]): Promise<any>;
    getEvalEventsByFilter(filter: IFindPaginateOptions): Promise<EvalEvent[]>;
    getEvalEventWithChildrenById(id: number): Promise<IStudentsEvents>;
    getCustomEvalEventsByFilter(filter: IFindPaginateOptions): Promise<EvalEvent[]>;
    softDeleteEvalEventsByFilter(filter: IFindPaginateOptions): Promise<any>;
    softDeleteEvalEventsWithStudentsEventsByFilter(ids: number[]): Promise<any>;
    getEventWithStudentsEvents(groupId: number, eventId: number): Promise<EvalEvent>;
    createEventsWithChildrenByTree(
        groupIds: number[],
        tree?: EventScoreNodeTree,
    ): Promise<{ status: string, errGroups: { groupId: number, groupName: string }[] }>;
    upsertEvalEvents(evalStatements: EvalEvent[]): Promise<EvalEvent[]>;
    upsertEvalEventsAndUpdateScores(events: EvalEvent[]): Promise<EvalEvent[]>;
    deleteEvalEventsByIds(ids: number[]): Promise<any>;
    upsertStudentsEvents(studentsEvents: Partial<StudentEvent>[]): Promise<StudentEvent[]>;
    upsertEventsRelationsAndCalculateScores(eventsRelations: EventRelation[]): Promise<EventRelation[]>;
    upsertEventsRelations(eventsRelations: EventRelation[]): Promise<EventRelation[]>;
    getEventEngagementStatusStatusReport(
        body: {
            eventTypes: EventType[];
            period: Partial<CorePeriod>;
            classes: CoreClass[];
            subjects: CoreSubject[];
        }
    ): Promise<EvalEvent[]>;
    getEventsGlobalReport(body: { eventTypes: EventType[], periods: CorePeriod[], classes: CoreClass[], subjects: ISubject[] }): Promise<any>;
    getStudentsWithAttendanceByFilter(
        body: { grade: number, gradeSection: number, periodId: number, eventTypeId: number }
    ): Promise<CoreStudent[]>;
    upsertEvalAttendances(evalAttendances: EvalAttendance[]): Promise<EvalAttendance[]>;

    getDisplayTypes(filter: IFindPaginateOptions): Promise<DisplayType[]>;
    upsertDisplayTypes(DisplayTypes: DisplayType[]): Promise<DisplayType[]>;
    getSubjectByDisplayTypes(filter: IFindPaginateOptions): Promise<ISubject[]>;
    deleteDisplayTypeByIds(ids: number[]): Promise<any[]>;

    getSubjectsWithComponentsByFilter(filter?: IFindPaginateOptions): Promise<IEvalCustomSubject[]>;
}

@Injectable({
    providedIn: 'root',
})
export class EvalApiService extends CommonApiService implements IEvalApiService, ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'eval', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 EventTypes                                 */
    /* -------------------------------------------------------------------------- */
    public async getEventTypes(filter: IFindPaginateOptions): Promise<EventType[]> {
        return this.extraApiService.request(EventType, 'post', '/eval/events/types/by-filter', filter);
    }

    public async upsertEventTypes(eventTypes: EventType[]): Promise<EventType[]> {
        const body = eventTypes.map(item => {
            return _.pick(item, [...COMMON_FIELDS, 'title', 'status', 'id']);
        });

        return this.extraApiService.request(EventType, 'post', '/eval/events/types/upsert', body);
    }

    public async deleteEventTypesByIds(ids: number[]): Promise<any> {
        if (ids.length === 0) {
            return;
        }

        return this.extraApiService.delete(`/eval/events/types/delete/${ids.join(',')}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Components                                 */
    /* -------------------------------------------------------------------------- */
    public async getComponents(filter: IFindPaginateOptions): Promise<EvalComponent[]> {
        return this.extraApiService.request(EvalComponent, 'post', '/eval/settings/components/by-filter', filter);
    }

    public async upsertComponents(components: EvalComponent[]): Promise<EvalComponent[]> {
        const body = components.map(item => {
            return _.pick(item, [...COMMON_FIELDS, 'title', 'status', 'id', 'subjectId']);
        });

        return this.extraApiService.request(EvalComponent, 'post', '/eval/settings/components/upsert', body);
    }

    public async getSubjectByComponents(filter: IFindPaginateOptions): Promise<CoreSubject[]> {
        return this.extraApiService.request<CoreSubject[]>(CoreSubject, 'post', '/eval/settings/components/subjects/by-filter', filter);
    }

    public async getSubjectsWithComponentsByFilter(filter: IFindPaginateOptions = { filter: { } }): Promise<IEvalCustomSubject[]> {
        return this.extraApiService.post('/eval/settings/components/subjects-with-components', filter).then(res => {
            return res;
        });
    }

    public async deleteComponentByIds(ids: number[]): Promise<any[]> {
        return this.extraApiService.delete(`/eval/settings/components/delete/${ids.join(',')}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                                Display Type                                */
    /* -------------------------------------------------------------------------- */
    public async getDisplayTypes(filter: IFindPaginateOptions): Promise<DisplayType[]> {
        return this.extraApiService.request(DisplayType, 'post', '/eval/settings/display-types/by-filter', filter);
    }

    public async upsertDisplayTypes(DisplayTypes: DisplayType[]): Promise<DisplayType[]> {
        const body = DisplayTypes.map(item => {
            return _.pick(item, [...COMMON_FIELDS, 'title', 'status', 'id', 'subjectId']);
        });

        return this.extraApiService.request(DisplayType, 'post', '/eval/settings/display-types/upsert', body);
    }

    public async getSubjectByDisplayTypes(filter: IFindPaginateOptions): Promise<ISubject[]> {
        return this.extraApiService.post('/eval/settings/display-types/subjects/by-filter', filter);
    }

    public async deleteDisplayTypeByIds(ids: number[]): Promise<any[]> {
        return this.extraApiService.delete(`/eval/settings/display-types/delete/${ids.join(',')}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                             Eval Ranges Routes                             */
    /* -------------------------------------------------------------------------- */
    public async getEvalRangesByFilter(filter?: IFindPaginateOptions): Promise<EvalRange[]> {
        return this.extraApiService.request(EvalRange, 'post', '/eval/settings/ranges/by-filter', filter);
    }

    public async upsertEvalRanges(evalRanges: EvalRange[]): Promise<EvalRange[]> {
        const body = evalRanges.map(item => {
            return _.pick(item, [...COMMON_FIELDS, 'id', 'name', 'meta']);
        });

        return this.extraApiService.request(EvalRange, 'post', '/eval/settings/ranges/upsert', body);
    }

    public async deleteEvalRangesByIds(ids: number[]): Promise<any> {
        return this.extraApiService.delete(`/eval/settings/ranges/delete/${ids.join(',')}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                             Eval Statements Routes                             */
    /* -------------------------------------------------------------------------- */
    public async getEvalStatementsByFilter(filter: IFindPaginateOptions): Promise<EvalStatement[]> {
        return this.extraApiService.request(EvalStatement, 'post', '/eval/settings/statements/by-filter', filter);
    }

    public async upsertEvalStatements(evalStatements: EvalStatement[]): Promise<EvalStatement[]> {
        const body = evalStatements.map(item => {
            return _.pick(item, [...COMMON_FIELDS, 'id', 'title', 'titleMin1', 'titleMin2', 'subjectId', 'layerIds', 'status']);
        });

        return this.extraApiService.request(EvalStatement, 'post', '/eval/settings/statements/upsert', body);
    }

    public async deleteEvalStatementsByIds(ids: number[]): Promise<any> {
        return this.extraApiService.delete(`/eval/settings/statements/delete/${ids.join(',')}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                             Event Routes                             */
    /* -------------------------------------------------------------------------- */
    public async getEvalEventsByFilter(filter: IFindPaginateOptions): Promise<EvalEvent[]> {
        return this.extraApiService.request(EvalEvent, 'post', '/eval/events/by-filter', filter);
    }

    public async getEvalEventWithChildrenById(id: number): Promise<IStudentsEvents> {
        return this.extraApiService.get(`/eval/events/get-event-with-children/${id}`);
    }

    public async getCustomEvalEventsByFilter(filter: IFindPaginateOptions): Promise<EvalEvent[]> {
        return this.extraApiService.request(EvalEvent, 'post', '/eval/events/custom/by-filter', filter);
    }

    public async softDeleteEvalEventsByFilter(filter: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post('/eval/events/soft-delete', filter);
    }

    public async softDeleteEvalEventsWithStudentsEventsByFilter(ids: number[]): Promise<any> {
        return this.extraApiService.delete(`/eval/events/soft-delete-with-students-events/${ids.join(',')}`);
    }

    public async getEventWithStudentsEvents(groupId: number, eventId: number): Promise<EvalEvent> {
        return this.extraApiService.request(EvalEvent, 'post', `/eval/events/get-event-with-students-events/${groupId}/${eventId}`);
    }

    public async createEventsWithChildrenByTree(
        groupIds: number[],
        tree?: EventScoreNodeTree,
    ): Promise<{ status: string, errGroups: { groupId: number, groupName: string }[] }> {
        return this.extraApiService.post(`/eval/events/create-events-with-children-by-tree`, { groupIds, tree });
    }

    public async upsertEvalEvents(evalStatements: EvalEvent[]): Promise<EvalEvent[]> {
        const body = evalStatements.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'title',
                'subjectName',
                'groupId',
                'eventTypeId',
                'subjectId',
                'periodId',
                'score',
                'display',
                'componentId',
                'capability',
                'status',
                'date',
            ]);
        });

        return this.extraApiService.request(EvalEvent, 'post', '/eval/events/upsert', body);
    }

    public async upsertEvalEventsAndUpdateScores(events: EvalEvent[]): Promise<EvalEvent[]> {
        const body = events.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'title',
                'subjectName',
                'groupId',
                'eventTypeId',
                'subjectId',
                'periodId',
                'score',
                'display',
                'componentId',
                'capability',
                'status',
            ]);
        });

        return this.extraApiService.request(EvalEvent, 'post', '/eval/events/upsert-and-update-scores', body);
    }

    public async deleteEvalEventsByIds(ids: number[]): Promise<any> {
        return this.extraApiService.delete(`/eval/events/delete/${ids.join(',')}`);
    }

    public async deleteStudentsEvents(filter: IFindPaginateOptions): Promise<any> {
        return this.extraApiService.post(`/eval/events/student-events/delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                Student Evens                               */
    /* -------------------------------------------------------------------------- */
    public async upsertStudentsEvents(studentsEvents: Partial<StudentEvent>[]): Promise<StudentEvent[]> {
        const body = studentsEvents.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'eventId',
                'studentId',
                'groupId',
                'subjectId',
                'classId',
                'periodId',
                'eventTypeId',
                'componentId',
                'eventScore',
                'percentOfParent',
                'score',
                'statementId',
                'statementIds',
                'note',
                'teacherId',
            ]);
        });

        return this.extraApiService.request(StudentEvent, 'post', '/eval/events/student-events/upsert', body);
    }

    public async getCustomStudentEventsByFilter(filter: {
        classes?: CoreClass[];
        subjectIds?: number[];
        periodId?: number;
    }): Promise<StudentEvent[]> {
        return this.extraApiService.request<StudentEvent[]>(StudentEvent, 'post', '/eval/events/student-events/with-data', filter);
    }

    public async getStudentEventsScores(body: object): Promise<IStudentScoreResult> {
        return this.extraApiService.post(`/eval/events/get-student-events-scores`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                              Events Relations                              */
    /* -------------------------------------------------------------------------- */
    public async upsertEventsRelationsAndCalculateScores(eventsRelations: EventRelation[]): Promise<EventRelation[]> {
        const body = eventsRelations.map(item => {
            return _.pick(item, [
                'parentId',
                'childId',
                'percentOfParent',
            ]);
        });

        return this.extraApiService.request(EventRelation, 'post', '/eval/events/events-relations/calculate-score-and-upsert', body);
    }

    public async upsertEventsRelations(eventsRelations: EventRelation[]): Promise<EventRelation[]> {
        const body = eventsRelations.map(item => {
            return _.pick(item, [
                'parentId',
                'childId',
                'percentOfParent',
            ]);
        });

        return this.extraApiService.request(EventRelation, 'post', '/eval/events/events-relations/upsert', body);
    }

    public async deleteEventRelationsByQuery(filter: IFindPaginateOptions): Promise<EventRelation[]> {
        return this.extraApiService.request(EventRelation, 'post', '/eval/events/events-relations/delete', filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Reports                                  */
    /* -------------------------------------------------------------------------- */
    public async getEventEngagementStatusStatusReport(
        body: {
            eventTypes: EventType[];
            period: Partial<CorePeriod>;
            classes: CoreClass[];
            subjects: CoreSubject[];
        },
    ): Promise<EvalEvent[]> {
        const fullBody = {
            eventTypes: body.eventTypes.map(item => {
                return _.pick(item, [
                    ...COMMON_FIELDS,
                    'id',
                    'title',
                    'status',
                ]);
            }),
            subjects: body.subjects.map(item => {
                return _.pick(item, [
                    'subjectId',
                    'subjectName1',
                    'subjectName2',
                    'order',
                ]);
            }),
            classes: body.classes.map(item => {
                return _.pick(item, [
                    'classId',
                    'grade',
                    'gradeSection',
                    'name1',
                ]);
            }),
            period: _.pick(body.period, [
                'periodId',
                'desc',
                'startDate',
                'endDate',
                'status',
            ]),
        };

        return this.extraApiService.request(EvalEvent, 'post', '/eval/reports/events/get-engagement-status-report', fullBody);
    }

    public async getEventsGlobalReport(
        body: {
            eventTypes: EventType[];
            periods: CorePeriod[];
            classes: CoreClass[];
            subjects: ISubject[];
        },
    ): Promise<any> {
        const fullBody = {
            eventTypes: body.eventTypes.map(item => {
                return _.pick(item, [...COMMON_FIELDS, 'id', 'title', 'status']);
            }),
            subjects: body.subjects.map(item => {
                return _.pick(item, [
                    'subjectId',
                    'subjectName1',
                    'subjectName2',
                    'order',
                ]);
            }),
            classes: body.classes.map(item => {
                return _.pick(item, [
                    'classId',
                    'grade',
                    'gradeSection',
                    'name1',
                ]);
            }),
            periods: body.periods.map(item => {
                return _.pick(item, [
                    'periodId',
                    'desc',
                    'startDate',
                    'endDate',
                    'status',
                ]);
            }),
        };

        return this.extraApiService.post('/eval/reports/events/get-global-report', fullBody);
    }

    public async getEventsGlobalReportXLSX(
        body: {
            eventTypes: EventType[];
            periods: CorePeriod[];
            classes: CoreClass[];
            subjects: ISubject[];
        },
    ): Promise<any> {
        const fullBody = {
            eventTypes: body.eventTypes.map(item => {
                return _.pick(item, [...COMMON_FIELDS, 'id', 'title', 'status']);
            }),
            subjects: body.subjects.map(item => {
                return _.pick(item, [
                    'subjectId',
                    'subjectName1',
                    'subjectName2',
                    'order',
                ]);
            }),
            classes: body.classes.map(item => {
                return _.pick(item, [
                    'classId',
                    'grade',
                    'gradeSection',
                    'name1',
                ]);
            }),
            periods: body.periods.map(item => {
                return _.pick(item, [
                    'periodId',
                    'desc',
                    'startDate',
                    'endDate',
                    'status',
                ]);
            }),
        };

        return this.extraApiService.postFile('/eval/reports/events/get-global-report-xlsx', fullBody);
    }

    public async getEventsGlobalReportXLSXPart2(
        body: {
            eventTypes: EventType[];
            periods: CorePeriod[];
            classes: CoreClass[];
            subjects: ISubject[];
        },
    ): Promise<any> {
        const fullBody = {
            eventTypes: body.eventTypes.map(item => {
                return _.pick(item, [...COMMON_FIELDS, 'id', 'title', 'status']);
            }),
            subjects: body.subjects.map(item => {
                return _.pick(item, [
                    'subjectId',
                    'subjectName1',
                    'subjectName2',
                    'order',
                ]);
            }),
            classes: body.classes.map(item => {
                return _.pick(item, [
                    'classId',
                    'grade',
                    'gradeSection',
                    'name1',
                ]);
            }),
            periods: body.periods.map(item => {
                return _.pick(item, [
                    'periodId',
                    'desc',
                    'startDate',
                    'endDate',
                    'status',
                ]);
            }),
        };

        return this.extraApiService.postFile('/eval/reports/events/get-global-report-xlsx-part-2', fullBody);
    }

    public async getStudentsCertificates(body: ICertificateBody): Promise<IStudentCertificateResult> {
        return this.extraApiService.post('/eval/reports/students-certificates', body);

    }

    public async generateEventScoreRangeReport(body: IScoreRangeBody): Promise<IScoreRange[]> {
        return this.extraApiService.post('/eval/reports/events/generate-score-range-report', body);

    }

    public async getCertificateKeys(body: { periods: IGeneralSelect[] }): Promise<IDocxKeyRow[]> {
        return this.extraApiService.post('/eval/reports/certificate-keys', body);

    }

    public async getStudentsCertificatesPdf(content: string): Promise<any> {
        return this.extraApiService.postFile('/util/generator/html-to-pdf', { content });

    }

    public async getGuideReportXlsx(filter: { templateId: number, classes: Partial<CoreClass>[] }): Promise<any> {
        return this.extraApiService.postFile('/eval/reports/guide-report-xlsx', filter);
    }

    public async getGuideReportXlsxPart2(filter: { templateId: number, classes: Partial<CoreClass>[] }): Promise<any> {
        return this.extraApiService.postFile('/eval/reports/guide-report-xlsx-part-2', filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                              Eval Attendances                              */
    /* -------------------------------------------------------------------------- */
    public async getStudentsWithAttendanceByFilter(
        body: { grade: number, gradeSection: number, periodId: number, eventTypeId: number },
    ): Promise<CoreStudent[]> {
        return this.extraApiService.request<CoreStudent[]>(CoreStudent, 'post', `/eval/eval-attendances/get-students-with-attendance`, body);
    }

    public async upsertEvalAttendances(evalAttendances: EvalAttendance[]): Promise<EvalAttendance[]> {
        const body = evalAttendances.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'studentId',
                'periodId',
                'eventTypeId',
                'status',
                'statementsIds',
                'note',
                'managerStatementsIds',
                'managerNote',
            ]);
        });

        return this.extraApiService.request(EvalAttendance, 'post', '/eval/eval-attendances/upsert', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Templates                                 */
    /* -------------------------------------------------------------------------- */
    public async getEvalTemplates(filter: IFindPaginateOptions): Promise<EvalTemplate[]> {
        return this.extraApiService.request(EvalTemplate, 'post', '/eval/templates/by-filter', filter);
    }

    public async upsertEvalTemplates(eventTypes: EvalTemplate[]): Promise<EvalTemplate[]> {
        const body = eventTypes.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'name',
                'meta',
            ]);
        });

        return this.extraApiService.request(EvalTemplate, 'post', '/eval/templates/upsert', body);
    }

    public async cloneEvalTemplatesById(id: number): Promise<EvalTemplate[]> {
        return this.extraApiService.request(EvalTemplate, 'post', `/eval/templates/clone/${id}`);
    }

    public async deleteEvalTemplatesById(id: number): Promise<EvalTemplate[]> {
        return this.extraApiService.request(EvalTemplate, 'post', `/eval/templates/delete/${id}`);
    }

    /* -------------------------------------------------------------------------- */
    /*                                     FTP                                    */
    /* -------------------------------------------------------------------------- */
    public async getListFilesNamesByFilter(body: { list_files: string, regex?: string }): Promise<string[]> {
        return this.extraApiService.post(`/eval/ftp/get-available-files`, body);
    }

    public async getFileByName(body: { get_file: string }): Promise<any> {
        return this.extraApiService.postFile(`/eval/ftp/get-file-by-name`, body);
    }

    public async migrateFileToDatabase(body: { get_file: string, model_name: string }): Promise<any> {
        return this.extraApiService.postFile(`/eval/ftp/migrate-file-to-database`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Sheelon                                  */
    /* -------------------------------------------------------------------------- */
    public async getSheelonByFilter(filter: IFindPaginateOptions): Promise<Sheelon[]> {
        return this.extraApiService.request(Sheelon, 'post', '/eval/sheelons/by-filter', filter);
    }

    public async getSheelonStudentsByFilter(filter: IFindPaginateOptions): Promise<CustomSheelonStudent[]> {
        return this.extraApiService.request(CustomSheelonStudent, 'post', '/eval/sheelons/students/by-filter', filter);
    }

    public async getMainSheelonsByFilter(filter: IFindPaginateOptions): Promise<MainSheelon[]> {
        return this.extraApiService.request(MainSheelon, 'post', '/eval/sheelons/main/by-filter', filter);
    }

    public async upsertStudentScoreSheelon(sheelons: StudentScoreSheelon[]): Promise<StudentScoreSheelon[]> {
        const body = sheelons.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'semel_mosad',
                'shnat_limudim',
                'code_moed_bchina',
                'semel_sheelon',
                'mispar_zehut_nivchan',
                'semel_sheelon_rashi',
                'tziun_shnaty_latalmid',
                'sug_zehut_nivchan',
                'code_mishtamesh_yotser',
                'haim_nigash_lebhina',
                'mispar_zehut_measher',
                'sug_zehut_measher',
                'mispar_zehut_sholeach',
                'sug_zehut_sholeach',
                'haim_nivchan_mishnee',
                'shem_prati',
                'shem_mishpacha',
                'taarich_leida_nivchan_mishnee',
                'code_min_nivchan_mishnee',
            ]);
        });

        return this.extraApiService.post(`/eval/sheelons/student-score/upsert`, body);
    }

    public async confirmationStudentScoreSheelon(sheelons: StudentScoreSheelon[]): Promise<StudentScoreSheelon[]> {
        const body = sheelons.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'semel_mosad',
                'shnat_limudim',
                'code_moed_bchina',
                'semel_sheelon',
                'mispar_zehut_nivchan',
            ]);
        });

        return this.extraApiService.post(`/eval/sheelons/student-score/scores-confirmation`, body);
    }

    public async ministryConfirmationStudentScoreSheelon(sheelons: StudentScoreSheelon[]): Promise<StudentScoreSheelon[]> {
        const body = sheelons.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'semel_mosad',
                'shnat_limudim',
                'code_moed_bchina',
                'semel_sheelon',
                'mispar_zehut_nivchan',
            ]);
        });

        return this.extraApiService.post(`/eval/sheelons/student-score/ministry-confirmation`, body);
    }

    public async exportCsvAndSaveInAWS3(body: { data: any[], learn_year: string, semel_mosad: string }): Promise<any> {
        return this.extraApiService.post(`/eval/sheelons/convert-to-csv-and-save`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Mapping                                  */
    /* -------------------------------------------------------------------------- */
    public async getMappingByFilter(filter: IFindPaginateOptions): Promise<Mapping[]> {
        return this.extraApiService.request(Mapping, 'post', '/eval/mappings/by-filter', filter);
    }

    public async getMappingInsights(body: any): Promise<any> {
        return this.extraApiService.post('/eval/mappings/get-mapping-insights', body);
    }

    public async generateMappingInsightsXlsx(body: any): Promise<any> {
        return this.extraApiService.postFile('/eval/mappings/generate-mapping-insights-xlsx', body);
    }

    public async upsertMappings(mappings: Mapping[]): Promise<StudentScoreSheelon[]> {
        const body = mappings.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'title',
                'status',
                'description',
                'gradeId',
                'gardeSectionId',
                'subjectId',
            ]);
        });

        return this.extraApiService.post(`/eval/mappings/upsert`, body);
    }

    public async softDeleteMappingsByIds(ids: number[]): Promise<any> {
        return this.extraApiService.post(`/eval/mappings/soft-delete/${ids.join(',')}`);
    }

    public async getMappingWithSection(id: number): Promise<Mapping> {
        return this.extraApiService.request(Mapping, 'post', `/eval/mappings/get-mapping-with-section/${id}`);
    }

    public async upsertQuestions(questions: Question[]): Promise<Question[]> {
        const body = questions.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'title',
                'programSectionId',
            ]);
        });

        return this.extraApiService.post(`/eval/mappings/questions/upsert`, body);
    }

    public async upsertMappingQuestions(mappingQuestions: MappingQuestion[]): Promise<MappingQuestion[]> {
        const body = mappingQuestions.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'mappingId',
                'questionId',
                'score',
                'order',
            ]);
        });

        return this.extraApiService.post(`/eval/mappings/mapping-questions/upsert`, body);
    }

    public async softDeleteMappingQuestionsByIds(mappingIds: number[], questionIds: number[]): Promise<any> {
        return this.extraApiService.post(`/eval/mappings/mapping-questions/soft-delete/${mappingIds.join(',')}/${questionIds.join(',')}`);
    }

    public async getMappingLinkByFilter(filter: IFindPaginateOptions): Promise<MappingLink[]> {
        return this.extraApiService.request(MappingLink, 'post', '/eval/mappings/mapping-links/by-filter', filter);
    }

    public async getMappingAnswersByFilter(filter: IFindPaginateOptions): Promise<MappingAnswer[]> {
        return this.extraApiService.request(MappingAnswer, 'post', '/eval/mappings/mapping-answers/by-filter', filter);
    }

    public async getMappingRangesByFilter(filter?: IFindPaginateOptions): Promise<MappingRange[]> {
        return this.extraApiService.request(MappingRange, 'post', '/eval/mappings/mapping-ranges/by-filter', filter);
    }

    public async upsertMappingLinks(mappingLinks: MappingLink[]): Promise<MappingLink[]> {
        const body = mappingLinks.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'item',
                'itemId',
                'mappingId',
                'gradeId',
                'gardeSectionId',
                'groupId',
            ]);
        });

        return this.extraApiService.post(`/eval/mappings/mapping-links/upsert`, body);
    }

    public async upsertMappingAnswers(mappingAnswers: MappingAnswer[]): Promise<MappingAnswer[]> {
        const body = mappingAnswers.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'mappingId',
                'questionId',
                'studentId',
                'mappingLinkId',
                'gradeId',
                'gardeSectionId',
                'score',
            ]);
        });

        return this.extraApiService.post(`/eval/mappings/mapping-answers/upsert`, body);
    }

    /* -------------------------------------------------------------------------- */
    /*                            EvalStatsSetting Apis                           */
    /* -------------------------------------------------------------------------- */
    public async getEvalStatsSettingByFilter(filter: IFindPaginateOptions): Promise<EvalStatsSetting[]> {
        return this.extraApiService.request(EvalStatsSetting, 'post', '/eval/settings/stats-settings/by-filter', filter);
    }

    public async upsertEvalStatsSettings(models: EvalStatsSetting[]): Promise<EvalStatsSetting[]> {
        return this.extraApiService.post(`/eval/settings/stats-settings/upsert`, models);
    }
}
