import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseColumnWithClassIdSchemaPart } from './BaseColumnWithClassIdSchemaPart';
import { CoreClass } from './CoreClass.entity';
import { CoreRole } from './CoreRole.entity';
import { CoreTeacher } from './CoreTeacher.entity';
import { CoreSubject } from './CoreSubject.entity';

export class CoreRoleUser extends BaseColumnWithClassIdSchemaPart {
    @Expose()
    public roleId!: number;

    @Expose()
    public userId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public status!: number;

    @Expose()
    @Type(() => CoreClass)
    public class!: CoreClass;

    @Expose()
    @Type(() => CoreRole)
    public role!: CoreRole;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    @Type(() => CoreSubject)
    public subject!: CoreSubject;

    constructor(payload?: Partial<CoreRoleUser>) {
        super();

        return plainToClass(CoreRoleUser, payload, { excludeExtraneousValues: true });
    }
}
