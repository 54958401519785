import { Injectable } from '@angular/core';
import { IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { PayPaymentType } from '@common/entities/pay/PayPaymentTypes.entity';
import { PayInvoices } from '@common/entities/pay/PayInvoices.entity';
import { PayPayments } from '@common/entities/pay/PayPayments.entity';
import { PayStudentPayments } from '@common/entities/pay/PayStudentPayments.entity';
import { PayStudentPaymentsInvoices } from '@common/entities/pay/PayStudentPaymentsInvoices.entity';
import { Relationship } from '@common/entities';
import { PayBank } from '@common/entities/pay/PayBank.entity';
import { PaySettings } from '@common/entities/pay/PaySettings.entity';




// export interface IFormApiService { }

@Injectable({
    providedIn: 'root',
})
export class PayApiService extends CommonApiService implements  ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'pay', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Pay Type Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getPayPaymentType(body: IFindPaginateOptions): Promise<PayPaymentType[]> {
        return await this.extraApiService.post('/pay/payments/payment-types/by-filter', body);
    }

    async upsertPayPaymentType(body: any): Promise<PayPaymentType[]> {
        return await this.extraApiService.post('/pay/payments/payment-types/upsert', body);
    }

    async softDeletePayPaymentType(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/payment-types/soft-delete', body);
    }

    async deletePayPaymentType(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/payment-types/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Payment Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getPayments(body: IFindPaginateOptions): Promise<PayPayments[]> {
        return await this.extraApiService.post('/pay/payments/by-filter', body);
    }

    async upsertPayment(body: any): Promise<PayPayments[]> {
        return await this.extraApiService.post('/pay/payments/upsert', body);
    }

    async softDeletePayment(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/soft-delete', body);
    }

    async deletePayment(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Invoices Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getInvoices(body: IFindPaginateOptions): Promise<PayInvoices[]> {
        return await this.extraApiService.post('/pay/payments/invoices/by-filter', body);
    }

    async upsertInvoices(body: any): Promise<PayInvoices[]> {
        return await this.extraApiService.post('/pay/payments/invoices/upsert', body);
    }

    async softDeleteInvoice(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/invoices/soft-delete', body);
    }

    async deleteInvoice(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/invoices/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Student Payments Apis                      */
    /* -------------------------------------------------------------------------- */
    async getStudentPayments(body: IFindPaginateOptions): Promise<PayStudentPayments[]> {
        return await this.extraApiService.post('/pay/payments/student-payments/by-filter', body);
    }

    async upsertStudentPayments(body: any): Promise<PayStudentPayments[]> {
        return await this.extraApiService.post('/pay/payments/student-payments/upsert', body);
    }

    async softDeleteStudentPayment(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/student-payments/soft-delete', body);
    }

    async deleteStudentPayment(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/student-payments/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                           Student Payments Invoices Apis                   */
    /* -------------------------------------------------------------------------- */
    async getStudentPaymentsInvoices(body: IFindPaginateOptions): Promise<PayStudentPaymentsInvoices[]> {
        return await this.extraApiService.request(PayStudentPaymentsInvoices, 'post', '/pay/payments/student-payments-invoices/by-filter', body);
    }

    async upsertStudentPaymentsInvoices(body: any): Promise<PayStudentPaymentsInvoices[]> {
        return await this.extraApiService.post('/pay/payments/student-payments-invoices/upsert', body);
    }

    async softDeleteStudentPaymentInvoices(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/student-payments-invoices/soft-delete', body);
    }

    async deleteStudentPaymentInvoices(body: IFindPaginateOptions): Promise<any> {
        return await this.extraApiService.post('/pay/payments/student-payments-invoices/delete', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Banks Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getBanks(body: IFindPaginateOptions): Promise<PayBank[]> {
        return await this.extraApiService.post('/pay/payments/banks/by-filter', body);
    }

    async upsertBanks(body: any): Promise<PayBank[]> {
        return await this.extraApiService.post('/pay/payments/banks/upsert', body);
    }

    async softDeleteBank(body: any): Promise<any> {
        return await this.extraApiService.post('/pay/payments/banks/soft-delete', body);
    }

    async deleteBank(body: any): Promise<any> {
        return await this.extraApiService.post('/pay/payments/banks/delete', body);
    }


    /* -------------------------------------------------------------------------- */
    /*                                 Settings Apis                                 */
    /* -------------------------------------------------------------------------- */
    async getSettings(body: IFindPaginateOptions): Promise<PaySettings[]> {
        return await this.extraApiService.post('/pay/payments/payment-settings/by-filter', body);
    }

    async upsertSettings(body: any): Promise<PaySettings[]> {
        return await this.extraApiService.post('/pay/payments/payment-settings/upsert', body);
    }

    async softDeleteSetting(body: any): Promise<any> {
        return await this.extraApiService.post('/pay/payments/payment-settings/soft-delete', body);
    }

    async deleteSetting(body: any): Promise<any> {
        return await this.extraApiService.post('/pay/payments/payment-settings/delete', body);
    }

    async getRelation(id: number): Promise<Relationship[]> {
        return await this.extraApiService.request(Relationship,'post' ,`/core/cache/get-relationships-of-student/` + id);
    }

    async generateExcel(body: any): Promise<any> {
        return await this.extraApiService.postFile('/util/generator/export-data-as-xlsx', body);
    }

}
