import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreGroup } from '../core/CoreGroup.entity';
import * as moment from 'moment';

export class ServeWeekPlans extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public groupId!: number;

    @Expose()
    @Type(() => Number)
    public grade!: number;

    @Expose()
    @Type(() => Number)
    public gradeSection!: number;

    @Expose()
    @Type(() => Date)
    @Transform( ({ obj }) => moment(obj.startDate).format('YYYY-MM-DD'))
    public startDate!: string;

    @Expose()
    @Type(() => Date)
    @Transform( ({ obj }) => moment(obj.endDate).format('YYYY-MM-DD'))
    public endDate!: string;

    @Expose()
    @Type(() => String)
    public content!: string;

    @Expose()
    @Type(() => String)
    public note!: string;

    @Expose()
    @Type(() => String)
    public classId!: string;

    @Expose()
    @Type(() => CoreGroup)
    public group!: Partial<CoreGroup>;

    constructor(payload?: Partial<ServeWeekPlans>) {
        super();

        return plainToClass(ServeWeekPlans, payload, { excludeExtraneousValues: true });
    }
}
