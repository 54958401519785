
import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreGender extends BaseEntityClass {
    @Expose()
    public desc!: string;

    constructor(payload?: Partial<CoreGender>) {
        super();

        return plainToClass(CoreGender, payload, { excludeExtraneousValues: true });
    }
}
