
import { Expose, plainToClass } from 'class-transformer';

export class CloudApplication {
    @Expose()
    public code!: string;

    @Expose()
    public url!: string;

    @Expose()
    public api_url!: string;

    @Expose()
    public db!: string;

    @Expose()
    public port!: number;

    @Expose()
    public status!: number;

    @Expose()
    public order!: number;

    @Expose()
    public hosts!: string;

    @Expose()
    public latest_update!: string;

    constructor(payload?: Partial<CloudApplication>) {
        return plainToClass(CloudApplication, payload, { excludeExtraneousValues: true });
    }
}
