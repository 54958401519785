import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('@common/components/auth/common-auth.module').then(module => module.CommonAuthModule),
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
