import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Group } from './Group.entity';
import { CoreGroup } from './core/CoreGroup.entity';
import { CoreTeacher } from './core/CoreTeacher.entity';


export class EcountLessonStatus extends BaseEntityClass {
    @Expose()
    public groupId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    @Type(() => Date)
    public date!: Date;

    @Expose()
    public teacherId!: number;

    @Expose()
    public action!: number;

    @Expose()
    @Type(() => CoreGroup)
    public group!: CoreGroup;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    @Expose()
    public approved!: boolean;

    @Expose()
    public approvedDate!: Date;

    constructor(payload: Partial<EcountLessonStatus>) {
        super();

        return plainToClass(EcountLessonStatus, payload, { excludeExtraneousValues: true });
    }
}
