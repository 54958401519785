
import { Expose, Transform, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import * as moment from 'moment';

export class QuestionsExam extends BaseEntityClass {
    @Expose()
    public sectionId!: number;

    @Expose()
    public title!: string;

    @Expose()
    public type!: string;

    @Expose()
    public options!: OptionQuestion[];

    @Expose()
    public level!: number;

    @Expose()
    public note!: string;

    @Expose()
    public image!: string;

    @Expose()
    public video!: string;

    @Expose()
    @Transform(item => moment(item.obj.created_at).format('YYYY-MM-DD'))
    public date!: string;

    score?: number;
    layer?: number;

    constructor(payload?: Partial<QuestionsExam>) {
        super();

        return plainToClass(QuestionsExam, payload, { excludeExtraneousValues: true });
    }
}

export interface QuestionsExamBySectionId {
    [sectionId: number]: QuestionsExam[];
}

export interface OptionQuestion {
    text: string;
    correct: boolean;
}
