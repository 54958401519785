import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'core-progress',
    templateUrl: './core-progress.component.html',
    styleUrls: ['./core-progress.component.scss'],
})
export class CoreProgressComponent implements OnInit {
    @Input()
    public value!: number;

    @Input()
    public valuemin: number = 0;

    @Input()
    public valuemax: number = 100;

    public _value!: number;

    public color!: 'success' | 'info' | 'warning' | 'danger';

    constructor(
    ) { }

    async ngOnInit(): Promise<void> {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._value = _.round(this.value * 100, 0);

        if (this._value < this.valuemax / 3) {
            this.color = 'danger';
        } else if (this._value < (this.valuemax / 3) * 2) {
            this.color = 'warning';
        } else if (this._value < this.valuemax) {
            this.color = 'info';
        } else {
            this.color = 'success';
        }
    }
}
