import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';


export class AttendanceStatus extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public color!: string;

    constructor(payload: Partial<AttendanceStatus>) {
        super();

        return plainToClass(AttendanceStatus, payload, { excludeExtraneousValues: true });
    }
}
