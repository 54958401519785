import { Expose, plainToClass, Type } from 'class-transformer';


export class RelationDetail {
    @Expose()
    public MISPAR_ZEHUT!: number;

    @Expose()
    public TEUR_YISHUV1!: string;

    @Expose()
    public SHEM_RECHOV1!: string;

    @Expose()
    public MISPAR_BAYIT1!: number;

    @Expose()
    public KIDOMET_NAYACH_1!: string;

    @Expose()
    public MISPAR_NAYACH_1!: string;

    @Expose()
    public KIDOMET_NAYAD_1!: string;

    @Expose()
    public MISPAR_NAYAD_1!: string;

    @Expose()
    public EMAIL_1!: string;

    constructor(payload?: Partial<RelationDetail>) {
        return plainToClass(RelationDetail, payload, { excludeExtraneousValues: true });
    }
}
