import { Injectable } from '@angular/core';
import {
    CoreCapability, CoreClass, CoreEduProgram, CoreEduProgramGroup, CoreEduProgramManager, CoreEduProgramOrg,
    CoreGrade, CoreGroup, CoreGroupMember, CoreMinistrySubject, CoreOrg, CorePeriod, CoreRelationship, CoreRelationshipDetail, CoreRelationshipType, CoreRole,
    CoreRoleUser, CoreSchoolProgram, CoreStudent, CoreStudentBus, CoreStudentStatus, CoreSubject, CoreTeacher,
} from '@common/entities';
import { IFTPParams, IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService, IImportResult } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { COMMON_FIELDS } from '@common/constants';
import { MemberType } from '@common/enums';

@Injectable({
    providedIn: 'root',
})
export class CoreApiService extends CommonApiService implements ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'core', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Classes                                  */
    /* -------------------------------------------------------------------------- */
    public async getCoreClassesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreClass[]> {
        loading = true;
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/core/classes/by-filter`, filter).then(res => {
            loading = false;

            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async getCoreClasses(loading?: boolean): Promise<CoreClass[]> {
        loading = true;
        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/core/classes/all`).then(res => {
            loading = false;

            return _.orderBy(res, ['grade', 'gradeSection'], ['asc', 'asc']);
        });
    }

    public async upsertCoreClasses(models: CoreClass[], loading?: boolean): Promise<CoreClass[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'grade',
                'gradeSection',
                'name1',
                'name2',
                'classType',
                'classTypeDesc',
                'minStudents',
                'maxStudents',
                'status',
                'statusDesc',
                'location',
                'address',
            ]);
        });

        return this.extraApiService.request<CoreClass[]>(CoreClass, 'post', `/core/classes/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreClassesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreGrade[]> {
        loading = true;

        return this.extraApiService.request<CoreGrade[]>(CoreGrade, 'post', `/core/classes/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Grade Api                                 */
    /* -------------------------------------------------------------------------- */
    public async getCoreGradesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreGrade[]> {
        loading = true;

        return this.extraApiService.request<CoreGrade[]>(CoreGrade, 'post', `/core/grades/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreGradesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreGrade[]> {
        loading = true;

        return this.extraApiService.request<CoreGrade[]>(CoreGrade, 'post', `/core/grades/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreGradesByFilter(models: CoreGrade[], loading?: boolean): Promise<CoreGrade[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'grade',
                'name1',
                'name2',
            ]);
        });

        return this.extraApiService.request<CoreGrade[]>(CoreGrade, 'post', `/core/grades/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                             CoreCapability Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getCoreCapabilitiesByFilter(loading?: boolean): Promise<CoreCapability[]> {
        loading = true;

        return this.extraApiService.request<CoreCapability[]>(CoreCapability, 'post', `/core/settings/capabilities/all`).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreCapabilitiesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreCapability[]> {
        loading = true;

        return this.extraApiService.request<CoreCapability[]>(CoreCapability, 'post', `/core/settings/capabilities/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreCapability(model: CoreCapability, loading?: boolean): Promise<CoreCapability> {
        loading = true;

        const body = _.pick(model, [
            'id',
            'capability',
            'appCode',
            'description',
            'category',
        ]);

        return this.extraApiService.request<CoreCapability>(CoreCapability, 'post', `/core/settings/capabilities/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteOneCoreCapabilityById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/settings/capabilities/delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                           CoreStudentStatus Apis                           */
    /* -------------------------------------------------------------------------- */
    public async getAllCoreStudentStatuses(loading?: boolean): Promise<CoreStudentStatus[]> {
        loading = true;

        return this.extraApiService.request<CoreStudentStatus[]>(CoreStudentStatus, 'post', `/core/settings/student-statues/all`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                             CoreStudentBus Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getAllCoreStudentBuses(filter?: IFindPaginateOptions, loading?: boolean): Promise<CoreStudentBus[]> {
        loading = true;

        return this.extraApiService.request<CoreStudentBus[]>(CoreStudentBus, 'post', `/core/settings/student-buses/all`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreStudentBus(model: CoreStudentBus, loading?: boolean): Promise<CoreStudentBus> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'id',
            'busName',
            'companyName',
            'busRoute',
            'note',
        ]);

        return this.extraApiService.request<CoreStudentBus>(CoreStudentBus, 'post', `/core/settings/student-buses/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async softDeleteOneCoreStudentBusById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/settings/student-buses/soft-delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                               CorePeriod Apis                              */
    /* -------------------------------------------------------------------------- */
    public async getAllCorePeriods(loading?: boolean): Promise<CorePeriod[]> {
        loading = true;

        return this.extraApiService.request<CorePeriod[]>(CorePeriod, 'post', `/core/settings/periods/all`).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCorePeriod(model: CorePeriod, loading?: boolean): Promise<CorePeriod> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'periodId',
            'desc',
            'startDate',
            'endDate',
            'status',
        ]);

        return this.extraApiService.request<CorePeriod>(CorePeriod, 'post', `/core/settings/periods/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async softDeleteOneCorePeriodById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/settings/periods/soft-delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                CoreRole Apis                               */
    /* -------------------------------------------------------------------------- */
    public async getAllCoreRoles(loading?: boolean): Promise<CoreRole[]> {
        loading = true;

        return this.extraApiService.request<CoreRole[]>(CoreRole, 'post', `/core/settings/roles/all`).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreRolesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreRole[]> {
        loading = true;

        return this.extraApiService.request<CoreRole[]>(CoreRole, 'post', `/core/settings/roles/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreRolesByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreRole[]> {
        loading = true;

        return this.extraApiService.request<CoreRole[]>(CoreRole, 'post', `/core/settings/roles/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async getOneCoreRolesById(id: number, loading?: boolean): Promise<CoreRole> {
        loading = true;

        return this.extraApiService.request<CoreRole>(CoreRole, 'post', `/core/settings/roles/one/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreRole(model: CoreRole, loading?: boolean): Promise<CoreRole> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'id',
            'name',
            'desc',
            'roleUsers',
        ]);

        return this.extraApiService.request<CoreRole>(CoreRole, 'post', `/core/settings/roles/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteOneCoreRoleById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/settings/roles/delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async softDeleteOneCoreRoleById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/settings/roles/soft-delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async linkCapabilitiesWithRole(roleId: number, capabilitiesIds: number[]): Promise<any> {
        return this.extraApiService.post(`/core/settings/roles/link-capabilities-with-role`, { roleId, capabilitiesIds });
    }

    public async upsertCoreRoleUsersByFilter(models: CoreRoleUser[], loading?: boolean): Promise<CoreRoleUser[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'roleId',
                'userId',
                'subjectId',
                'grade',
                'gradeSection',
            ]);
        });

        return this.extraApiService.request<CoreRoleUser[]>(CoreRoleUser, 'post', `/core/settings/roles-users/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreRoleUsersByFilter(filter?: IFindPaginateOptions, loading?: boolean): Promise<CoreRoleUser[]> {
        loading = true;

        return this.extraApiService.request<CoreRoleUser[]>(CoreRoleUser, 'post', `/core/settings/roles-users/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreRoleUsersByQuery(model: Partial<CoreRoleUser>, loading?: boolean): Promise<any> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'roleId',
            'userId',
            'subjectId',
            'grade',
            'gradeSection',
        ]);

        return this.extraApiService.post(`/core/settings/roles-users/delete-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                Teachers  Api                               */
    /* -------------------------------------------------------------------------- */
    public async getCoreTeachersByFilter(filter?: IFindPaginateOptions, loading?: boolean): Promise<CoreTeacher[]> {
        loading = true;

        return this.extraApiService.request<CoreTeacher[]>(CoreTeacher, 'post', `/core/teachers/by-filter`, filter).then(res => {
            loading = false;

            return _.orderBy(res, ['firstName1', 'lastName1'], ['asc', 'asc']);
        });
    }

    public async upsertCoreTeachers(models: CoreTeacher[], loading?: boolean): Promise<CoreTeacher[]> {
        loading = true;

        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS, 'id', 'userNumber', 'idType', 'firstName1', 'department', 'lastName1', 'firstName2',
                'lastName2', 'dateOfBirth', 'genderId', 'descGender', 'phone', 'email', 'descAddress', 'address',
                'homeId', 'userStatusId', 'notVisibleInStart', 'roleUsers', 'username',
            ]);
        });

        return this.extraApiService.request<CoreTeacher[]>(CoreTeacher, 'post', `/core/teachers/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreTeacher(model: CoreTeacher, loading?: boolean): Promise<CoreTeacher> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS, 'id', 'userNumber', 'idType', 'firstName1', 'department', 'lastName1', 'firstName2',
            'lastName2', 'dateOfBirth', 'genderId', 'descGender', 'phone', 'email', 'descAddress', 'address',
            'homeId', 'userStatusId', 'notVisibleInStart', 'roleUsers', 'username',
        ]);

        return this.extraApiService.request<CoreTeacher>(CoreTeacher, 'post', `/core/teachers/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async updateOneCoreTeacher(model: CoreTeacher, loading?: boolean): Promise<CoreTeacher> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS, 'id', 'userNumber', 'idType', 'firstName1', 'department', 'lastName1', 'firstName2',
            'lastName2', 'dateOfBirth', 'genderId', 'descGender', 'phone', 'email', 'descAddress', 'address',
            'homeId', 'userStatusId', 'notVisibleInStart', 'roleUsers', 'username',
        ]);

        return this.extraApiService.request<CoreTeacher>(CoreTeacher, 'post', `/core/teachers/update-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreTeachersByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreTeacher[]> {
        loading = true;

        return this.extraApiService.post(`/core/teachers/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async buildRolesTeachers(): Promise<CoreTeacher[]> {
        return this.extraApiService.post(`/core/teachers/build-roles-teachers`).then(res => {
            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                              CoreSubject apis                              */
    /* -------------------------------------------------------------------------- */
    public async upsertOneSubject(model: CoreSubject, loading?: boolean): Promise<CoreSubject> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'subjectId',
            'subjectName1',
            'subjectName2',
            'order',
            'notVisibleInStart',
        ]);

        return this.extraApiService.post(`/core/subjects/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async getAllCoreSubjects(loading?: boolean): Promise<CoreSubject[]> {
        loading = true;

        return this.extraApiService.request<CoreSubject[]>(CoreSubject, 'post', '/core/subjects/all').then(res => {
            loading = false;

            return res;
        });
    }

    public async getAllCoreMinistrySubjects(): Promise<CoreMinistrySubject[]> {
        return this.extraApiService.request<CoreMinistrySubject[]>(CoreMinistrySubject, 'post', '/core/subjects/ministry-subjects/all');
    }

    public async softDeleteOneCoreSubjectById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/subjects/soft-delete/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                               CoreStudent Api                              */
    /* -------------------------------------------------------------------------- */
    public async upsertOneCoreStudent(model: CoreStudent, loading?: boolean): Promise<CoreStudent> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'firstName1',
            'lastName1',
            'firstName2',
            'lastName2',
            'firstName3',
            'lastName3',
            'id',
            'phone',
            'dateOfBirth',
            'email',
            'studentStatusId',
            'genderId',
            'busId',
            'grade',
            'gradeSection',
        ]);

        return this.extraApiService.post(`/core/students/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async getAllCoreStudents(loading?: boolean): Promise<CoreStudent[]> {
        loading = true;

        return this.extraApiService.request<CoreStudent[]>(CoreStudent, 'post', `/core/students/all`).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreStudentsByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreStudent[]> {
        loading = true;

        return this.extraApiService.request<CoreStudent[]>(CoreStudent, 'post', `/core/students/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async updateOneCoreStudent(model: CoreStudent, loading?: boolean): Promise<CoreStudent> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'firstName1',
            'lastName1',
            'firstName2',
            'lastName2',
            'firstName3',
            'lastName3',
            'id',
            'phone',
            'dateOfBirth',
            'email',
            'studentStatusId',
            'genderId',
            'busId',
            'grade',
            'gradeSection',
        ]);

        return this.extraApiService.request<CoreStudent>(CoreStudent, 'post', `/core/students/update-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async importStudentsByFile(file: Blob): Promise<IImportResult> {
        const formParams = new FormData();

        formParams.append('data_file', file);

        return this.extraApiService.uploadFile(`/core/students/import`, formParams);
    }

    /* -------------------------------------------------------------------------- */
    /*                               CoreGroup Apis                               */
    /* -------------------------------------------------------------------------- */
    public async upsertCoreGroups(models: CoreGroup[], loading?: boolean): Promise<CoreGroup[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'groupTypeId',
                'periodId',
                'startDate',
                'endDate',
                'subjectId',
                'subjectDesc',
                'groupName',
                'ministrySubjectId',
                'groupLevelId',
                'educationPhase',
                'createdUserId',
                'updatedUserId',
                'activityTypeId',
                'groupStatusId',
                'numOfHours',
                'groupingId',
                'grade',
                'gradeSection',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async updateCoreGroups(models: Partial<CoreGroup>[], loading?: boolean): Promise<any> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'groupTypeId',
                'periodId',
                'startDate',
                'endDate',
                'subjectId',
                'subjectDesc',
                'groupName',
                'ministrySubjectId',
                'groupLevelId',
                'educationPhase',
                'createdUserId',
                'updatedUserId',
                'activityTypeId',
                'groupStatusId',
                'numOfHours',
                'groupingId',
                'grade',
                'gradeSection',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/update`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreGroupsByTeachersAndStudent(models: CoreGroup[], loading?: boolean): Promise<CoreGroup[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'groupTypeId',
                'periodId',
                'startDate',
                'endDate',
                'subjectId',
                'subjectDesc',
                'groupName',
                'ministrySubjectId',
                'groupLevelId',
                'educationPhase',
                'createdUserId',
                'updatedUserId',
                'activityTypeId',
                'groupStatusId',
                'numOfHours',
                'groupingId',
                'grade',
                'gradeSection',
                'teachers',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/upsert-with-members`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreGroups(model: CoreGroup, loading?: boolean): Promise<CoreGroup> {
        loading = true;
        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'groupId',
            'groupTypeId',
            'periodId',
            'startDate',
            'endDate',
            'subjectId',
            'subjectDesc',
            'groupName',
            'ministrySubjectId',
            'groupLevelId',
            'educationPhase',
            'createdUserId',
            'updatedUserId',
            'activityTypeId',
            'groupStatusId',
            'numOfHours',
            'groupingId',
            'grade',
            'gradeSection',
        ]);

        return this.extraApiService.request<CoreGroup>(CoreGroup, 'post', `/core/groups/upsert-one`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreGroupAndCreateGroupMembers(models: CoreGroup[], loading?: boolean): Promise<CoreGroup[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'groupTypeId',
                'periodId',
                'startDate',
                'endDate',
                'subjectId',
                'subjectDesc',
                'groupName',
                'ministrySubjectId',
                'groupLevelId',
                'educationPhase',
                'createdUserId',
                'updatedUserId',
                'activityTypeId',
                'groupStatusId',
                'numOfHours',
                'groupingId',
                'grade',
                'gradeSection',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/upsert-and-create-group-members`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreGroupsWithGroupMembers(models: CoreGroup[], loading?: boolean): Promise<CoreGroup[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'groupTypeId',
                'periodId',
                'startDate',
                'endDate',
                'subjectId',
                'subjectDesc',
                'groupName',
                'ministrySubjectId',
                'groupLevelId',
                'educationPhase',
                'createdUserId',
                'updatedUserId',
                'activityTypeId',
                'groupStatusId',
                'numOfHours',
                'groupingId',
                'grade',
                'gradeSection',
                'groupMembers',
            ]);
        });

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/upsert-with-group-members`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreGroupsByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreGroup[]> {
        loading = true;

        return this.extraApiService.request<CoreGroup[]>(CoreGroup, 'post', `/core/groups/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async linkCoreGroupMembersWithGroup(
        models: CoreGroupMember[],
        groupId: number,
        memberType: MemberType,
        loading?: boolean,
    ): Promise<CoreGroupMember[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'memberId',
                'memberType',
                'idType',
                'startDate',
                'endDate',
                'reasonLeaving',
                'createdUserId',
                'updatedUserId',
            ]);
        });

        return this.extraApiService.request<CoreGroupMember[]>(
            CoreGroupMember,
            'post',
            `/core/groups/group-members/link-with-group/${groupId}/${memberType}`,
            body,
        ).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreGroupMembers(models: CoreGroupMember[], loading?: boolean): Promise<CoreGroupMember[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'groupId',
                'memberId',
                'memberType',
                'idType',
                'startDate',
                'endDate',
                'reasonLeaving',
                'createdUserId',
                'updatedUserId',
            ]);
        });

        return this.extraApiService.request<CoreGroupMember[]>(CoreGroupMember, 'post', `/core/groups/group-members/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreGroupMembersByGroupId(groupId: number, loading?: boolean): Promise<CoreGroupMember[]> {
        loading = true;

        return this.extraApiService.request<CoreGroupMember[]>(CoreGroupMember, 'post', `/core/groups/group-members/find/${groupId}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreGroupMembersByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreGroupMember[]> {
        loading = true;

        return this.extraApiService.request<CoreGroupMember[]>(CoreGroupMember, 'post', `/core/groups/group-members/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                CoreOrg Apis                                */
    /* -------------------------------------------------------------------------- */
    public async getMyCorOrg(loading?: boolean): Promise<CoreOrg> {
        loading = true;

        return this.extraApiService.request<CoreOrg>(CoreOrg, 'post', `/core/orgs/my-org`).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertMyCoreOrg(org: CoreOrg, loading?: boolean): Promise<CoreOrg> {
        loading = true;

        return this.extraApiService.request<CoreOrg>(CoreOrg, 'post', `/core/orgs/my-org/upsert`, org).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Ftp Apis                                  */
    /* -------------------------------------------------------------------------- */
    public async getCoreFtpFiles(params: { [key: string]: string | number }): Promise<string[]> {
        return this.extraApiService.post(`/core/ftp/get-available-files`, params);
    }

    public async getCoreMigrateFileToDatabase(params: IFTPParams): Promise<string[]> {
        return this.extraApiService.post(`/core/ftp/migrate-file-to-database`, params);
    }

    public async getCoreMigrateLatestFileToDatabase(params: IFTPParams): Promise<string[]> {
        return this.extraApiService.post(`/core/ftp/migrate-latest-file-to-database`, params);
    }

    public async getCoreMoeFileByName(body: { get_file: string }): Promise<any> {
        return this.extraApiService.postFile(`/core/ftp/get-file-by-name`, body);
    }

    public async exportCoreGroupsToMOE(): Promise<any> {
        return this.extraApiService.postFile(`/core/export/groups-to-moe`);
    }

    public async exportCoreEduProgramsToMOE(): Promise<any> {
        return this.extraApiService.postFile(`/core/export/edu-programs-to-moe`);
    }

    /* -------------------------------------------------------------------------- */
    /*                             CoreEduProgram Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getAllCoreEduPrograms(loading?: boolean): Promise<CoreEduProgram[]> {
        loading = true;

        return this.extraApiService.request<CoreEduProgram[]>(CoreEduProgram, 'post', `/core/edu-programs/all`).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreEduProgramOrgs(models: CoreEduProgramOrg[], loading?: boolean): Promise<CoreEduProgramOrg[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'startDate',
                'endDate',
            ]);
        });

        return this.extraApiService.request<CoreEduProgramOrg[]>(CoreEduProgramOrg, 'post', `/core/edu-programs/edu-program-orgs/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreEduProgramManagers(models: CoreEduProgramManager[], loading?: boolean): Promise<CoreEduProgramManager[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'eduProgramId',
                'teacherId',
                'firstName',
                'lastName',
                'startDate',
                'endDate',
            ]);
        });

        return this.extraApiService.request<CoreEduProgramManager[]>(
            CoreEduProgramManager,
            'post',
            `/core/edu-programs/edu-program-managers/upsert`,
            body,
        ).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreEduProgramOrgsByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreEduProgramOrg[]> {
        loading = true;

        return this.extraApiService.request<CoreEduProgramOrg[]>(
            CoreEduProgramOrg,
            'post',
            `/core/edu-programs/edu-program-orgs/by-filter`,
            filter,
        ).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreEduProgramGroups(models: CoreEduProgramGroup[], loading?: boolean): Promise<CoreEduProgramGroup[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'eduProgramId',
                'groupId',
                'startDate',
                'endDate',
            ]);
        });

        return this.extraApiService.request<CoreEduProgramGroup[]>(
            CoreEduProgramGroup,
            'post',
            `/core/edu-programs/edu-program-groups/upsert`,
            body,
        ).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreEduProgramGroupByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/edu-programs/edu-program-groups/delete`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreProgramManagersGroupAndOrgByEduId(eduId: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/edu-programs/delete-program-managers-and-group/${eduId}`).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                           CoreSchoolProgram Apis                           */
    /* -------------------------------------------------------------------------- */
    public async softDeleteCoreSchoolProgramById(id: number, loading?: boolean): Promise<any> {
        loading = true;

        return this.extraApiService.post(`/core/school-programs/soft-delete/one/${id}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async getCoreSchoolProgramsByFilter(filter: IFindPaginateOptions, loading?: boolean): Promise<CoreSchoolProgram[]> {
        loading = true;

        return this.extraApiService.request<CoreSchoolProgram[]>(CoreSchoolProgram, 'post', `/core/school-programs/by-filter`, filter).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreSchoolPrograms(models: CoreSchoolProgram[], loading?: boolean): Promise<CoreSchoolProgram[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'title',
                'grade',
                'gradeSection',
                'subjectId',
                'parentId',
            ]);
        });

        return this.extraApiService.request<CoreSchoolProgram[]>(CoreSchoolProgram, 'post', `/core/school-programs/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertCoreRelationships(models: CoreRelationship[], loading?: boolean): Promise<CoreRelationship[]> {
        loading = true;
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'relationshipId',
                'studentId',
                'studentIdType',
                'relationshipIdType',
                'relationshipTypeId',
                'genderId',
                'lastName',
                'firstName',
                'allowGetInfo',
                'allowPay',
                'relationshipStatusId',
                'dateOfBirth',
                'startDate',
                'endDate',
                'relationshipDetail',
            ]);
        });

        return this.extraApiService.request<CoreRelationship[]>(CoreRelationship, 'post', `/core/relationships/upsert`, body).then(res => {
            loading = false;

            return res;
        });
    }

    public async deleteCoreRelationships(studentId: number, relationshipId: number, loading?: boolean): Promise<CoreRelationship[]> {
        loading = true;

        return this.extraApiService.post(`/core/relationships/delete/${studentId}/${relationshipId}`).then(res => {
            loading = false;

            return res;
        });
    }

    public async upsertOneCoreRelationshipDetail(studentId: number, model: CoreRelationshipDetail, loading?: boolean): Promise<CoreRelationshipDetail> {
        loading = true;

        const body = _.pick(model, [
            ...COMMON_FIELDS,
            'relationshipId', 'relationshipIdType', 'relationshipDetailStatusId', 'villageId1', 'villageName1',
            'homeId1', 'apartmentId1', 'zipCode1', 'villageId2', 'villageName2', 'streetId2', 'streetName2',
            'homeId2', 'homeDetail2', 'apartmentId2', 'mailBox', 'zipCode2', 'neighborhood', 'neighborhoodDesc',
            'mailBox2', 'prefixTel', 'tel1', 'prefixTel2', 'tel2', 'prefixMobile1', 'mobile1', 'prefixMobile2',
            'mobile2', 'email1', 'email2', 'relationship', 'streetId1', 'streetName1',
        ]);

        return this.extraApiService.request<CoreRelationshipDetail>(
            CoreRelationshipDetail,
            'post',
            `/core/relationships/relationship-details/upsert-one/${studentId}`,
            body,
        ).then(res => {
            loading = false;

            return res;
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                               ReCaching Apis                               */
    /* -------------------------------------------------------------------------- */
    public async reCachingClasses(): Promise<any> {
        return this.extraApiService.post('/core/classes/re-caching');
    }

    public async reCachingGrades(): Promise<any> {
        return this.extraApiService.post('/core/grades/re-caching');
    }

    public async reCachingTeachers(): Promise<any> {
        return this.extraApiService.post('/core/teachers/re-caching');
    }

    public async reCachingUserStatuses(): Promise<any> {
        return this.extraApiService.post('/core/settings/user-statuses/re-caching');
    }

    public async reCachingGenders(): Promise<any> {
        return this.extraApiService.post('/core/settings/genders/re-caching');
    }

    public async reCachingCapabilities(): Promise<any> {
        return this.extraApiService.post('/core/settings/capabilities/re-caching');
    }

    public async reCachingRoles(): Promise<any> {
        return this.extraApiService.post('/core/settings/roles/re-caching');
    }

    public async reCachingStudentStatues(): Promise<any> {
        return this.extraApiService.post('/core/settings/student-statues/re-caching');
    }

    public async reCachingStudentBuses(): Promise<any> {
        return this.extraApiService.post('/core/settings/student-buses/re-caching');
    }

    public async reCachingPeriods(): Promise<any> {
        return this.extraApiService.post('/core/settings/periods/re-caching');
    }

    public async reCachingLessons(): Promise<any> {
        return this.extraApiService.post('/core/settings/lessons/re-caching');
    }

    public async reCachingRolesUsers(): Promise<any> {
        return this.extraApiService.post('/core/settings/roles-users/re-caching');
    }

    public async reCachingEducationPhases(): Promise<any> {
        return this.extraApiService.post('/core/settings/education-phases/re-caching');
    }

    public async reCachingSubjects(): Promise<any> {
        return this.extraApiService.post('/core/subjects/re-caching');
    }

    public async reCachingStudents(): Promise<any> {
        return this.extraApiService.post('/core/students/re-caching');
    }

    public async reCachingRelationships(): Promise<any> {
        return this.extraApiService.post('/core/relationships/re-caching');
    }

    public async reRelationshipTypes(): Promise<any> {
        return this.extraApiService.post('/core/relationships/relationship-types/re-caching');
    }

    public async reCachingGroups(): Promise<any> {
        return this.extraApiService.post('/core/groups/re-caching');
    }

    public async reCachingGroupMembers(): Promise<any> {
        return this.extraApiService.post('/core/groups/group-members/re-caching');
    }

    public async reCachingGroupTypes(): Promise<any> {
        return this.extraApiService.post('/core/groups/group-types/re-caching');
    }

    public async reCachingOrgs(): Promise<any> {
        return this.extraApiService.post('/core/orgs/re-caching');
    }

    public async reCachingSchoolPrograms(): Promise<any> {
        return this.extraApiService.post('/core/school-programs/re-caching');
    }
}
