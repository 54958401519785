import { Expose, plainToClass } from 'class-transformer';

export interface Deploy {
    [app: string]: {
        [year: string]: {
            url: string;
        };
    };
}

export class KindixUser {
    @Expose()
    public account_id!: number;

    @Expose()
    public accessToken!: string;

    @Expose()
    public idp!: string;

    @Expose()
    public code!: string;

    @Expose()
    public description!: string;

    @Expose()
    public message!: string;

    @Expose()
    public org_id!: number;

    @Expose()
    public year!: number;

    @Expose()
    public externalId!: number;

    @Expose()
    public status!: string;

    @Expose()
    public deploy!: Deploy;

    @Expose()
    public username!: string;

    @Expose()
    public subDomain!: string;

    @Expose()
    public id!: string;

    @Expose()
    public user!: {
        id: string;
    };

    @Expose()
    public lang!: string[];

    @Expose()
    public token_start_time!: string;

    @Expose()
    public token_life_time!: string;

    @Expose()
    public logout_url!: string;

    constructor(payload?: Partial<KindixUser>) {
        return plainToClass(KindixUser, payload, { excludeExtraneousValues: true });
    }
}
