import { ISubject } from '@common/interfaces';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { Teacher } from './Teacher.entity';

export class Attendance extends BaseEntityClass {
    @Expose()
    public lessonId!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public teacherId!: number;

    @Expose()
    public updateTeacherId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    public statementId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    public date!: Date;

    @Expose()
    public status!: number;

    @Expose()
    @Transform(item => moment(item.obj.date).format('YYYY-MM-DD'))
    public dateStr!: string;

    constructor(payload: Partial<Attendance>) {
        super();

        return plainToClass(Attendance, payload, { excludeExtraneousValues: true });
    }

    @Expose()
    @Type(() => Teacher)
    public teacher!: Teacher;

    @Expose()
    public subject!: ISubject;
}
