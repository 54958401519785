import { Injectable } from '@angular/core';
import { COMMON_FIELDS } from '@common/constants';
import {
    MusicTool, MusicToolReservation, SMS, SMSPurchases, ServeLessonPlans, ServeMusicTool, ServeMusicToolCategories, ServeMusicToolReservation,
    ServeMusicToolStatuses,
    ServeSessionsPlans, ServeWeekPlans
} from '@common/entities';
import { IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';

export interface IServeApiService {
    getNumberOfOrgMessages(): Promise<{ numberOfOrgMessages: number, numberOfSentMessages: number }>;
    getMySmsPurchases(filter?: IFindPaginateOptions): Promise<SMSPurchases[]>;
}
@Injectable({
    providedIn: 'root',
})
export class ServeApiService extends CommonApiService implements IServeApiService, ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'serv', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                                  SMS Apis                                  */
    /* -------------------------------------------------------------------------- */
    public async sendSMS(
        body: { content: string, customerName?: string, phones: string[] },
    ): Promise<{ numberOfOrgMessages: number, numberOfSentMessages: number }> {
        return this.extraApiService.post('/serv/sms/send', body);
    }

    public async getNumberOfOrgMessages(): Promise<{ numberOfOrgMessages: number, numberOfSentMessages: number }> {
        return this.extraApiService.post('/serv/sms/sms-remaining');
    }

    public async getMySmsPurchases(filter: IFindPaginateOptions = { filter: { } }): Promise<SMSPurchases[]> {
        return this.extraApiService.request(SMSPurchases, 'post', '/serv/sms/sms-purchases/get-my-sms-purchases', filter);
    }

    public async getSMSsByFilter(filter: IFindPaginateOptions = { filter: { } }): Promise<SMS[]> {
        return this.extraApiService.request(SMS, 'post', '/serv/sms/by-filter', filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                             Music Tools Routes                             */
    /* -------------------------------------------------------------------------- */
    public async getMusicToolsByFilter(filter: IFindPaginateOptions = { filter: { } }): Promise<MusicTool[]> {
        return this.extraApiService.request(MusicTool, 'post', '/serv/tools/music-tools/by-filter', filter);
    }

    public async upsertMusicTools(musicTools: MusicTool[]): Promise<MusicTool[]> {
        const body = musicTools.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'id',
                'name',
                'type',
                'code',
                'manager',
                'storagePlace',
            ]);
        });

        return this.extraApiService.request(MusicTool, 'post', '/serv/tools/music-tools/upsert', body);
    }

    public async upsertMusicToolReservations(musicTools: MusicToolReservation[]): Promise<MusicToolReservation[]> {
        const body = musicTools.map(item => {
            return _.pick(item, [
                'studentId',
                'musicToolId',
                'date',
            ]);
        });

        return this.extraApiService.request(MusicToolReservation, 'post', '/serv/tools/music-tool-reservations/upsert', body);
    }

    public async getMusicToolsReservationsByFilter(filter: IFindPaginateOptions = { filter: {} }): Promise<MusicToolReservation[]> {
        return this.extraApiService.request(MusicToolReservation, 'post', `/serv/tools/music-tool-reservations/by-filter`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                             WeekPlan Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getWeekPlans(filter?: IFindPaginateOptions): Promise<ServeWeekPlans[]> {
        return this.extraApiService.request(ServeWeekPlans, 'post', `/serv/plans/week-plans/by-filter`, filter);
    }

    public async upsertWeekPlan(models: ServeWeekPlans[]): Promise<void> {
        return this.extraApiService.post(`/serv/plans/week-plans/upsert`, models);
    }

    public async softDeleteWeekPlans(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/serv/plans/week-plans/soft-delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                             SessionsPlan Apis                              */
    /* -------------------------------------------------------------------------- */
    public async getSessionsPlans(filter?: IFindPaginateOptions): Promise<ServeSessionsPlans[]> {
        return this.extraApiService.request(ServeSessionsPlans, 'post', `/serv/plans/session-plans/by-filter`, filter);
    }

    public async upsertSessionsPlan(models: ServeSessionsPlans[]): Promise<any> {
        return this.extraApiService.post(`/serv/plans/session-plans/upsert`, models);
    }

    public async softDeleteSessionsPlans(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/serv/plans/session-plans/soft-delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                             LessonPlan Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getLessonPlans(filter?: IFindPaginateOptions): Promise<ServeLessonPlans[]> {
        return this.extraApiService.request(ServeLessonPlans, 'post', `/serv/plans/lesson-plans/by-filter`, filter);
    }

    public async upsertLessonPlan(models: ServeLessonPlans[]): Promise<void> {
        return this.extraApiService.post(`/serv/plans/lesson-plans/upsert`, models);
    }

    public async softDeleteLessonPlans(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/serv/plans/lesson-plans/soft-delete`, filter);
    }


    /* -------------------------------------------------------------------------- */
    /*                             MusicTool Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getMusicTools(filter?: IFindPaginateOptions): Promise<ServeMusicTool[]> {
        return this.extraApiService.request(ServeMusicTool, 'post', `/serv/tools/music-tools/by-filter`, filter);
    }

    public async upsertMusicTool(models: ServeMusicTool[]): Promise<void> {
        return this.extraApiService.post(`/serv/tools/music-tools/upsert`, models);
    }

    public async softDeleteMusicTools(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/serv/tools/music-tools/soft-delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                             MusicToolReservation Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getMusicToolReservation(filter?: IFindPaginateOptions): Promise<ServeMusicToolReservation[]> {
        return this.extraApiService.request(ServeMusicToolReservation, 'post', `/serv/tools/music-tool-reservations/by-filter`, filter);
    }

    public async upsertMusicToolReservation(models: ServeMusicToolReservation[]): Promise<void> {
        return this.extraApiService.post(`/serv/tools/music-tool-reservations/upsert`, models);
    }


    public async softDeleteMusicToolReservation(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/serv/tools/music-tool-reservations/soft-delete`, filter);
    }


    async generateExcel(body: any): Promise<any> {
        return await this.extraApiService.postFile('/util/generator/export-data-as-xlsx', body);
    }

    /* -------------------------------------------------------------------------- */
    /*                             MusicToolCategories Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getMusicToolCategories(filter?: IFindPaginateOptions): Promise<ServeMusicToolCategories[]> {
        return this.extraApiService.request(ServeMusicToolCategories, 'post', `/serv/tools/categories/by-filter`, filter);
    }

    public async upsertMusicToolCategories(models: ServeMusicToolCategories[]): Promise<void> {
        return this.extraApiService.post(`/serv/tools/categories/upsert`, models);
    }

    /* -------------------------------------------------------------------------- */
    /*                             MusicToolStatuses Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getMusicToolStatuses(filter?: IFindPaginateOptions): Promise<ServeMusicToolStatuses[]> {
        return this.extraApiService.request(ServeMusicToolStatuses, 'post', `/serv/tools/statuses/by-filter`, filter);
    }

    public async upsertMusicToolStatuses(models: ServeMusicToolStatuses[]): Promise<void> {
        return this.extraApiService.post(`/serv/tools/statuses/upsert`, models);
    }
}
