import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EvalApiService } from '../api/eval-api.service';
import * as _ from 'lodash';
import { CoreClass, CoreGrade, CorePeriod, CoreSubject } from '@common/entities';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private store: { [key: string]: any } = { };
    private currentStore: { [key: string]: any } = { };

    constructor(
        public evalApiService: EvalApiService,
    ) { }

    public async getClassesOfTeacher(): Promise<CoreClass[]> {
        if (!this.store['getClassesOfTeacher'] || _.isEmpty(this.store['getClassesOfTeacher'])) {
            this.store['getClassesOfTeacher'] = await this.evalApiService.getClassesOfTeacher();
        }

        return this.store['getClassesOfTeacher'];
    }

    public async getClassesByRoleUser(): Promise<CoreClass[]> {
        if (!this.store['getClassesByRoleUser'] || _.isEmpty(this.store['getClassesByRoleUser'])) {
            this.store['getClassesByRoleUser'] = await this.evalApiService.getClassesByRoleUser();
        }

        return this.store['getClassesByRoleUser'];
    }

    public async getPeriods(): Promise<CorePeriod[]> {
        if (!this.store['getPeriods'] || _.isEmpty(this.store['getPeriods'])) {
            this.store['getPeriods'] = await this.evalApiService.getPeriods();
        }

        return this.store['getPeriods'];
    }

    public async getGrade(): Promise<CoreGrade[]> {
        if (!this.store['getGrade'] || _.isEmpty(this.store['getGrade'])) {
            this.store['getGrade'] = await this.evalApiService.getGrade();
        }

        return this.store['getGrade'];
    }

    public async getSubjects(): Promise<CoreSubject[]> {
        if (!this.store['getSubjects'] || _.isEmpty(this.store['getSubjects'])) {
            this.store['getSubjects'] = await this.evalApiService.getSubjects();
        }

        return this.store['getSubjects'];
    }

    public setCurrentState(key: string, data: any): void {
        this.currentStore[key] = _.cloneDeep(data);
    }

    public getCurrentState(key: string, defaultValue?: any): any {
        return this.currentStore[key] || defaultValue;
    }
}
