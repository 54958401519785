import { Expose, Type, plainToClass } from 'class-transformer';
import { CoreOrg } from './CoreOrg.entity';
import { KindixUser } from '../kindix-user.entity';

export interface IProfileUser {
    userId: number;
    firstName: string;
    lastName: string;
    idType: number;
}


export class CoreProfile {
    @Expose()
    public user!: IProfileUser;

    @Expose()
    @Type(() => CoreOrg)
    public org!: CoreOrg;

    @Expose()
    public capabilities!: {
        [key: string]: boolean;
    };

    @Expose()
    @Type(() => KindixUser)
    public auth!: KindixUser;

    constructor(payload?: Partial<CoreProfile>) {
        return plainToClass(CoreProfile, payload, { excludeExtraneousValues: true });
    }
}
