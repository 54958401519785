export const environment = {
    production: false,
    staging: false,
    mode: 'dev',
    loginUrl: 'https://authedu.kindix.me',
    service_url: 'https://accounts-api.kindix.me/api/',
    mainAppsUrl: 'http://dev.kindix.me',
    accountsApi: 'https://accounts-api.kindix.me',
    appUrlServer: 'http://88.198.211.16:3008',
    frontUrl:'http://dev.kindix.me/pay',
    showOnlineLesson: false,

    appUrl: 'https://dev-api.kindix.me',
    // appUrl: 'https://api.kindix.me',
    // appUrl: 'http://localhost:3000',
    // appUrlServer: 'http://localhost:3007',
};
