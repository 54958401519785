import { Injectable } from '@angular/core';
import {
    CoreLesson,
    StartConstraint,
    StartDay, StartLesson,
    StartLockedCell, StartTeacherAbsent, StartTimeTable, StartTimeTableData,
} from '@common/entities';
import { IStartTimeTableResult, IFindPaginateOptions } from '@common/interfaces';
import { HelperService } from '../helpers/helper.service';
import { CommonApiService, ICommonApiService } from './common-api.service';
import { ExtraApiService } from './extra-api.service';
import * as _ from 'lodash';
import { COMMON_FIELDS } from '@common/constants';
import * as moment from 'moment';

export interface IStartApiService {
    getStartTimeTables(filter: IFindPaginateOptions): Promise<StartTimeTable[]>;
    upsertStartTimeTable(models: StartTimeTable[]): Promise<void>;
    softDeleteStartTimeTables(filter: IFindPaginateOptions): Promise<void>;
    updateStatusStartTimeTables(id: number, model: Partial<StartTimeTable>): Promise<void>;
}

@Injectable({
    providedIn: 'root',
})
export class StartApiService extends CommonApiService implements IStartApiService, ICommonApiService {
    constructor(
        protected override extraApiService: ExtraApiService,
        protected override helperService: HelperService,
    ) {
        super(extraApiService, 'start', helperService);
    }

    /* -------------------------------------------------------------------------- */
    /*                             StartTimeTable Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getStartTimeTables(filter?: IFindPaginateOptions): Promise<StartTimeTable[]> {
        return this.extraApiService.request(StartTimeTable, 'post', `/start/time-tables/by-filter`, filter);
    }

    public async upsertStartTimeTable(models: StartTimeTable[]): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/upsert`, models);
    }

    public async softDeleteStartTimeTables(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/soft-delete`, filter);
    }

    public async updateStatusStartTimeTables(id: number, model: Partial<StartTimeTable>): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/update-status/${id}`, model);
    }

    public async copyStartTimeTables(id: number, model?: Partial<StartTimeTable>): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/copy/${id}`, model);
    }

    /* -------------------------------------------------------------------------- */
    /*                           StartTimeTableData Apis                          */
    /* -------------------------------------------------------------------------- */
    public async getStartTimeTableData(filter: IFindPaginateOptions): Promise<StartTimeTableData[]> {
        return this.extraApiService.request<StartTimeTableData[]>(StartTimeTableData, 'post', `/start/time-table-data/by-filter`, filter);
    }

    public async upsertStartTimeTableData(models: StartTimeTableData[]): Promise<void> {
        return this.extraApiService.post(`/start/time-table-data/upsert`, models);
    }


    public async softDeleteStartTimeTableData(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/start/time-table-data/soft-delete`, filter);
    }

    public async updateStatusStartTimeTableData(model: Partial<StartTimeTableData>): Promise<void> {
        return this.extraApiService.post(`/start/time-table-data/update-status`, model);
    }

    public async getCalenderTimeTableDataByFilter(filter?: IFindPaginateOptions): Promise<IStartTimeTableResult> {
        return this.extraApiService.post(`/start/time-table-data/get-time-table-data`, filter);
    }

    public async generateDynamicTimeTableDataByFilter(mode: any, filter?: IFindPaginateOptions): Promise<IStartTimeTableResult> {
        return this.extraApiService.post(`/start/time-table-data/generate-dynamic-time-table`, { ...filter, mode });
    }

    public async getTeachersTimeTableDataByFilter(filter?: IFindPaginateOptions): Promise<IStartTimeTableResult> {
        return this.extraApiService.post(`/start/time-table-data/get-teachers-time-table-data`, filter).catch(res => {
            return { };
        });
    }

    public async saveStartTimeTableData(models: StartTimeTableData[]): Promise<void> {
        return this.extraApiService.post(`/start/time-table-data/sava-time-table-data`, models);
    }

    /* -------------------------------------------------------------------------- */
    /*                                StartDay Apis                               */
    /* -------------------------------------------------------------------------- */
    public async getStartDays(filter?: IFindPaginateOptions): Promise<StartDay[]> {
        return this.extraApiService.request<StartDay[]>(StartDay, 'post', `/start/settings/days/by-filter`, filter).then(res => {
            return _.orderBy(res, 'order', 'asc');
        });
    }

    private getLocalDay(): StartDay[] {
        let day = moment().startOf('week');

        const days: StartDay[] = [];

        for (let i = 0; i < 7; i++) {
            if (i > 0) {
                day = day.add(1, 'days');
            }

            days.push(new StartDay({
                id: Number(moment(day).format('d')) + 1,
                title: moment(day).format('dd'),
                date: day.toDate(),

                checked: true,

                active: day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),

                order: i + 1,
            }));
        }

        return days;
    }

    public async getCommonDays(filter?: IFindPaginateOptions): Promise<StartDay[]> {
        return this.extraApiService.request<StartDay[]>(StartDay, 'post', `/start/settings/days/by-filter`, filter).then(res => {
            if (!res || res.length === 0) {
                return this.getLocalDay();
            }

            for (const row of res) {
                const day = moment().startOf('week').add(row.id - 1, 'days');

                row.title = moment(day).format('dd');

                row.date = day.toDate();

                row.active = day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
            }

            return _.orderBy(res, 'order', 'asc');
        }).catch(err => {
            return this.getLocalDay();
        });
    }

    public async upsertStartDay(models: StartDay[]): Promise<void> {
        return this.extraApiService.post(`/start/settings/days/upsert`, models);
    }

    /* -------------------------------------------------------------------------- */
    /*                              StartLesson Apis                              */
    /* -------------------------------------------------------------------------- */
    public async getStartLessons(filter?: IFindPaginateOptions): Promise<StartLesson[]> {
        return this.extraApiService.request(StartLesson, 'post', `/start/settings/lessons/by-filter`, filter);
    }

    public async getCommonLessons(filter?: IFindPaginateOptions): Promise<StartLesson[]> {
        return this.extraApiService.request<StartLesson[]>(StartLesson, 'post', `/start/settings/lessons/by-filter`, filter).then(res => {
            return res;
        }).catch((err) => {
            return this.extraApiService.request<CoreLesson[]>(CoreLesson, 'post', `/core/cache/lessons`).then(res => {
                return res.map(l => {
                    return new StartLesson({
                        id: l.id,
                        title: l.title || String(l.id),
                        startTime: l.startTime,
                        endTime: l.endTime,
                    });
                });
            });
        }).then(res => res);
    }

    public async upsertStartLesson(models: StartLesson[]): Promise<void> {
        return this.extraApiService.post(`/start/settings/lessons/upsert`, models);
    }

    /* -------------------------------------------------------------------------- */
    /*                            StartConstraint Apis                            */
    /* -------------------------------------------------------------------------- */
    public async getStartConstraints(filter?: IFindPaginateOptions): Promise<StartConstraint[]> {
        return this.extraApiService.request(StartConstraint, 'post', `/start/settings/constraints/by-filter`, filter);
    }

    public async upsertStartConstraint(models: StartConstraint[]): Promise<void> {
        return this.extraApiService.post(`/start/settings/constraints/upsert`, models);
    }

    public async deleteStartConstraint(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/start/settings/constraints/delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                                StartLockedCell Apis                               */
    /* -------------------------------------------------------------------------- */
    public async getStartLockedCells(filter?: IFindPaginateOptions): Promise<StartLockedCell[]> {
        return this.extraApiService.request<StartLockedCell[]>(StartLockedCell, 'post', `/start/time-tables/locked-cells/by-filter`, filter);
    }

    public async upsertStartLockedCell(models: StartLockedCell[]): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/locked-cells/upsert`, models);
    }

    public async deleteStartLockedCells(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/start/time-tables/locked-cells/delete`, filter);
    }

    /* -------------------------------------------------------------------------- */
    /*                           StartTeacherAbsent Apis                          */
    /* -------------------------------------------------------------------------- */
    public async getStartTeacherAbsents(filter?: IFindPaginateOptions): Promise<StartTeacherAbsent[]> {
        return this.extraApiService.request<StartTeacherAbsent[]>(StartTeacherAbsent, 'post', `/start/teacher-absents/by-filter`, filter);
    }

    public async getAbsentTeacher(query: any): Promise<StartTeacherAbsent[]> {
        return this.extraApiService.request<StartTeacherAbsent[]>(StartTeacherAbsent, 'post', '/start/teacher-absents/by-filter', query);
    }

    public async upsertStartTeacherAbsents(models: StartTeacherAbsent[]): Promise<void> {
        const body = models.map(item => {
            return _.pick(item, [
                ...COMMON_FIELDS,
                'teacherId',
                'lessonId',
                'date',
                'dayId',
                'reason',
                'note',
                'startTime',
                'endTime',
                'status',
                'teacherReplaceId',
                'groupId',
            ]);
        });

        return this.extraApiService.post(`/start/teacher-absents/upsert`, body);
    }

    public async deleteStartTeacherAbsents(filter: IFindPaginateOptions): Promise<void> {
        return this.extraApiService.post(`/start/teacher-absents/delete`, filter);
    }
}
