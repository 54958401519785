import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { EvalMetaTemplate } from './EvalMetaTemplate.entity';


export class EvalTemplate extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    @Type(() => EvalMetaTemplate)
    public meta!: Partial<EvalMetaTemplate>;

    public itemName?: string;
    public active?: boolean;

    constructor(payload?: Partial<EvalTemplate>) {
        super();

        return plainToClass(EvalTemplate, payload, { excludeExtraneousValues: true });
    }
}
