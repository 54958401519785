
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import * as moment from 'moment';
import { PayPayments } from './PayPayments.entity';
import { CoreStudent } from '../core/CoreStudent.entity';
import { PayStudentPaymentsInvoices } from './PayStudentPaymentsInvoices.entity';

export class PayStudentPayments extends BaseEntityClass {

    @Expose()
    public studentId!: number;

    @Expose()
    public paymentId!: number;

    @Expose()
    public numOfInstallments!: number;

    @Expose()
    public discount!: number;

    @Expose()
    public startInstallmentDate!: string;

    @Expose()
    public note!: string;

    @Expose()
    @Type( () => PayPayments)
    public payment!: PayPayments;

    @Expose()
    @Type( () => CoreStudent)
    public student!: CoreStudent;

    @Expose()
    @Type( () => PayStudentPaymentsInvoices)
    public studentPaymentsInvoices!: PayStudentPaymentsInvoices[];

    public totalDebt?: number;
    public totalPaid?: number;
    public totalGrant?: number;
    public totalAmount: number = 0;

    constructor(payload?: Partial<PayStudentPayments>) {
        super();

        return plainToClass(PayStudentPayments, payload, { excludeExtraneousValues: true });
    }
}


