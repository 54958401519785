
import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import * as moment from 'moment';

export class StartDay extends BaseEntityClass {
    @Expose()
    public order!: number;

    @Expose()
    public checked!: boolean;

    @Expose()
    public active!: boolean;

    @Expose()
    public title?: string;

    @Expose()
    @Type(() => Date)
    public date?: Date;

    @Expose()
    @Transform(({ obj }) => {
        return moment(obj.date).format('YYYY-MM-DD');
    })
    public dateStr?: string;

    constructor(payload?: Partial<StartDay>) {
        super();

        return plainToClass(StartDay, payload, { excludeExtraneousValues: true });
    }
}
