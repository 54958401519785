import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';

export class StudentFileCategory extends BaseEntityClass {
    @Expose()
    public category!: string;

    constructor(payload?: Partial<StudentFileCategory>) {
        super();

        return plainToClass(StudentFileCategory, payload, { excludeExtraneousValues: true });
    }
}
