import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { MusicTool } from './MusicTool.entity';
import { Student } from './Student.entity';

export class MusicToolReservation extends BaseEntityClass {
    @Expose()
    public student!: Student;

    @Expose()
    public studentId!: number;

    @Expose()
    public musicToolId!: number;

    @Expose()
    public date!: Date;

    @Expose()
    public musicTool!: MusicTool;

    constructor(payload?: Partial<MusicToolReservation>) {
        super();

        return plainToClass(MusicToolReservation, payload, { excludeExtraneousValues: true });
    }
}
