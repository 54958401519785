import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { Student } from '../Student.entity';
import { StudentScoreSheelon } from './StudentScoreSheelon.entity';

export class SheelonStudent extends BaseEntityClass {
    @Expose()
    public semel_mosad!: number;

    @Expose()
    public shnat_limudim!: number;

    @Expose()
    public code_moed_bchina!: number;

    @Expose()
    public semel_sheelon!: number;

    @Expose()
    public mispar_zehut_nivchan!: number;

    @Expose()
    public sug_zehut_nivchan!: number;

    @Expose()
    public haim_nivchan_mishnee!: number;

    @Expose()
    public shem_prati!: string;

    @Expose()
    public shem_mishpacha!: string;

    @Expose()
    public code_min_nivchan_mishnee!: string;

    @Expose()
    public semel_hakala_reshima!: string;

    @Expose()
    public mispar_cheder!: string;

    @Expose()
    public teur_cheder!: string;

    @Expose()
    public taarich_idkun_acharon!: Date;

    @Expose()
    public taarich_siyum!: string;

    @Expose()
    public code_ofen_bchina!: string;

    @Expose()
    public teur_ofen_bchina!: string;

    @Expose()
    @Type(() => StudentScoreSheelon)
    public studentScoreSheelon!: StudentScoreSheelon;

    @Expose()
    public taarich_leida_nivchan_mishnee!: Date;

    @Expose()
    @Transform(({ obj }) => obj.taarich_leida_nivchan_mishnee ? moment(obj.taarich_leida_nivchan_mishnee).format('YYYY-MM-DD') : null)
    public taarich_leida_nivchan_mishnee_str!: string;

    @Expose()
    public taarich_yetsirat_reshuma!: Date;

    @Expose()
    @Transform(({ obj }) => obj.taarich_yetsirat_reshuma ? moment(obj.taarich_yetsirat_reshuma).format('YYYY-MM-DD hh:mm:ss') : null)
    public taarich_yetsirat_reshuma_str!: string;

    constructor(payload?: Partial<SheelonStudent>) {
        super();

        return plainToClass(SheelonStudent, payload, { excludeExtraneousValues: true });
    }
}
