
import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StartLesson extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public startTime!: Date;

    @Expose()
    public endTime!: Date;

    constructor(payload?: Partial<StartLesson>) {
        super();

        return plainToClass(StartLesson, payload, { excludeExtraneousValues: true });
    }
}
