<div class="custom-checkbox" [class.input-rtl]="direction">
  <div
    [class.row]="horizontal.label && horizontal.input"
    [class.form-group]="label && !horizontal.label && !horizontal.input"
  >
    <label [for]="id" *ngIf="label" class="form-label {{ labelCol }}">
      <div>
        <span style="display: inline-block">
          {{ title }}
        </span>
        <span style="display: inline-block" *ngIf="required" style="color: red">
          *
        </span>
      </div>
    </label>
    <div class="input-with-icon {{ inputCol }}">
      <div class="form-check">
        <label class="form-check-label" [for]="id">
          {{ title }}
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          [name]="name"
          [checked]="checked"
          [disabled]="disabled"
          [(ngModel)]="inputValue"
          (change)="onChecked($event)"
          [id]="id"
        />
      </div>

      <div class="invalid-feedback" *ngIf="!validation?.status">
        <span *ngFor="let item of validation?.messages">
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</div>
