import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IDynamicTableRow, ISheetColumn } from '@common/interfaces';

@Component({
    selector: 'core-dynamic-table',
    templateUrl: './core-dynamic-table.component.html',
    styleUrls: ['./core-dynamic-table.component.scss'],
})
export class CoreDynamicTableComponent implements OnInit {
    @Output()
    public parentRetFunction: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @Output()
    public parentActionFunction: EventEmitter<IDynamicTableRow> = new EventEmitter<IDynamicTableRow>();

    @Output()
    public parentKeyUpFunction: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @Input()
    public showHeader = true;

    @Input()
    public searchable = true;

    @Input()
    public setting: any;

    @Input()
    public apiKey: any;

    @Input()
    public query: any;

    @Input()
    public columns: any[] = []; // ISheetColumn

    @Input()
    public data: any = [];

    @Input()
    public selectAll!: boolean;

    @Input()
    public singleSelect = false;

    @Input()
    public dargField = '';

    @Input()
    public dargDisable = true;

    public orderByDesc = true;
    public ASC_DISC = 'ASC';
    public selectedColumns: any = [];
    public loading: any = false;
    public xlsDownloadLoading: any;

    public _data: IDynamicTableRow[] = [];

    public search: string = '';

    constructor(
    ) { }

    ngOnInit(): void {
    }

    isArray(data: any): boolean {
        return Array.isArray(data);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectAll']) {
            this.doSelectAll();
        }

        this.setData();
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this._data, event.previousIndex, event.currentIndex);

        this.upDateParentActionFunction({ }, 'drag');
    }

    setData(): void {
        this._data = [];

        if (this.columns && this.columns.length > 0) {
            if (this.data && this.data.length > 0) {
                for (const row of this.data) {
                    const newRow: IDynamicTableRow = {
                        action_code: '',
                        row,
                    };
                    this._data.push(newRow);
                }
            }
        }

        const defaultSortCol = this.columns.filter(item => item.defaultSort);

        if (defaultSortCol) {
            this.orderBy(defaultSortCol.map(item => item.key));
        }
    }

    async downloadXls(): Promise<void> {
        const dataRep = [
            [
                ['sheet1'],
                ['title'],
                _.map(this.selectedColumns, 'itemName'),
            ],
        ];

        this._data.map((rowData: any) => {
            const newRow: any[] = [];
            this.selectedColumns.map((c: { id: string | number }) => {
                newRow.push(rowData[`${c.id}`]);
            });
            dataRep[0].push(newRow);
        });

        this.xlsDownloadLoading = true;

        // const res: any = await this.apiService.post('xls_rep', dataRep, null, 'blob');

        this.xlsDownloadLoading = false;
    }


    onColumnChange(selectedColumns: any): void {
        this.selectedColumns = selectedColumns;
    }

    onClickSelectAll(): void {
        this.selectAll = !this.selectAll;

        this._data.forEach(row => {
            if (!row.locked) {
                row.selected = this.selectAll ? true : false;
            }
        });

        this.upDateParent();
    }

    doSelectAll(): void {
        if (this.selectAll) {
            this._data.forEach(row => {
                row.selected = true;
            });
        }

        this.upDateParent();
    }

    onRowClick(row: { selected: boolean }): void {
        if (this.singleSelect) {

            this._data.forEach(_row => {
                _row.selected = false;
            });

            row.selected = true;

            const ret: any = [row];

            this.parentRetFunction.emit(ret);
        }
    }

    onClickCheckBox(row: { selected: boolean }): void {
        const old = row.selected;

        if (this.singleSelect) {
            this._data.forEach(_row => {
                _row.selected = false;
            });
        }

        row.selected = !old;
        this.upDateParent();
    }

    upDateParent(): void {
        this.parentRetFunction.emit(this.getSelectedRows());
    }

    getSelectedRows(): any {
        return this._data.filter(row => row.selected);
    }

    getSelectedRowsLength(): any {
        return (this._data.filter(row => row.selected)).length;
    }

    onKeyUp(row: MouseEvent | undefined): void {
        this.parentKeyUpFunction.emit(row);
    }

    orderBy(order_by: string[], flag = false): void {
        if (order_by) {
            if (flag) {
                this.orderByDesc = !this.orderByDesc;
            }

            const orders = order_by.map(item => `row.${item}`);

            this._data = _.orderBy(this._data, orders, this.orderByDesc ? 'asc' : 'desc');
        }
    }

    sortByMultipleKey(keys: string | any[]): any {
        if (this.orderByDesc) {
            return (b: { [x: string]: number }, a: { [x: string]: number }) => {
                if (keys.length === 0) {
                    return 0;
                }

                const key = keys[0];

                if (a[key] < b[key]) {
                    return -1;
                } else if (a[key] > b[key]) {
                    return 1;
                } else {
                    return this.sortByMultipleKey(keys.slice(1))(a, b);
                }
            };
        } else {
            return (a: { [x: string]: number }, b: { [x: string]: number }) => {
                if (keys.length === 0) {
                    return 0;
                }

                const key = keys[0];

                if (a[key] < b[key]) {
                    return -1;
                } else if (a[key] > b[key]) {
                    return 1;
                } else {
                    return this.sortByMultipleKey(keys.slice(1))(a, b);
                }
            };
        }
    }

    upDateParentActionFunction(row: IDynamicTableRow, action_code: string | undefined): void {
        row.action_code = action_code;

        this.parentActionFunction.emit(row);
    }

    public getValue(row: any, config: ISheetColumn): any {
        const value = _.get(row, config.key as any);

        if (Array.isArray(value)) {
            if (config.filter) {
                if ((config.filter as any).where) {
                    const findVal = value.find(item => Object.keys((config.filter as any).where || { }).every(key =>
                        (config.filter as any).where[key] === item[key]),
                    );

                    const keys = !Array.isArray((config.filter as any).key) ? [(config.filter as any).key] : (config.filter as any).key;
                    const values = keys.map((item: any) => _.get(findVal, item));

                    return values.join((config.filter as any).separator || ' ');
                } else {
                    return value.map(item => {
                        const keys = !Array.isArray((config.filter as any).key) ? [(config.filter as any).key] : (config.filter as any).key;
                        const values = keys.map((key: any) => _.get(item, key));

                        return values;
                    }).join((config.filter as any).separator || ' ');
                }
            }
        }

        return value;
    }
}
