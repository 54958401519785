import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { SMSPhoneStatus } from './SMSPhoneStatus.entity';

export class SMS extends BaseEntityClass {
    @Expose()
    public segmentLength!: number;

    @Expose()
    public content!: string;

    @Expose()
    public messagesCount!: number;

    @Expose()
    @Type(() => SMSPhoneStatus)
    public smsPhoneStatuses!: SMSPhoneStatus[];

    @Expose()
    @Transform(({ obj }) => (obj.smsPhoneStatuses || []).length)
    public number_phones!: number;

    @Expose()
    @Transform(item => moment(item.obj.created_at).format('YYYY-MM-DD'))
    public dateStr!: string;

    constructor(payload?: Partial<SMS>) {
        super();

        return plainToClass(SMS, payload, { excludeExtraneousValues: true });
    }
}
