
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreLesson extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    @Type(() => Date)
    public startTime!: Date;

    @Expose()
    @Type(() => Date)
    public endTime!: Date;

    @Expose()
    public status?: boolean;

    constructor(payload?: Partial<CoreLesson>) {
        super();

        return plainToClass(CoreLesson, payload, { excludeExtraneousValues: true });
    }
}
