<div class="container">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="container-dot">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
      </div>
    </div>
  </div>
</div>
