import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { Question } from '../eval/Question.entity';

export class CoreSchoolProgram extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    @Type(() => Number)
    public grade!: number;

    @Expose()
    @Type(() => Number)
    public gradeSection!: number;

    @Expose()
    @Type(() => Number)
    public subjectId!: number;

    @Expose()
    @Type(() => Number)
    public parentId!: number;

    @Expose()
    @Type(() => CoreSchoolProgram)
    public children: CoreSchoolProgram[] = [];

    @Expose()
    @Type(() => CoreSchoolProgram)
    public parent!: CoreSchoolProgram;

    // eval field
    @Expose()
    @Type(() => Question)
    public questions?: Question[];

    public name?: string;

    public questionQty: number = 0;

    public isRoot: boolean = false;

    constructor(payload?: Partial<CoreSchoolProgram>) {
        super();

        return plainToClass(CoreSchoolProgram, payload, { excludeExtraneousValues: true });
    }
}
