
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreTeacher } from './CoreTeacher.entity';

export class CoreEduProgramManager extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public eduProgramId!: number;

    @Expose()
    @Type(() => Number)
    public teacherId!: number;

    @Expose()
    @Type(() => CoreTeacher)
    public teacher!: CoreTeacher;

    constructor(payload?: Partial<CoreEduProgramManager>) {
        super();

        return plainToClass(CoreEduProgramManager, payload, { excludeExtraneousValues: true });
    }
}
