
import { Expose, plainToClass } from 'class-transformer';

export class CoreCapability {
    @Expose()
    public id!: number;

    @Expose()
    public capability!: string;

    @Expose()
    public appCode!: string;

    @Expose()
    public description!: string;

    @Expose()
    public category!: string;

    constructor(payload?: Partial<CoreCapability>) {
        return plainToClass(CoreCapability, payload, { excludeExtraneousValues: true });
    }
}
