
import { Expose, Type, plainToClass } from 'class-transformer';
import { CoreGroup } from './CoreGroup.entity';
import { CoreEduProgram } from './CoreEduProgram.entity';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CoreEduProgramGroup extends BaseEntityClass {
    @Expose()
    public eduProgramId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    public startEnd!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    @Type(() => CoreGroup)
    public group!: CoreGroup;

    @Expose()
    @Type(() => CoreEduProgram)
    public eduProgram!: CoreEduProgram;

    constructor(payload?: Partial<CoreEduProgramGroup>) {
        super();

        return plainToClass(CoreEduProgramGroup, payload, { excludeExtraneousValues: true });
    }
}
