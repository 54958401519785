import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CloudApiService } from '@common/services';
import { CloudOrg } from '@common/entities';

@Injectable()
export class ProfileService {
    service_url = environment.appUrl;

    constructor(
        private cloudApiService: CloudApiService,
    ) { }

    public getAllApps(): Promise<CloudOrg> {
        return this.cloudApiService.getOrgByAuth();
    }
}
