
import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class CorePeriod extends BaseEntityClass {
    @Expose()
    @Type(() => Number)
    public periodId!: number;

    @Expose()
    public desc!: string;

    @Expose()
    public startDate!: Date;

    @Expose()
    public endDate!: Date;

    @Expose()
    public status!: number;

    constructor(payload?: Partial<CorePeriod>) {
        super();

        return plainToClass(CorePeriod, payload, { excludeExtraneousValues: true });
    }
}
