import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from './BaseColumnSchemaPart';
import { CoreSubject } from './core/CoreSubject.entity';

export class EvalComponent extends BaseEntityClass {
    @Expose()
    public title!: string;

    @Expose()
    public subjectId!: number;

    @Expose()
    public subject!: CoreSubject;

    @Expose()
    public status!: number;

    public selected!: boolean;

    constructor(payload?: Partial<EvalComponent>) {
        super();

        return plainToClass(EvalComponent, payload, { excludeExtraneousValues: true });
    }
}
