/* eslint-disable no-console */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private API_URL = '';
    private headers!: HttpHeaders;

    constructor(
        private http: HttpClient,
        public location: Location,
        private cookieService: CookieService,
    ) {
        this.API_URL = environment.appUrl;
    }


    private get kindixToken(): any {
        return this.cookieService.check('kindix-token')
            ? JSON.parse(decodeURIComponent(this.cookieService.get('kindix-token')))
            : null;
    }

    private init(): void {
        const authorization = (this.kindixToken && this.kindixToken.accessToken) ? this.kindixToken.accessToken : null;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain',
            Authorization: authorization,
        });
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, body was: ${error && error.error && error.error.message}`);
        }
        return throwError('Something bad happened; please try again later.');
    }

    get(path: string, parameters: any = { }, withPrefix = true): Promise<any> {
        this.init();

        const url = withPrefix ? `${this.API_URL}api/` : this.API_URL;

        return this.http.get(`${url + path}`, { params: parameters, headers: this.headers }).pipe(
            catchError(this.handleError),
        ).toPromise();
    }

    post(path: string, body: any = { }, withPrefix = true): Promise<any> {
        this.init();

        const url = withPrefix ? `${this.API_URL}/api/` : this.API_URL;

        return this.http.post(`${url + path}`, body, { headers: this.headers })
            .pipe(
                catchError(this.handleError),
            ).toPromise();
    }

    put(path: string, body: any = { }, withPrefix = true): Promise<any> {
        this.init();

        const url = withPrefix ? `${this.API_URL}/api/` : this.API_URL;

        return this.http.put(`${url + path}`, body, { headers: this.headers })
            .pipe(
                catchError(this.handleError),
            ).toPromise();
    }

    delete(path: string, parameters: any = { }, withPrefix = true): Promise<any> {
        this.init();

        const url = withPrefix ? `${this.API_URL}/api/` : this.API_URL;

        return this.http.delete(`${url + path}`, { params: parameters, headers: this.headers })
            .pipe(
                catchError(this.handleError),
            ).toPromise();
    }
}
