
import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
export class UtilFileDictionary extends BaseEntityClass {
    @Expose()
    public app!: string;

    @Expose()
    public fileName!: string;

    @Expose()
    public mimetype!: string;

    @Expose()
    public categoryId!: number;

    @Expose()
    public section!: string;

    @Expose()
    public bucket!: string;

    @Expose()
    public shared!: boolean;

    @Expose()
    public link!: string;

    @Expose()
    public itemName!: string; // model name

    @Expose()
    public itemId!: string;

    constructor(payload?: Partial<UtilFileDictionary>) {
        super();

        return plainToClass(UtilFileDictionary, payload, { excludeExtraneousValues: true });
    }
}
