import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IEButtonConfig } from '@common/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'core-button',
    templateUrl: './core-button.component.html',
    styleUrls: ['./core-button.component.scss'],
})
export class CoreButtonComponent implements OnInit {
    @Input()
    public activeStyle!: IEButtonConfig; // IEButtonConfig

    @Input()
    public notActiveStyle!: IEButtonConfig;

    @Input()
    public title!: string;

    @Input()
    public width!: number;

    @Input()
    public height!: number;

    @Input()
    public loading!: boolean;

    @Input()
    public active!: boolean;

    @Input()
    public alt: any;

    @Input()
    public disabled!: boolean;

    @Output()
    public onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    constructor(
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['title']) {
            this.translate.get('list').subscribe((list: any) => {
                if (list[this.title]) {
                    this.title = '  ' + list[this.title] + '  ';
                }
            });
        }
    }

    public _onClick(): void {
        this.onClick.emit();
    }
}
