import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private defaultLang: string = 'ar';
    private activeThem = new BehaviorSubject('oceanBlueThemProps');

    constructor(
        private translate: TranslateService,
        private storageService: StorageService,
    ) {

    }

    public setLang(lang?: string): void {
        const profile = this.storageService.getCoreProfile();

        this.translate.addLangs(['ar', 'he', 'en']);

        const _lang = lang || (profile && profile.auth && profile.auth.lang && profile.auth.lang[0]) || this.defaultLang;

        this.translate.setDefaultLang(_lang || 'ar');
    }

    public getActiveTheme(): Observable<string> {
        return this.activeThem.asObservable();
    }

    public setActiveThem(name: any): void {
        this.activeThem.next(name);
    }
}
