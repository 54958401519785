import { Component } from '@angular/core';
import { ThemeService } from '@common/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'kindix-app';

    constructor(
        public themeService: ThemeService,
    ) {

    }

    ngOnInit(): void {
        this.themeService.setLang();
    }
}
