import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class MainSheelon extends BaseEntityClass {
    @Expose()
    public semel_sheelon!: number;

    @Expose()
    public semel_sheelon_rashi!: number;

    @Expose()
    public taarich_siyum!: string;

    constructor(payload?: Partial<MainSheelon>) {
        super();

        return plainToClass(MainSheelon, payload, { excludeExtraneousValues: true });
    }
}
