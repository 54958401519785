import { Expose, Type, plainToClass } from 'class-transformer';
import { EcountMessage } from './EcountMessage.entity';
import { BaseEntityClass } from './BaseColumnSchemaPart';


export class EcountStudentMessage extends BaseEntityClass {
    @Expose()
    public messageId!: number;

    @Expose()
    public studentId!: number;

    @Expose()
    @Type(() => EcountMessage)
    public message!: EcountMessage;

    constructor(payload?: Partial<EcountStudentMessage>) {
        super();

        return plainToClass(EcountStudentMessage, payload, { excludeExtraneousValues: true });
    }
}
