
import { Expose, Type, plainToClass } from 'class-transformer';
import { CloudApplication } from './CloudApplication.entity';

export class CloudOrg {
    @Expose()
    public id!: number;

    @Expose()
    public external_id!: number;

    @Expose()
    public name!: string;

    @Expose()
    public sub_domain!: string;

    @Expose()
    public host!: string;

    @Expose()
    public email!: string;

    @Expose()
    public phone!: string;

    @Expose()
    public mobile!: string;

    @Expose()
    public city!: string;

    @Expose()
    public address!: string;

    @Expose()
    public status!: number;

    @Expose()
    public created!: Date;

    @Expose()
    public admin_first_name!: string;

    @Expose()
    public admin_email!: string;

    @Expose()
    public admin_mobile!: string;

    @Expose()
    public manager_name!: string;

    @Expose()
    public lang!: string;

    @Expose()
    public deploy_scheme!: object;

    @Expose()
    public lang_prefix!: string;

    @Expose()
    public secret_key!: string;

    @Expose()
    @Type(() => CloudApplication)
    public apps?: CloudApplication[];

    constructor(payload?: Partial<CloudOrg>) {
        return plainToClass(CloudOrg, payload, { excludeExtraneousValues: true });
    }
}
