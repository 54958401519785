<header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-danger">
        <div class="container-fluid">
            <a class="navbar-brand d-flex" [routerLink]="['home']">
                <img
                    src="./assets/images/png/profile-kindix.png"
                    width="60"
                    height="60"
                    alt=""
                />
                <div class="mx-2 mt-2" style="font-size: 0.9rem;">
                    <div class="fw-bold">
                        {{ profile?.org?.orgName }}
                    </div>
                    <div class="">
                        {{ profile?.user?.firstName }}
                        {{ profile?.user?.lastName }}
                    </div>
                </div>
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="تبديل التنقل"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mb-2 mb-md-0">
                    <li class="nav-item">

                    </li>
                </ul>
                <div class="float-left" style="margin-right: auto;">
                    <img
                        style="cursor: pointer; margin-right: 35px !important;"
                        class="m-3 ms-4"
                        src="./assets/images/png/log-out.png"
                        width="25"
                        (click)="onLogout()"
                    />
                    <!-- <img
                        src="./assets/images/png/settings.png"
                        class="mx-2"
                        width="25"
                        routerLink="/change_password"
                    /> -->
                </div>
            </div>
        </div>
    </nav>
</header>
