import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrViewComponent } from './err-view.component';
import { ProviderTranslateChildModule } from '@common/modules/provider-translate-child.module';

@NgModule({
    imports: [
        CommonModule,
        ProviderTranslateChildModule,
    ],
    exports: [ErrViewComponent],
    declarations: [ErrViewComponent]
})
export class ErrViewModule { }
