import { IEvalCustomSubject, IGeneralSelect, ISubject } from '@common/interfaces';
import { Expose, plainToClass, Transform } from 'class-transformer';
import { EvalComponent } from './EvalComponent.entity';
import { EventType } from './EventType.entity';


export class EvalMetaTemplate {
    @Expose()
    public orgSignSize!: number;

    @Expose()
    public orgLogoSize!: number;

    @Expose()
    public govLogoSize!: number;

    @Expose()
    public cityLogoSize!: number;

    @Expose()
    public waterImgSize!: number;

    @Expose()
    public waterImgOpacity!: number;

    @Expose()
    public freeText!: string;

    @Expose()
    public title1!: string;

    @Expose()
    public title2!: string;

    @Expose()
    public date!: string;

    @Expose()
    public freeNote2!: string;

    @Expose()
    public daysInYear!: number;

    @Expose()
    public trHeight!: number;

    @Expose()
    public fontSize!: number;

    @Expose()
    public subjectColWidth!: number;

    @Expose()
    public compColWidth!: number;

    @Expose()
    public paddingT!: number;

    @Expose()
    public paddingR!: number;

    @Expose()
    public fBgColor!: string;

    @Expose()
    public paddingL!: number;

    @Expose()
    public paddingB!: number;

    @Expose()
    @Transform(({ value }) => value || [])
    public subjects!: IEvalCustomSubject[];

    @Expose()
    @Transform(({ value }) => value || [])
    public backgrounds!: IGeneralSelect[];

    @Expose()
    @Transform(({ value }) => value || [])
    public showItems!: IGeneralSelect[];

    @Expose()
    @Transform(({ value }) => value || [])
    public displayTypes!: IGeneralSelect[];

    @Expose()
    @Transform(({ value }) => value || [])
    public periods!: IGeneralSelect[];

    @Expose()
    @Transform(({ value }) => value || [])
    public eventTypes!: EventType[];

    @Expose()
    public rangeMeta!: any[];

    constructor(payload?: Partial<EvalMetaTemplate>) {

        return plainToClass(EvalMetaTemplate, payload, { excludeExtraneousValues: true });
    }
}
