import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class MappingRange extends BaseEntityClass {
    @Expose()
    public status!: string;

    @Expose()
    public from!: number;

    @Expose()
    public to!: number;

    @Expose()
    public color!: string;

    constructor(payload?: Partial<MappingRange>) {
        super();

        return plainToClass(MappingRange, payload, { excludeExtraneousValues: true });
    }
}
