import { Expose, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';

export class StartTimeTableData extends BaseEntityClass {
    @Expose()
    public timeTableId!: number;

    @Expose()
    public lessonId!: number;

    @Expose()
    public teacherId!: number;

    @Expose()
    public grade!: number;

    @Expose()
    public gradeSection!: number;

    @Expose()
    public subjectId!: number;

    @Expose()
    public dayId!: number;

    @Expose()
    public groupId!: number;

    @Expose()
    public status!: number;

    constructor(payload?: Partial<StartTimeTableData>) {
        super();

        return plainToClass(StartTimeTableData, payload, { excludeExtraneousValues: true });
    }
}
