import { ViewEncapsulation, Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExtraApiService } from '@common/services';
import * as _ from 'lodash';

interface IRoute {
    url: string;
    method: 'get' | 'post' | 'delete' | 'put';
    body?: any;
    select?: string;
}
@Component({
    selector: 'core-select',
    templateUrl: './core-select.component.html',
    styleUrls: ['./core-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoreSelectComponent),
            multi: true,
        },
    ],
})
export class CoreSelectComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input()
    public horizontal: { label: number, input: number } = { label: 0, input: 0 };

    @Input()
    public label = false;

    @Input()
    public id!: string;

    @Input()
    public appendTo: string = null as any;

    @Input()
    public name!: string;

    @Input()
    public max!: number;

    @Input()
    public direction: 'rtl' | 'ltr' = 'rtl';

    @Input()
    public min!: number;

    @Input()
    public icon!: string;

    @Input()
    public step: string = '1';

    @Input()
    public bg: boolean = false;

    @Input()
    public searchable: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public title: string = '';

    @Input()
    public required = false;

    @Input()
    public bindValue!: string;

    @Input()
    public bindLabel!: string;

    @Input()
    public tagShow: number = 1;

    @Input()
    public set route(val: IRoute) {
        this._route = val;
        this.getDataFromApi();
    }

    private _route!: IRoute;

    @Input()
    public set dropdownList(val: any[]) {
        this._dropdownList = [...(val || [])];

        this.setDefaultSelect();
        this.setDefaultSelectAll();
    }

    public _dropdownList: any[] = [];

    @Input()
    public selectedItems: any[] = [];

    @Input()
    public placeholder!: string;

    @Input()
    public multiple: boolean = false;

    @Input()
    public defaultSelect: boolean = false;

    @Input()
    public defaultSelectAll: boolean = false;

    @Input()
    public validation?: { status?: boolean, messages?: string[] } | null;

    @Output()
    public onSelect: EventEmitter<any[] | any> = new EventEmitter<any[] | any>();

    public dropdownSettings: any;
    public loading: boolean = false;
    public inputValue: any = [];

    public onChange: (_any: any) => void = (_any: any) => { };
    public onTouch: (_any: any) => void = () => { };

    public isSelectedAll = false;

    constructor(
        private extraApiService: ExtraApiService,
    ) {
        this.selectedItems = [];
    }

    private set value(input: any) {
        if (input !== undefined) {
            this.inputValue = _.cloneDeep(input);

            this.handleNgModel();

            this.setDefaultSelect();
        }
    }

    public async ngOnInit(): Promise<void> {

    }

    public writeValue(value: any | any[]): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    handleNgModel(): void {
        if (Array.isArray(this.inputValue)) {
            const selectValues = this.inputValue || [];

            this.onChangeItemsSelect(this._dropdownList.filter(item => selectValues.includes(item[this.bindValue])));
        } else {
            this.onChangeItemsSelect(this._dropdownList.find(item => item[this.bindValue] === this.inputValue));
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes[`selectedItems`] && changes[`selectedItems`].firstChange) {
            this.setDefaultSelect();
            this.setDefaultSelectAll();
        }
    }

    private setDefaultSelect(): void {
        if (this.defaultSelect) {
            if ((!this.inputValue || this.inputValue.length === 0) && this._dropdownList && this._dropdownList[0]) {
                this.inputValue = this.multiple ? [this._dropdownList[0][this.bindValue]] : this._dropdownList[0][this.bindValue];
            }

            if (Array.isArray(this.inputValue)) {
                const selectValues = this.inputValue || [];
                this.onChangeItemsSelect(this._dropdownList.filter(item => selectValues.includes(item[this.bindValue])));
            } else {
                this.onChangeItemsSelect(this._dropdownList.find(item => item[this.bindValue] === this.inputValue));
            }
        }
    }

    private setDefaultSelectAll(): void {
        if (this.defaultSelectAll) {
            this.inputValue = _.map(this._dropdownList, this.bindValue);

            this.onChangeItemsSelect(_.cloneDeep(this._dropdownList));
        }
    }

    public onChangeItemsSelectAll(isChecked: any): void {
        const checked = isChecked.currentTarget.checked || false;

        if (checked) {
            this.inputValue = _.map(this._dropdownList, this.bindValue);

            this.onChangeItemsSelect(_.cloneDeep(this._dropdownList));
        } else {
            this.onChangeItemsUnSelectAll();
        }
    }

    public onChangeItemsUnSelectAll(): void {
        this.inputValue = [];

        this.onChangeItemsSelect();
    }

    public onChangeItemsSelect(event?: any | any[]): void {
        this.validation = null;

        this.onChange(this.inputValue);
        this.onTouch(this.inputValue);

        if (Array.isArray(this.inputValue)) {
            const rows = this._dropdownList.filter(i => (this.inputValue || []).includes(i[this.bindValue]));

            this.isSelectedAll = this._dropdownList.length === this.inputValue.length;

            this.onSelect.emit(rows);
        } else {
            this.onSelect.emit(this._dropdownList.find(i => this.inputValue === i[this.bindValue]));
        }
    }

    public async getDataFromApi(): Promise<void> {
        this.loading = true;

        if (this._route && this._route.url && this._route.method) {
            this.loading = true;
            this._dropdownList = await this.extraApiService.extraRequest(this._route.method, this._route.url, {
                body: this._route.body || undefined,
            }).then(res => {
                this.loading = false;
                return res || [];
            }).catch(() => {
                this.loading = false;

                return [];
            });

            this.setDefaultSelect();

            if (Array.isArray(this.inputValue)) {
                const selectValues = this.inputValue || [];
                this.onChangeItemsSelect(this._dropdownList.filter(item => selectValues.includes(item[this.bindValue])));
            } else {
                this.onChangeItemsSelect(this._dropdownList.find(item => item[this.bindValue] === this.inputValue));
            }
        }
    }

    get isInvalid(): boolean {
        if (!this.validation) {
            return false;
        } else {
            return !this.validation.status;
        }
    }

    public get labelCol(): string {
        if (this.horizontal && this.horizontal.label) {
            return `col-${this.horizontal.label}`;
        }
        return '';
    }

    public get inputCol(): string {
        if (this.horizontal && this.horizontal.input) {
            return `col-${this.horizontal.input}`;
        }
        return '';
    }

    public reset(): void {

    }
}
