import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreRoleUser } from './CoreRoleUser.entity';
import { CoreRoleCapability } from './CoreRoleCapability.entity';
import { CoreCapability } from './CoreCapability.entity';

export class CoreRole extends BaseEntityClass {
    @Expose()
    public name!: string;

    @Expose()
    public desc!: string;

    @Expose()
    @Type(() => CoreRoleUser)
    public roleUsers!: CoreRoleUser[];

    @Expose()
    @Type(() => CoreRoleCapability)
    public roleCapabilities!: CoreRoleCapability[];

    @Expose()
    @Type(() => CoreCapability)
    public capabilities!: CoreCapability[];

    constructor(payload?: Partial<CoreRole>) {
        super();

        return plainToClass(CoreRole, payload, { excludeExtraneousValues: true });
    }
}
