import { Expose, Transform, Type, plainToClass } from 'class-transformer';
import { BaseEntityClass } from '../BaseColumnSchemaPart';
import { CoreStudent } from '../core/CoreStudent.entity';
import { ServeMusicTool } from './ServeMusicTool';
import * as moment from 'moment';

export class ServeMusicToolStatuses extends BaseEntityClass {
    @Expose()
    public name?: string;

    @Expose()
    @Transform(({ obj }) => {
        return moment(obj.created_at).format('YYYY-MM-DD');
    })
    public date?: string;

    @Expose()
    public musicTool!: ServeMusicTool;


    constructor(payload?: Partial<ServeMusicToolStatuses>) {
        super();

        return plainToClass(ServeMusicToolStatuses, payload, { excludeExtraneousValues: true });
    }
}
